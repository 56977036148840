import React from "react";
import { useSelector } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useDispatch } from "react-redux";
import moment from "moment";

function ViewCard() {
  const profile = useSelector(profile360Selector.profile);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(profile360_actions.closeModal());
  if (!profile?.data?.cardOnFile) {
    return (
      <div className="flex justify-center items-center">
        Card details not found!
      </div>
    );
  }
  return (
    <div className="mt-10px w-394px new-card">
      <div className="input-div mt-26px flex items-center pl-5px">
        {profile?.data?.cardData[0].brand}
      </div>
      <div className="input-div mt-26px flex items-center pl-5px">
        {profile?.data?.cardData[0].cardNumber}
      </div>
      <div className="flex flex-nowrap mt-26px mb-26px w-full gap-18px">
        <div className="input-div w-full flex items-center pl-5px">
          {profile?.data?.cardData[0].expiryDate &&
            moment(profile?.data?.cardData[0].expiryDate).format("MM/YY")}
        </div>
        <div className="input-div w-full flex items-center pl-5px">
          {profile?.data?.cardData[0].cardCode}
        </div>
      </div>
      <div className=" flex w-full justify-end">
        <button
          className="form-btn form-btn-white mt-27px"
          onClick={handleClose}
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ViewCard;
