import React, { useState } from "react";
import { useSelector } from "react-redux";
import { profile360Selector } from "../../js/slice";
import CustomButton from "../../common/button";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../../js/api";

function AddPackage() {
  const dispatch = useDispatch();
  const modal = useSelector(profile360Selector.modal);
  const addPackagesToReservation = useSelector(
    profile360Selector.addPackagesToReservation
  );

  const [selectedItem, selectItem] = useState("");

  const handleSubmit = () => {
    if (selectedItem.itemId) {
      let tempObj = { ...modal.data };
      tempObj.packages = [selectedItem];
      dispatch(
        Profile360Apis.addPackagesToReservation({
          addOns: [selectedItem],
          ...tempObj,
        })
      );
    } else {
      alert("Please Select");
    }
  };

  const BubbleSelector = ({ data }) => {
    const { itemId = "", title = "", subtitle = "", tag = "" } = data;
    return (
      <div
        onClick={() =>
          selectItem((prev) => (prev.itemId == data.itemId ? "" : data))
        }
        className="bubble-selection flex flex-col items-center"
      >
        <div
          className={`flex radius-full cursor-pointer flex-col items-center ttl-base ${
            selectedItem.itemId == data.itemId && "ttl-base-select"
          } justify-center`}
        >
          {title && <div className="ttle-1">{title}</div>}
          {subtitle && <div className="ttle-2">{subtitle}</div>}
        </div>
        {tag && <div className="ttle-3 mt-5px">{tag}</div>}
      </div>
    );
  };

  return (
    <div className="w-394px mt-20px packages ">
      <div className="desc">{modal.data?.modalDesc}</div>
      {Array.isArray(modal.data.packages) && (
        <>
          {modal.data?.packages[0]?.description && (
            <ul className="list-parent mt-12px">
              {modal.data?.packages[0].description.split("+").map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          )}

          <div className="flex flex-wrap gap-16px justify-start mt-20px">
            {modal.data.packages.map((data, index) => (
              <BubbleSelector data={data} />
            ))}
          </div>
        </>
      )}

      <CustomButton
        style={{ border: "solid black", color: "white", fontSize: "16px" }}
        loader={addPackagesToReservation.loader}
        disabled={addPackagesToReservation.loader}
        onClick={() => handleSubmit()}
        className="w-full h-40px mt-20px"
        background="black"
        label="Submit"
        loaderColor="white"
      />
    </div>
  );
}

export default AddPackage;
