import React from "react";
import moment from "moment-timezone";
import { H6 } from "../../AbstractElements";

export const AllCheckInColumns = [
  {
    name: <H6>First Name</H6>,
    selector: (row) => row.firstName,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Last Name</H6>,
    selector: (row) => row.lastName,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Shashi ID (Email ID)</H6>,
    selector: (row) => row.email || "-",
    sortable: true,
    center: true,
    minWidth: "250px",
    wrap:true
  },
  {
    name: <H6>Primary Email ID</H6>,
    selector: (row) => row.primaryEmailId || "-",
    sortable: true,
    center: true,
    minWidth: "250px",
    wrap:true
  },
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId,
    sortable: true,
    center: true,
    minWidth: "170px",
  },
  {
    name: <H6>Reservation Date</H6>,
    selector: (row) => moment.unix(row.createdAt).tz("US/Pacific").format("MMM DD, YYYY"),
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Check-In date</H6>,
    selector: (row) => moment.unix(row.arrivalDate).tz("US/Pacific").format("MMM DD, YYYY"),
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Check-Out date</H6>,
    selector: (row) => moment.unix(row.departureDate).tz("US/Pacific").format("MMM DD, YYYY"),
    sortable: true,
    center: true,
    minWidth: "170px",
  },
  {
    name: <H6>Adults</H6>,
    selector: (row) => row.numberOfAdults,
    sortable: true,
    center: true,
    minWidth: "100px",
  },
  {
    name: <H6>Kids</H6>,
    selector: (row) => row.numberOfChildren,
    sortable: true,
    center: true,
    minWidth: "55px",
  },
  {
    name: <H6>Pets</H6>,
    selector: (row) => row.pets,
    sortable: true,
    center: true,
    minWidth: "55px",
  },
  {
    name: <H6>Total reservation amount</H6>,
    selector: (row) => {
      var value = parseFloat(row.totalResAmount);
      value = value.toFixed(2);
    return <span>${row.totalResAmount?value:"00.00"}</span>
    },
    sortable: true,
    center: true,
    minWidth: "180px",
  },
  {
    name: <H6>Room Rate </H6>,
    selector: (row) => {
      var value = parseFloat(row.totalRoomAmount);
      value = value.toFixed(2);
    return <span>${row.totalRoomAmount?value:"00.00"}</span>
    },
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Booking Channel </H6>,
    selector: (row) => row.sourceOfBussiness,
    sortable: true,
    minWidth: "100px",
  },
  {
    name: <H6>Rate Plan</H6>,
    selector: (row) => row.ratePlan,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Rate Code Name</H6>,
    selector: (row) => <div className="text-center">{row.ratePlanName}</div>,
    sortable: true,
    center: true,
    wrap:true,
    minWidth: "200px",
  },
  {
    name: <H6>Eligible For Shashi Cash</H6>,
    selector: (row) => row.elegible_shashicash,
    sortable: true,
    center: true,
    minWidth: "150px",
  },
  {
    name: <H6>Enroll in Shashi Cash </H6>,
    selector: (row) => row.isLoyaltyApply,
    sortable: true,
    center: true,
    minWidth: "143px",
  },
  {
    name: <H6>Shashi Cash Amount </H6>,
    selector: (row) => {
      var value = parseFloat(row.loyaltyPoints);
      value = value.toFixed(2);
    return <span>${row.loyaltyPoints?value:"00.00"}</span>
    },
    sortable: true,
    center: true,
    minWidth: "150px",
  },
  {
    name: <H6>Setup Mobile key </H6>,
    selector: (row) => row.isExpressCheckInSetup,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Early Check-In </H6>,
    selector: (row) => row.earlyCheckIn,
    sortable: true,
    center: true,
    minWidth: "150px",
  },
  {
    name: <H6>Late Check-Out </H6>,
    selector: (row) => row.lateCheckOut,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6>Breakfast Package </H6>,
    selector: (row) => row.breakfastPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Cabana Package </H6>,
    selector: (row) => row.cabanaPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Business Package </H6>,
    selector: (row) => row.businessPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Extended Stay Package </H6>,
    selector: (row) => row.extendedPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Weekend Getaway Package </H6>,
    selector: (row) => row.weekendGetawayPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
  {
    name: <H6>Romance Package </H6>,
    selector: (row) => row.couplesPackage,
    sortable: true,
    center: true,
    minWidth: "140px",
  },
];
