import { Skeleton } from "@mui/material";
import React, { Fragment } from "react";
import tempJson from "../../tempDetails.json";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Loader() {
  return (
    <div style={{ marginTop: "30px" }} className="w-500px  my-preferences">
      {tempJson.Preferences.map(({ skelton, keepUnderLine, form }, index) => {
        return (
          <Fragment>
            <div className="flex justify-between mb-15px h-40px gap-30px items-center">
              <Skeleton variant="rounded" height={18} width={skelton.a + 50} />

              <div className="flex items-center">
                { form.name == "lightColor" ? (
                   <>
                   <Skeleton
                     variant="circular"
                     height={23}
                     width={23}
                   />
                   <KeyboardArrowDownIcon
                     style={{ fontSize: "30px", color: "#cfcfcf" }}
                   />
                 </>
                ) : (
                  <>
                    <Skeleton
                      variant="rounded"
                      height={18}
                      width={skelton.b + 30}
                    />
                    <KeyboardArrowDownIcon
                      style={{ fontSize: "30px", color: "#cfcfcf" }}
                    />
                  </>
                )}
              </div>
            </div>
            {keepUnderLine && <div className="underline mb-15px" />}
          </Fragment>
        );
      })}
      <div className="h-20px"></div>
      <div className="h-45px flex items-center  ">
        <Skeleton className="w-full" variant="rounded" height={45} />
      </div>
    </div>
  );
}

export default Loader;
