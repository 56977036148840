import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AxiosInterceptor,
  AxiosInterceptor2,
} from "../../../utils/axios-utils";
import Cryptr from "cryptr";
import { profile360_actions } from "./slice";

const getWeatherData = createAsyncThunk(
  "profile360/getWeatherData",
  async (params, { getState, rejectWithValue }) => {
    try {
      let response = await AxiosInterceptor2.get("getWeatherData", {
        headers: {
          emailId: params.email || "",
          reservationId: params.reservationId || "",
        },
      });
      return response.data?.result?.data || {};
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const saveCards = createAsyncThunk(
  "profile360/saveCards",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const profileData = ManageProfile360?.profile;
      const hotelData = ManageProfile360?.hotelData;
      if (
        hotelData.secretKey &&
        hotelData.hotelCode &&
        profileData.data?.email
      ) {
        const cryptr = new Cryptr(hotelData?.secretKey);
        const encrypted = cryptr.encrypt(
          JSON.stringify({ ...params.values, email: profileData.data?.email })
        );
        let res = await AxiosInterceptor2.post(
          `v1/cardTokenization/${hotelData.hotelCode}/web/enc`,
          { card: encrypted }
        );
        if (res?.data?.success) {
          let saveCardRes = await AxiosInterceptor2.post(
            `v1/saveCards/${hotelData.hotelCode}/web/encSave`,
            {
              encryptedCard: res?.data?.result?.data,
              hotelId: hotelData.hotelId,
              isPrefered: true,
              paymentMode: hotelData.paymentMode,
              isFromFD: true,
              emailId: profileData.data?.email,
            }
          );
          console.log(saveCardRes.data, "saveCardRes.data");
          dispatch(getProfileData(params.queryProps));
          return {};
        } else {
          throw {
            message: res.data.result?.message || res.data.message,
            custom: true,
          };
        }
      } else {
        throw { message: "Please try again later", custom: true };
      }
    } catch (error) {
      if (error?.custom) {
        alert(JSON.stringify(error?.message));
      } else if (error?.response?.data?.result?.message) {
        alert(JSON.stringify(error?.response?.data?.result?.message));
      }
      console.log(error, "error");

      throw rejectWithValue(error?.response?.data);
    }
  }
);

const userProfileUpdate = createAsyncThunk(
  "profile360/userProfileUpdate",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const profile = ManageProfile360?.profile;
      let response = await AxiosInterceptor.post(
        "frontdesk/UserProfileUpdate",
        { ...params, email: profile.data?.email }
      );
      return {};
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const userRegistrationv5 = createAsyncThunk(
  "profile360/userRegistrationv5",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const profile = ManageProfile360?.profile;
      const reservationDetails = ManageProfile360?.reservationDetails;
      if (params?.profileSetup) {
        const vlu = params?.value;
        let updtvalues = {
          email: profile.data?.email,
        };

        if (!profile.data?.email && vlu?.email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(vlu?.email)) {
            alert("Please provide valid email");
            return;
          }
          await AxiosInterceptor.post("userRegistrationv5", {
            email: vlu?.email,
          });
        }
        // fullName
        if (!profile.data.firstName && !profile.data.lastName) {
          if (
            reservationDetails.data?.firstName ||
            reservationDetails.data?.lastName
          ) {
            updtvalues.firstName = reservationDetails.data?.firstName;
            updtvalues.lastName = reservationDetails.data?.lastName;
            updtvalues.fullName = `${reservationDetails.data?.firstName} ${reservationDetails.data?.lastName}`;
          }
        }

        if (vlu?.phone && vlu?.countryCode) {
          updtvalues.countryCodeForMobileNumber = vlu?.countryCode;
          updtvalues.mobileNoWithoutCountryCode = vlu?.phone;
          updtvalues.phoneNo = `${vlu?.countryCode} ${vlu?.phone}`;
        }

        if (
          (vlu?.phone && vlu?.countryCode) ||
          params?.value?.for == "create"
        ) {
          if (profile.data?.email || vlu?.email) {
            if (!updtvalues.email) {
              updtvalues.email = vlu?.email;
            }
            await AxiosInterceptor.post(
              "frontdesk/updtUserDetails",
              updtvalues
            );
          }
        }

        dispatch(getProfileData(params.queryProps));

        return { disableEnrollLoader: true };
      } else {
        const enrollValues = {
          isLoyaltyApply: true,
          email: params?.value?.email,
        };
        const queryProps = params.queryProps;
        if (params?.value?.alreadyRegister) {
          dispatch(updateUserLoyaltyApply({ enrollValues, queryProps }));
          return {};
        } else {
          delete params?.value?.alreadyRegister;
          let res = await AxiosInterceptor.post("userRegistrationv5", {
            email: params?.value?.email,
          });
          dispatch(updateUserLoyaltyApply({ enrollValues, queryProps }));
          return {};
        }
      }
    } catch (error) {
      if (error.response?.data?.result?.message) {
        alert(JSON.stringify(error.response?.data?.result?.message));
      }
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const getProfileData = createAsyncThunk(
  "profile360/getProfileData",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      let response = await AxiosInterceptor2.get("getProfileData", {
        headers: {
          emailId: params.email || "",
          reservationId: params.reservationId || "",
        },
      });
      let data = response.data?.result?.data || {};
      dispatch(hotelEngagementLogs(data));
      return data;
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const updateUserLoyaltyApply = createAsyncThunk(
  "profile360/updateUserLoyaltyApply",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      let response = await AxiosInterceptor.post(
        "updateUserLoyaltyApply",
        params.enrollValues
      );
      await dispatch(getProfileData(params?.queryProps || {}));
      return response.data || {};
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const upgradeRoom = createAsyncThunk(
  "profile360/upgradeRoom",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      let body = {
        reservationId: params.reservationId,
        roomTypeCode: params.roomTypeCode,
      };

      console.log("before return", params, body);
      if (params.roomTypeCode && params?.reservationId) {
        let response = await AxiosInterceptor2.post("upgradeRoom", body);
        await dispatch(saveHistory(params));
        return response.data?.result?.data || {};
      } else {
        throw {
          message: "Please try again later",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error.custom) throw rejectWithValue(error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const addPackagesToReservation = createAsyncThunk(
  "profile360/addPackagesToReservation",
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      console.log("before return", params);
      if (params.reservationId) {
        let response = await AxiosInterceptor2.post(
          "addPackagesToReservation",
          {
            isFromFD: true,
            reservationId: params.reservationId,
            userReservationId: params.userReservationId,
            addOns: params.addOns,
          }
        );
        delete params.addOns;
        await dispatch(saveHistory(params));
        return response.data?.result?.data || {};
      } else {
        throw {
          message: "reservationId not found",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error) throw rejectWithValue(error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const newservicerequest = createAsyncThunk(
  "profile360/newservicerequest",
  async (params, { dispatch, rejectWithValue }) => {
    try {
      console.log(params);
      // return;
      if (params.body.reservationId && params.body.roomNo) {
        let response = await AxiosInterceptor2.post(
          "newservicerequest",
          params.body
        );
        delete params.body;
        await dispatch(saveHistory(params));
        return response.data?.result?.data || {};
      } else {
        throw {
          message: "roomNo or reservationId not found",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error.custom) throw rejectWithValue(error);
      if (error?.response?.data?.result?.message) {
        alert(error?.response?.data?.result?.message);
      }
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const findHistory = createAsyncThunk(
  "profile360/findHistory",
  async (params, { getState, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const reservationDetails =
        ManageProfile360.reservationDetails?.data || {};
      const headers = {
        reservationId: reservationDetails?.reservationId || "",
        emailId: reservationDetails?.emailId || "",
      };

      if (headers.reservationId || headers.emailId) {
        let response = await AxiosInterceptor2.get(
          "getPurposeFullEngagementHistory",
          {
            headers,
          }
        );
        return response.data?.result?.data || [];
      } else {
        throw {
          message: "reservationId or emailId not found",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error.custom) throw rejectWithValue(error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const saveHistory = createAsyncThunk(
  "profile360/saveHistory",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const reservationDetails =
        ManageProfile360.reservationDetails?.data || {};

      const body = {};
      body.type = params.parentKey;
      body.reservationId = reservationDetails?.reservationId || "";

      delete params.parentKey;
      params.isDone = true;
      body.history = params;

      console.log(body, "savePurposeFullEngagementHistory body");
      if (body.reservationId) {
        await AxiosInterceptor2.post("savePurposeFullEngagementHistory", body);

        await dispatch(
          getPurposeFullEngagementData({
            reservationId: reservationDetails.reservationId,
            email: reservationDetails.emailId,
          })
        );
        await dispatch(
          getReservationDetails({
            reservationId: reservationDetails.reservationId,
            email: reservationDetails.emailId,
          })
        );
        return {};
      } else {
        throw {
          message: "reservationId not found",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error.custom) throw rejectWithValue(error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const getReservationDetails = createAsyncThunk(
  "profile360/getReservationDetails",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      let response = await AxiosInterceptor2.get("getReservationDetails", {
        headers: {
          emailId: params.email || "",
          reservationId: params.reservationId || "",
        },
      });
      // fetch getPurposeFullEngagementData based on reservation id -->>
      dispatch(getPurposeFullEngagementData(response.data?.result?.data));
      return response.data?.result?.data || {};
    } catch (error) {
      if (error?.custom) {
        throw rejectWithValue(error);
      } else {
        throw rejectWithValue(error?.response?.data);
      }
    }
  }
);

const getPurposeFullEngagementData = createAsyncThunk(
  "profile360/getPurposeFullEngagementData",
  async (params, { rejectWithValue }) => {
    try {
      if (params.reservationId) {
        let response = await AxiosInterceptor2.get(
          "getPurposeFullEngagementData",
          {
            headers: {
              emailId: params.emailId,
              reservationId: params.reservationId || "",
            },
          }
        );
        return response.data?.result?.data || {};
      } else {
        throw {
          message: "reservationId not found",
          success: false,
          custom: true,
        };
      }
    } catch (error) {
      if (error.custom) throw rejectWithValue(error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const getAllRoomService = createAsyncThunk(
  "profile360/getAllRoomService",
  async (params, { getState, rejectWithValue }) => {
    try {
      const { fetchNavbar } = getState();
      const hotelId = fetchNavbar.profileData.hotelId;
      if (hotelId) {
        let response = await AxiosInterceptor2.get("getAllRoomService", {
          headers: { hotelId },
        });
        return response.data?.result?.data || [];
      } else {
        alert("hotelId not found");
      }
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);
const saveUserPreference = createAsyncThunk(
  "profile360/saveUserPreference",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const { profile } = ManageProfile360;
      if (profile.data?.email) {
        await AxiosInterceptor2.post("saveUserPreferenceV2", {
          email: profile.data?.email,
          ...params.data,
        });
        dispatch(getUserPreference(params.queryProps));
        return {};
      } else {
        throw { message: "Email not found", success: false };
      }
    } catch (error) {
      let msg = error?.response?.data?.result?.message;
      if (msg) {
        alert(JSON.stringify(msg));
      } else if (error?.custom) {
        alert(JSON.stringify(error?.message));
      }
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const getUserPreference = createAsyncThunk(
  "profile360/getUserPreference",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      let response = await AxiosInterceptor2.get("getUserPreference", {
        headers: {
          emailId: params.email || "",
          reservationId: params.reservationId || "",
        },
      });
      const data = response.data?.result?.data || {};
      dispatch(profile360_actions.preferenceInitialFormData(data));
      return data;
    } catch (error) {
      if (error?.custom) {
        throw rejectWithValue(error);
      } else {
        throw rejectWithValue(error?.response?.data);
      }
    }
  }
);

const saveHotelEngagementLogs = createAsyncThunk(
  "profile360/saveHotelEngagementLogs",
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const { profile } = ManageProfile360;

      if (profile.data?.email) {
        await AxiosInterceptor2.post("saveHotelEngagementLogs", {
          emailId: profile.data?.email,
          ...params,
        });
        await dispatch(hotelEngagementLogs());
        return {};
      } else {
        return {};
      }
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const hotelEngagementLogs = createAsyncThunk(
  "profile360/hotelEngagementLogs",
  async (params, { getState, rejectWithValue }) => {
    try {
      const { ManageProfile360 } = getState();
      const { profile } = ManageProfile360;
      let emailId = params?.email || profile?.data?.email || "";
      if (emailId) {
        let response = await AxiosInterceptor2.get("getHotelEngagementLogs", {
          headers: {
            emailId,
          },
        });
        return response.data?.result?.data || {};
      } else {
        return {};
      }
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const getProfilesFromLinkedIn = createAsyncThunk(
  "profile360/getProfilesFromLinkedIn",
  async (params, { rejectWithValue }) => {
    try {
      if (params?.reservationId) {
        let response = await AxiosInterceptor2.post(
          "getMatchingProfilesFromLinkedIn",
          { reservationId: params?.reservationId }
        );
        let data = response.data?.result?.data[0] || {};
        if (Array.isArray(data?.profiles)) {
          if (data?.profiles.length > 0) {
            return data;
          } else {
            if (typeof params?.cBackFun == "function") {
              params?.cBackFun();
            }
            return {};
          }
        }
      } else {
        if (typeof params?.cBackFun == "function") {
          params?.cBackFun();
        }
        return {};
      }
    } catch (error) {
      if (typeof params?.cBackFun == "function") {
        params?.cBackFun();
      }
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const attachLinkedInUrl = createAsyncThunk(
  "profile360/attachLinkedInUrl",
  async (params, { rejectWithValue }) => {
    try {
      if (params?.linkedInUrl && params?.reservationId) {
        let response = await AxiosInterceptor2.post("attachLinkedInUrl", {
          linkedInUrl: params?.linkedInUrl,
          reservationId: params?.reservationId,
        });
        params?.cBackFun();
        return response.data?.result?.data[0] || {};
      } else {
        params?.cBackFun();
        return {};
      }
    } catch (error) {
      params?.cBackFun();
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const detachLinkedInUrl = createAsyncThunk(
  "profile360/detachLinkedInUrl",
  async (cbackForLinkedIn, { rejectWithValue, getState }) => {
    try {
      const { ManageProfile360 } = getState();
      const { profile, reservationDetails } = ManageProfile360;
      if (
        (profile?.data?.linkedInUrl) &&
        reservationDetails?.data?.reservationId
      ) {
        await AxiosInterceptor2.post("detachLinkedInUrl", {
          linkedInUrl: profile?.data?.linkedInUrl,
          reservationId: reservationDetails?.data?.reservationId,
        });
        cbackForLinkedIn(true);
        return {};
      } else {
        cbackForLinkedIn(false);
        return {};
      }
    } catch (error) {
      cbackForLinkedIn(false);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const skipLinkedInSelection = createAsyncThunk(
  "profile360/skipLinkedInSelection",
  async (params, { rejectWithValue }) => {
    try {
      if (params?.reservationId
      ) {
        await AxiosInterceptor2.post("skipLinkedInSelection", {
          isLinkedInSelectionSkip: true,
          reservationId: params?.reservationId,
        });
        return {};
      } else {
        return {};
      }
    } catch (error) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const Profile360Apis = {
  getReservationDetails,
  getUserPreference,
  getWeatherData,
  getProfileData,
  updateUserLoyaltyApply,
  userRegistrationv5,
  saveCards,
  userProfileUpdate,
  // PurposeFullEngagement apis -->>
  getPurposeFullEngagementData,
  upgradeRoom,
  saveHistory,
  addPackagesToReservation,
  newservicerequest,
  findHistory,
  // <<-- PurposeFullEngagement apis
  getAllRoomService,
  saveUserPreference,
  // reportedIssue and profileNote apis -->>
  hotelEngagementLogs,
  saveHotelEngagementLogs,
  // linkedIn profile search  -->>
  getProfilesFromLinkedIn,
  attachLinkedInUrl,
  detachLinkedInUrl,
  skipLinkedInSelection
};
