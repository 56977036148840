import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { CardColumns, Col, Row } from "reactstrap";
import "./navtab.scss";
function CurrentReservation(props) {
  var { data } = props
  console.log('datadata',data)
  var { profile } = data;
  
  var isExpressCheckInSetup = profile?.isExpressCheckInSetup
    ? profile?.isExpressCheckInSetup
    : false
  var currentData = {
    arrivalDate: data.arrivalDate,
    departureDate: data.departureDate,
    checkinTime: data.checkInTime[0],
    checkoutTime: data.checkOutTime[0],
    reservationId: data.reservationId[0],
    keyGenerated: data.isKeyGen,
    status: data.inforStatus[0],
    roomNo: data.roomNo[0],
    isAddedReservation: data.isAddedReservation,
    createdBy: data.reservationCreatedId,
    updatedBy: data.reservationLastModifierId,
    specialRequest: data.specialRequest,
  }

  const { state } = useLocation();
  const {
    arrivalDate,
    checkinTime,
    checkoutTime,
    createdBy,
    departureDate,
    isAddedReservation,
    keyGenerated,
    reservationId,
    roomNo,
    specialRequest,
    status,
    updatedBy,
  } = currentData;
  
  function trueOrFalseIcon(data) {
    switch (data) {
      case true:
        return (
          <i
            style={{ color: "#29d577" }}
            className="icofont icofont-tick-mark"
          ></i>
        );
      case false:
        return (
          <i style={{ color: "#d10000" }} className="icofont icofont-close"></i>
        );
      case "Yes":
        return (
          <i
            style={{ color: "#29d577" }}
            className="icofont icofont-tick-mark"
          ></i>
        );
      case "No":
        return (
          <i style={{ color: "#d10000" }} className="icofont icofont-close"></i>
        );
      default : return (
        <i style={{ color: "#d10000" }} className="icofont icofont-close"></i>
      );
    }
  }
  return (
    <Fragment>
      <Row className="current-reservation">
        <Col className="column" sm="12" md="6">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Arrival Date</label>
                  <label className="text-title">{arrivalDate}</label>
                </div>
              </Col>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Departure Date</label>
                  <label className="text-title">{departureDate}</label>
                </div>
              </Col>
              <Col sm="6" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Check-in time</label>
                  <label className="text-title">{checkinTime?checkinTime:'--'}</label>
                </div>
              </Col>
              <Col sm="6" className="mt-4">
                <div className="d-flex flex-column line-height">
                <label className="text-label">Check-out time</label>
                  <label className="text-title">{checkoutTime?checkoutTime:'--'}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="6">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Reservation ID</label>
                  <label className="text-title">{reservationId}</label>
                </div>
              </Col>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Key Generated</label>
                  <label className="text-title">{trueOrFalseIcon(keyGenerated)}</label>
                </div>
              </Col>
              <Col sm="6" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Status</label>
                  <label
                    className={`status ${
                      status == "Reserved" ? `color-brown` : `color-green`
                    }`}
                  >
                    {status}
                  </label>
                </div>
              </Col>
              <Col sm="6" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Room No</label>
                  <label className="text-title">{roomNo?roomNo:"--"}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm="12">
          <Card className="p-4">
            <Row>
              <Col md="3" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Express CheckIn Setup</label>
                  <label className="text-title">
                    {trueOrFalseIcon(isExpressCheckInSetup)}
                  </label>
                </div>
              </Col>
              <Col md="3" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Is Added Reservation</label>
                  <label className="text-title">{trueOrFalseIcon(isAddedReservation)}</label>
                </div>
              </Col>
              <Col md="3" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Created By</label>
                  <label className="text-title">{createdBy?createdBy:"--"}</label>
                </div>
              </Col>
              <Col md="3" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Updated By</label>
                  <label className="text-title">{updatedBy?updatedBy:"--"}</label>
                </div>
              </Col>
              <Col sm="12" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Special Instructions</label>
                  <label className="text-title">
                   {specialRequest}
                  </label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default CurrentReservation;
