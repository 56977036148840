// getReservationDetails -->>
const getReservationDetails = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.reservationDetails.loader = true;
      state.reservationDetails.data = {};
      break;
    case "fulfilled":
      state.reservationDetails.loader = false;
      state.reservationDetails.data = payload;
      break;
    case "rejected":
      state.reservationDetails.loader = false;
      state.reservationDetails.data = {};
      break;
    default:
      break;
  }
};

// getUserPreference -->>
const getUserPreference = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.preferenceDetails.loader = true;
      state.preferenceDetails.data = {};
      break;
    case "fulfilled":
      state.preferenceDetails.loader = false;
      state.preferenceDetails.data = payload;
      break;
    case "rejected":
      state.preferenceDetails.loader = false;
      state.preferenceDetails.data = {};
      break;
    default:
      break;
  }
};

// getWeatherData -->>
const getWeatherData = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.weather.loader = true;
      state.weather.data = {};
      break;
    case "fulfilled":
      let obj = { dummy: true };
      state.weather.loader = false;
      let modify = { ...payload };
      if (Array.isArray(payload.guestWeatherData)) {
        if (payload.guestWeatherData.length <= 3) {
          let guestFlag = 3 - payload.guestWeatherData.length;
          for (let i = 0; i < guestFlag; i++) {
            modify.guestWeatherData.push({ dummy: true });
          }
        }
      } else {
        modify.guestWeatherData = [obj, obj, obj];
      }
      if (Array.isArray(payload.hotelWeatherData)) {
        if (payload.hotelWeatherData.length <= 3) {
          let hotelFlag = 3 - payload.hotelWeatherData.length;
          for (let i = 0; i < hotelFlag; i++) {
            modify.hotelWeatherData.push({ dummy: true });
          }
        }
      } else {
        modify.hotelWeatherData = [obj, obj, obj];
      }

      state.weather.data = modify;
      break;
    case "rejected":
      state.weather.loader = false;
      state.weather.data = {};
      break;
    default:
      break;
  }
};

// getWeatherData -->>
const getProfileData = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.profile.loader = true;
      state.profile.data = {};
      break;
    case "fulfilled":
      state.profile.loader = false;
      let cardOnFile = Array.isArray(payload.cardData)
        ? payload.cardData.length > 0
          ? true
          : false
        : false;
      state.profile.data = { ...payload, cardOnFile };
      state.mobileKeySetup.apiSuccess = false;
      break;
    case "rejected":
      state.profile.loader = false;
      state.profile.data = {};
      break;
    default:
      break;
  }
};

// enroll Email =>>
const updateUserLoyaltyApply = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.enroll.loader = true;
      break;
    case "fulfilled":
      console.log("updateUserLoyaltyApply", payload);
      state.enroll.loader = false;
      state.modal.isOpen = false;
      state.modal.isFor = "";
      break;
    case "rejected":
      state.enroll.loader = false;
      state.modal.isOpen = false;
      state.modal.isFor = "";
      break;
    default:
      break;
  }
};

// userRegistrationv5 email and phone =>>
const userRegistrationv5 = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.enroll.loader = true;
      state.userRegistration.loader = true;
      break;
    case "fulfilled":
      if (payload?.disableEnrollLoader === true) {
        state.enroll.loader = false;
      }
      state.userRegistration.loader = false;
      break;
    case "rejected":
      state.userRegistration.loader = false;
      state.enroll.loader = false;
      break;
    default:
      break;
  }
};

// userRegistrationv5 email and phone =>>
const saveCards = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.saveCards.loader = true;
      break;
    case "fulfilled":
      state.saveCards.loader = false;
      state.modal.isOpen = false;
      break;
    case "rejected":
      state.saveCards.loader = false;
      state.modal.isOpen = false;
      break;
    default:
      break;
  }
};

// user Profile update ->>>
const userProfileUpdate = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.mobileKeySetup.loader = true;
      break;
    case "fulfilled":
      state.mobileKeySetup.loader = false;
      state.modal.isOpen = false;
      state.mobileKeySetup.apiSuccess = true;
      break;
    case "rejected":
      state.mobileKeySetup.loader = false;
      state.modal.isOpen = false;
      break;
    default:
      break;
  }
};

const getPurposeFullEngagementData = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.purposefulEngagement.data = {};
      state.purposefulEngagement.loader = true;
      break;
    case "fulfilled":
      console.log("getPurposeFullEngagementData", payload);
      state.purposefulEngagement.loader = false;
      state.purposefulEngagement.data = payload;
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    case "rejected":
      state.purposefulEngagement.loader = false;
      state.purposefulEngagement.data = {};
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    default:
      break;
  }
};

const upgradeRoom = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.upgradeRoom.loader = true;
      break;
    case "fulfilled":
      console.log("upgradeRoom", payload);
      state.upgradeRoom.loader = false;
      break;
    case "rejected":
      state.upgradeRoom.loader = false;
      break;
    default:
      break;
  }
};

const saveHistory = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.saveHistory.loader = true;
      break;
    case "fulfilled":
      console.log("saveHistory", payload);
      state.saveHistory.loader = false;
      break;
    case "rejected":
      state.saveHistory.loader = false;
      break;
    default:
      break;
  }
};

const addPackagesToReservation = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.addPackagesToReservation.loader = true;
      break;
    case "fulfilled":
      console.log("addPackagesToReservation", payload);
      state.addPackagesToReservation.loader = false;
      break;
    case "rejected":
      state.addPackagesToReservation.loader = false;
      break;
    default:
      break;
  }
};

const newservicerequest = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.newservicerequest.loader = true;
      break;
    case "fulfilled":
      console.log("newservicerequest", payload);
      state.newservicerequest.loader = false;
      break;
    case "rejected":
      state.newservicerequest.loader = false;
      break;
    default:
      break;
  }
};

const findHistory = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.findHistory.loader = true;
      state.findHistory.data = [];
      break;
    case "fulfilled":
      console.log("findHistory", payload);
      state.findHistory.loader = false;
      state.findHistory.data = payload;
      break;
    case "rejected":
      state.findHistory.loader = false;
      state.findHistory.data = [];
      break;
    default:
      break;
  }
};

const getAllRoomService = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.getAllRoomService.loader = true;
      state.getAllRoomService.data = [];
      break;
    case "fulfilled":
      console.log("getAllRoomService", payload);
      state.getAllRoomService.loader = false;
      let tempObj = {};
      if (Array.isArray(payload)) {
        payload.map((e, index) => {
          tempObj[e.requestKey] = e?.options;
        });
      }
      state.getAllRoomService.data = tempObj;
      break;
    case "rejected":
      state.getAllRoomService.loader = false;
      state.getAllRoomService.data = [];
      break;
    default:
      break;
  }
};

const saveUserPreference = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.saveUserPreference.loader = true;
      break;
    case "fulfilled":
      console.log("saveUserPreference", payload);
      state.saveUserPreference.loader = false;
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    case "rejected":
      state.saveUserPreference.loader = false;
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    default:
      break;
  }
};

const hotelEngagementLogs = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.hotelEngagementLogs.loader = true;
      state.hotelEngagementLogs.data = {};
      break;
    case "fulfilled":
      console.log("hotelEngagementLogs", payload);
      state.hotelEngagementLogs.loader = false;
      state.hotelEngagementLogs.data = payload || {};
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    case "rejected":
      state.hotelEngagementLogs.loader = false;
      state.hotelEngagementLogs.data = {};
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    default:
      break;
  }
};

const saveHotelEngagementLogs = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      console.log("pending");
      state.saveHotelEngagementLogs.loader = true;
      break;
    case "fulfilled":
      console.log("saveHotelEngagementLogs", payload);
      state.saveHotelEngagementLogs.loader = false;
      break;
    case "rejected":
      state.saveHotelEngagementLogs.loader = false;
      state.modal = {
        isOpen: false,
        isFor: "",
        title: "",
        data: {},
      };
      break;
    default:
      break;
  }
};

const getProfilesFromLinkedIn = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.profilesFromLinkedIn.loader = true;
      state.profilesFromLinkedIn.data = {};
      break;
    case "fulfilled":
      console.log("profilesFromLinkedIn", payload);
      state.profilesFromLinkedIn.loader = false;
      state.profilesFromLinkedIn.data = payload;
      break;
    case "rejected":
      state.profilesFromLinkedIn.loader = false;
      state.profilesFromLinkedIn.data = {};
      break;
    default:
      break;
  }
};

const attachLinkedInUrl = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.attachLinkedInUrl.loader = true;
      state.attachLinkedInUrl.data = {};
      break;
    case "fulfilled":
      console.log("attachLinkedInUrl", payload);
      state.attachLinkedInUrl.loader = false;
      state.attachLinkedInUrl.data = payload;
      break;
    case "rejected":
      state.attachLinkedInUrl.loader = false;
      state.attachLinkedInUrl.data = {};
      break;
    default:
      break;
  }
};
const detachLinkedInUrl = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.detachLinkedInUrl.loader = true;
      break;
    case "fulfilled":
      console.log("detachLinkedInUrl", payload);
      state.detachLinkedInUrl.loader = false;
      break;
    case "rejected":
      state.detachLinkedInUrl.loader = false;
      break;
    default:
      break;
  }
};

const skipLinkedInSelection = (state, { payload, meta }) => {
  switch (meta.requestStatus) {
    case "pending":
      state.skipLinkedInSelection.loader = true;
      break;
    case "fulfilled":
      console.log("skipLinkedInSelection", payload);
      state.skipLinkedInSelection.loader = false;
      break;
    case "rejected":
      state.skipLinkedInSelection.loader = false;
      break;
    default:
      break;
  }
};
// keep function name same as api other wise it will throw error -->>
export const profile360ApiHelper = {
  getReservationDetails,
  getUserPreference,
  getWeatherData,
  getProfileData,
  updateUserLoyaltyApply,
  userRegistrationv5,
  saveCards,
  userProfileUpdate,
  // PurposeFullEngagement helper -->>
  getPurposeFullEngagementData,
  upgradeRoom,
  saveHistory,
  addPackagesToReservation,
  newservicerequest,
  findHistory,
  // PurposeFullEngagement helper -->>
  getAllRoomService,
  saveUserPreference,
  // reportedIssue and profileNote apis -->>
  hotelEngagementLogs,
  saveHotelEngagementLogs,
  getProfilesFromLinkedIn,
  attachLinkedInUrl,
  detachLinkedInUrl,
  skipLinkedInSelection
};
