import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
} from "reactstrap";

import ServiceTableData from "./ServiceTableData";
import { Breadcrumbs } from "../../AbstractElements";
import { fetchHistory, fetchServices } from "../../actions/axios-actions";
import DataTable from "react-data-table-component";
import { HistoryColumns, frontDeskServiceColumns, otherDepartmentServiceColumns } from "../../Data/Services";
import Loader from "../../CommonElements/Loader";
import TimelineTab from "./TimelineTab";
import { io } from "socket.io-client";
import { config } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonModal from "../../_core/Ui-kits/Modals/common/modal";
import { CircleIcon, CloseIcon, OpenIcon } from "../../Data/Icons";


const ServicePageContain = () => {
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [frontDeskServiceData, setFrontDeskServiceData] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setsearchText] = useState('');
  const [departmentName, setDepartmentName] = useState('Front Desk');
  const [selRequestType, setSelRequestType] = useState(false);
  const [selRequestStatus, setSelRequestStatus] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleModal = () => setIsModal(!isModal);

  let x = "Preferences"
  let Light = "Low";
  if (modalData.viewtype == "minibar") x = "Stock Your Mini Bar";
  if (modalData.viewtype == "room_preference") x = "Room Preferences";

  if(modalData.preferenceData?.lightPercentage === 0) Light = "Off";
  if(modalData.preferenceData?.lightPercentage !=0 &&  modalData.preferenceData?.lightPercentage > 10) Light = "Low";
  if(modalData.preferenceData?.lightPercentage >= 50 && modalData.preferenceData?.lightPercentage <= 60) Light = "Medium";
  if(modalData.preferenceData?.lightPercentage > 65 ) Light = "High";

  useEffect(() => {
    if (socket === null) {
      setSocket(io(config.RESERVATION_API_HOST));
    }
    if (socket) {
      socket.on("connect", () => {
        console.log("Connected Inside");
      });
      socket.on("service_request_notify", function (data) {
        fetchServices({
          page: 1,
          size: perPage,
          setFrontDeskServiceData,
          setData,
          setHistoryData,
          setTotalRows,
          setLoading,
        });
      });
    }
    return () => {
      if(socket){
        console.log('offing socket...')
        socket.off("service_request_notify");
        console.log('offed socket...')
      }
    };
  },[socket])
  useEffect(() => {
    fetchServices({
      page: 1,
      size: perPage,
      setFrontDeskServiceData,
      setData,
      setHistoryData,
      setTotalRows,
      setLoading,
    });
    return () => {
      setFrontDeskServiceData({});
      setData({});
      setHistoryData({});

    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (departmentName === "History") {
      fetchHistory({
        size: perPage,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    } else {
      fetchServices({
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    }
  };

  const handlePageChange = (page) => {
    if (departmentName === "History") {
      fetchHistory({
        page,
        size: perPage,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
        requestStatus: selRequestStatus.value,
      });
    } else {
      fetchServices({
        page,
        size: perPage,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    }
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    if (departmentName === "History") {
      fetchHistory({
        page,
        departmentName,
        searchText,
        setHistoryData,
        setData,
        setTotalRows,
        setLoading,
        size: newPerPage,
      });
    }
    else {
      fetchServices({
        page,
        size: newPerPage,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    }
    setPerPage(newPerPage);
  };

  const setValue = async (e, value) => {
    e.preventDefault();
    let departmentNameTemp = null;
    departmentNameTemp = value;
    setDepartmentName(value);

    if (value === "History") {
      await fetchHistory({
        departmentName: departmentNameTemp,
        searchText,
        setHistoryData,
        setData,
        setTotalRows,
        setLoading,
      });
    }
    else {

      await fetchServices({
        departmentName: departmentNameTemp,
        searchText,
        setFrontDeskServiceData,
        setData,
        setTotalRows,
        setLoading,
      });
    }
  };
  const handlefieldChange = (obj) => {
    console.log(obj);
    setSelRequestType(obj);
    fetchServices({
      requestStatus: selRequestStatus ? selRequestStatus.value : false,
      packageType: obj.value,
      searchText,
      departmentName,
      setFrontDeskServiceData,
      setData,
      setHistoryData,
      setTotalRows,
      setLoading,
    });
  };

  const handleStatusChange = (obj) => {

    console.log(obj);


    setSelRequestStatus(obj);

    if (departmentName === "History") {
      fetchHistory({
        packageType: selRequestType ? selRequestType.value : "",
        requestStatus: obj ? obj.value : false,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    } else {

      fetchServices({
        packageType: selRequestType ? selRequestType.value : "",
        requestStatus: obj ? obj.value : false,
        searchText,
        departmentName,
        setFrontDeskServiceData,
        setData,
        setHistoryData,
        setTotalRows,
        setLoading,
      });
    }

  };

  const handleRowClicked = (row) => {
    setIsModal(true);
    setModalData(row);
    console.log(row);
  }

  console.log('totalRows >>', totalRows)
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Services">
        {isModal &&
          (modalData.viewtype === "minibar" ||
            modalData.viewtype === "room_preference") && (
            <CommonModal
              isOpen={isModal}
              title={x}
              toggler={handleModal}
              disabledButtombuttons={true}
            >
              {modalData.viewtype === "minibar" && (
                <ul>
                  <li className="d-flex align-items-center justify-content-between p-2">
                    <span className="">
                      <b className="d-flex align-items-center dark-span justify-content-center">
                        {"Service Request"}
                      </b>
                    </span>
                    <span className="">
                      <b className="text-start dark-span">{"Quantity"}</b>
                    </span>
                  </li>
                  {modalData.requests?.map((request) => (
                    <li className="d-flex align-items-center justify-content-between p-2">
                      <span className="">
                        <span className="d-flex align-items-center justify-content-center ">
                          {modalData.status === "completed" ||
                          modalData.status === "assigned" ? (
                            <>
                              {console.log(modalData.status)}
                              <OpenIcon className="me-2" />
                              <span className="mt-1 dark-span right-font">
                                &nbsp;&nbsp;{request.name}
                              </span>
                            </>
                          ) : (
                            <>
                              {console.log(modalData.status)}
                              <CircleIcon className="me-3" />
                              <span className="mt-1 dark-span right-font">
                                &nbsp;&nbsp;{request.name}
                              </span>
                            </>
                          )}
                        </span>
                      </span>
                      <b className="text-start dark-span ">
                        {request.quantity}
                      </b>
                    </li>
                  ))}
                </ul>
              )}
              {modalData.viewtype === "room_preference" && (
                <>
                  {Object.keys(modalData.preferenceData).length > 0 && (
                    <>
                      {modalData.preferenceData.sleepQuality.length > 0 && (
                        <li className="d-flex align-items-center justify-content-between p-2">
                          <span className="">
                            <span className="d-flex align-items-center justify-content-between text-left">
                              {modalData.status === "completed" ||
                              modalData.status === "assigned" ? (
                                <>
                                  {console.log(modalData.status)}
                                  <OpenIcon className="me-2" />
                                  <span className=" mt-1 dark-span  right-font ">
                                    &nbsp;&nbsp;{"Sleep Quantity"}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {console.log(modalData.status)}
                                  <CircleIcon className="me-3" />
                                  <span className=" mt-1 dark-span right-font ">
                                    &nbsp;&nbsp;{"Sleep Quantity"}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                          <span
                            className="text-start dark-span left-font  text-end"
                            style={{ maxWidth: "65%" }}
                          >
                            {modalData.preferenceData?.sleepQuality.map(
                              (e, i) =>
                                e.key +
                                (i + 1 ==
                                modalData.preferenceData?.sleepQuality.length
                                  ? ""
                                  : ", ")
                            )}
                          </span>
                        </li>
                      )}

                      {modalData.preferenceData.pillowType.name && (
                        <li className="d-flex align-items-center justify-content-between p-2">
                          <span className="">
                            <span className="d-flex align-items-center justify-content-center ">
                              {modalData.status === "completed" ||
                              modalData.status === "assigned" ? (
                                <>
                                  <OpenIcon className="me-2" />
                                  <span className=" mt-1 dark-span right-font">
                                    &nbsp;&nbsp;
                                    {modalData.preferenceData.pillowType.name}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <CircleIcon className="me-3" />
                                  <span className=" mt-1 dark-span right-font">
                                    &nbsp;&nbsp;
                                    {modalData.preferenceData.pillowType.name}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                          <span className="text-start dark-span left-font">
                            {modalData.preferenceData.pillowType.key}
                          </span>
                        </li>
                      )}
                      {modalData.preferenceData.roomSelection.name && (
                        <li className="d-flex align-items-center justify-content-between p-2">
                          <span className="">
                            <span className="d-flex align-items-center justify-content-center">
                              {modalData.status === "completed" ||
                              modalData.status === "assigned" ? (
                                <>
                                  <CircleIcon className="me-2" />
                                  <span className=" mt-1 dark-span  right-font">
                                    &nbsp;&nbsp;
                                    {
                                      modalData.preferenceData.roomSelection
                                        .name
                                    }
                                  </span>
                                </>
                              ) : (
                                <>
                                  <CircleIcon className="me-3" />
                                  <span className=" mt-1 dark-span  right-font">
                                    &nbsp;&nbsp;
                                    {
                                      modalData.preferenceData.roomSelection
                                        .name
                                    }
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                          <span className="text-start dark-span left-font">
                            {modalData.preferenceData.roomSelection.key}
                          </span>
                        </li>
                      )}
                      {modalData.preferenceData.typeOfWine.name && (
                        <li className="d-flex align-items-center justify-content-between p-2">
                          <span className="">
                            <span className="d-flex align-items-center justify-content-center">
                              {modalData.status === "completed" ||
                              modalData.status === "assigned" ? (
                                <>
                                  <CircleIcon className="me-2 " />
                                  <span className=" mt-1 dark-span   right-font ">
                                    &nbsp;&nbsp;
                                    {modalData.preferenceData.typeOfWine.name}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <CircleIcon className="me-3" />
                                  <span className=" mt-1 dark-span  right-font">
                                    &nbsp;&nbsp;
                                    {modalData.preferenceData.typeOfWine.name}
                                  </span>
                                </>
                              )}
                            </span>
                          </span>
                          <span className="text-start dark-span left-font">
                            {modalData.preferenceData.typeOfWine.key}
                          </span>
                        </li>
                      )}
                      <hr />

                      <li className="d-flex align-items-center justify-content-between p-2">
                        <span className=" dark-span right-font">
                          {modalData.preferenceData.calmingMusic.name}
                        </span>
                        <span className="text-start dark-span left-font">
                          {modalData.preferenceData.calmingMusic.key}
                        </span>
                      </li>

                      <li className="d-flex align-items-center justify-content-between p-2">
                        <span className="dark-span right-font">
                          {modalData.preferenceData.whiteNoise.name}
                        </span>
                        <span className="text-end  dark-span left-font">
                          {modalData.preferenceData.whiteNoise.key}
                        </span>
                      </li>

                      <li className="d-flex align-items-center  justify-content-between p-2">
                        <span className="dark-span right-font">
                          {modalData.preferenceData?.temperatureUnit ==
                          "fahrenheit"
                            ? "Fahrenheit"
                            : "Celsius"}
                        </span>
                        <span className="text-start dark-span left-font">
                          {modalData.preferenceData?.temperatureUnit ==
                          "fahrenheit"
                            ? modalData.preferenceData?.temperature
                            : modalData.preferenceData?.temperature}
                        </span>
                      </li>

                      <li className="d-flex align-items-center justify-content-between p-2">
                        <span className="dark-span right-font">
                          {"Lights  "}
                        </span>
                        <span className="text-start dark-span left-font">
                          {Light}
                        </span>
                      </li>
                      <li className="d-flex align-items-center justify-content-between p-2">
                        <span className="dark-span right-font">
                          {"Color  "}
                        </span>
                        <span className="text-start">
                          <span
                            style={{
                              display: "block",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              borderWidth: "1px",
                              borderColor: "black",
                              borderStyle: "solid",
                              margin: "5px",
                              background: `rgb(${modalData.preferenceData?.lightColorRed}, ${modalData.preferenceData?.lightColorGreen}, ${modalData.preferenceData?.lightColorBlue})`,
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            &nbsp;&nbsp;&nbsp;
                          </span>
                        </span>
                      </li>

                      <li className="d-flex align-items-center justify-content-between p-2">
                        <span className="dark-span right-font">
                          {"Music  "}
                        </span>
                        <span className="text-start dark-span left-font">
                          {modalData.preferenceData?.music.name}
                        </span>
                      </li>
                    </>
                  )}
                </>
              )}
            </CommonModal>
          )}
        <div className="social-tab p-0" style={{ float: "right" }}>
          <form onSubmit={(e) => handleSearch(e, { searchText })}>
            <InputGroup>
              <Input
                className="form-control"
                type="text"
                onChange={(e) => setsearchText(e.target.value)}
                value={searchText}
                placeholder="Search......."
              />
              <span className="input-group-text">
                <i
                  className="fa fa-search"
                  onClick={(e) => handleSearch(e, { searchText })}
                ></i>
              </span>
            </InputGroup>
          </form>
        </div>
      </Breadcrumbs>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <div className="pb-0">
                <TimelineTab
                  handleSearch={handleSearch}
                  changeSearchText={(e) => setsearchText(e.target.value)}
                  changeTabCallback={(e, value) => setValue(e, value)}
                  activeTab={departmentName}
                  searchText={searchText}
                  handlefieldChange={handlefieldChange}
                  handleStatusChange={handleStatusChange}
                />
              </div>
              <CardBody>
                <div className="order-history table-responsive">

                  {frontDeskServiceData && departmentName === "Front Desk" && (
                    <DataTable
                      responsive={true}
                      columns={frontDeskServiceColumns}
                      data={frontDeskServiceData}
                      progressPending={loading}
                      progressComponent={<Loader />}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                      searching={true}
                    />
                  )}

                  {frontDeskServiceData && departmentName === "History" && (
                    <DataTable
                      responsive={true}
                      columns={HistoryColumns}
                      data={historyData}
                      progressPending={loading}
                      progressComponent={<Loader />}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                      searching={true}
                      onRowClicked={handleRowClicked}
                    /> 
                  )}

                  {data && departmentName !== "Front Desk" && departmentName !== "History" && (
                    <DataTable
                      responsive={true}
                      columns={otherDepartmentServiceColumns}
                      data={data && data.sort((a, b) => (b.createdAtUnix) - (a.createdAtUnix))}
                      progressPending={loading}
                      progressComponent={<Loader />}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                      searching={true}
                      onRowClicked={handleRowClicked}
                    />
                  )}

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <style jsx global>{`
      .order-history .rdt_Table .rdt_TableHead h6, .order-history .rdt_Table .rdt_TableHead .h6{
        text-align: center;  
      } 

      .dark-span{
        color: black;
      }

      .right-font{
        font-size:15px !important;
        font-weight:lighter !important;
        opacity:0.8 !important;
      }

      .left-font{
        font-size:15px !important;
        font-weight:normal !important;
      }
    `}</style>

    </Fragment>
  );
};
export default ServicePageContain;
