import React from "react";
import { useDispatch } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useFormik } from "formik";
import { profile360Schema } from "./validationSchema";
import { Profile360Apis } from "../js/api";
import { Profile360_icon } from "../common/icons";
import { useSelector } from "react-redux";

function ShashiEnroll(props) {
  const dispatch = useDispatch();
  const enroll = useSelector(profile360Selector.enroll);
  const handleClose = () => dispatch(profile360_actions.closeModal());
  const profile = useSelector(profile360Selector.profile);

  const formik = useFormik({
    initialValues: { email: profile?.data?.email||profile?.data?.pmsEmailId || "" },
    enableReinitialize: true,
    validationSchema: profile360Schema.enroll,
    onSubmit: (value) =>
      dispatch(
        Profile360Apis.userRegistrationv5({
          value: {
            alreadyRegister: profile?.data?.email ? true : false,
            ...value,
          },
          queryProps: props,
        })
      ),
  });

  return (
    <form onSubmit={formik.handleSubmit} className="form-shashi-enroll w-394px">
      <div className="enroll-label mt-39px">
        Enter the Email ID of the customer:
      </div>
      <input
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className="mt-12px w-full"
        name="email"
        type="text"
        value={formik.values.email}
        disabled={profile?.data?.email ? true : false}
      />
      {formik.touched.email && formik.errors.email ? (
        <div
          style={{
            height: "24px",
            color: "#cc4949",
            fontSize: "12px",
            paddingTop: "1px",
          }}
        >
          {formik.errors.email}
        </div>
      ) : (
        <div style={{ height: "24px" }}></div>
      )}
      <div className="flex justify-end gap-17px">
        <button
          type="button"
          onClick={handleClose}
          className="form-btn form-btn-white"
        >
          Close
        </button>
        <button
          type={enroll.loader ? "button" : "submit"}
          className="form-btn form-btn-magenta flex justify-center items-center"
        >
          {enroll.loader ? (
            <Profile360_icon.threeDotLoader color="#ffff" />
          ) : (
            "Enroll"
          )}
        </button>
      </div>
    </form>
  );
}

export default ShashiEnroll;
