import React, { Fragment } from "react";
import { Profile360_icon } from "../common/icons";
import json from "../tempDetails.json";
import CustomButton from "../common/button";
import { useDispatch } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import moment from "moment";

function AiPlatform() {
  const dispatch = useDispatch();
  const profile = useSelector(profile360Selector.profile);

  const handleModal = (data) => {
    dispatch(profile360_actions.openModal(data));
  };

  const Child = ({
    description = "",
    isFor = "",
    text = "",
    modalTitle = "",
  }) => {
    const TempSkelton = () => (
      <Fragment>
        <Skeleton variant="circular" width={20} height={20} />

        <div className="ai-label w-fit">
          <div style={{ height: "0px", overflow: "hidden" }}>{description}</div>
          <Skeleton variant="rounded" width={"100%"} height={13} />
        </div>

        <Skeleton
          className="ml-auto"
          variant="rounded"
          width={78}
          height={23}
        />
      </Fragment>
    );

    if (profile.loader) return <TempSkelton />;

    let obj = { success: false, content: <></>, removeBtn: false };

    switch (isFor) {
      case "shashi-enroll":
        obj.success = profile.data?.isLoyaltyApply || false;
        obj.content = (
          <div style={{ maxWidth: "230px" }} className="ml-auto flex gap-8px mt-2px">
            <Profile360_icon.dollarCircle />
            <div
              style={{ overflowWrap: "anywhere" }}
              className="mt-1px content-1"
            >
              {profile.data?.primaryEmail? profile?.data?.email.split("+delete")[0] : "-"}
            </div>
          </div>
        );
        obj.removeBtn = profile.data?.isLoyaltyApply || false;
        break;
      case "add-new-card":
        const cardExpiryDate = profile?.data?.cardOnFile
          ? profile.data?.cardData[0]?.daysToExpire
          : false;
        if (cardExpiryDate) {
          if (cardExpiryDate > 30) {
            obj.success = true;
            obj.removeBtn = true;
            description = "Card on File";
          } else {
            if (cardExpiryDate <= 0) {
              obj.success = false;
              description = `Card Expired`;
            } else {
              obj.success = true;
              description = `Card Expiring in ${profile.data?.cardData[0]?.daysToExpire} Days`;
            }
          }
        } else {
          description = `Card Not found`;
        }

        if (profile.data?.email) {
          obj.content = (
            <CustomButton
              onClick={() =>
                handleModal({ isFor: "view-card", title: "Card Information" })
              }
              className="ml-auto"
              label={"VIEW CARD"}
              minWidth="78px"
            />
          );
        } else {
          obj.content = (
            <CustomButton
              className="ml-auto cursor-not-allowed"
              label={"ADD CARD"}
              minWidth="78px"
            />
          );

          obj.removeBtn = true;
        }
        break;
      case "mobile-key-setup":
        if (
          profile.data?.emailVerified &&
          profile?.data?.cardOnFile &&
          profile.data.isUserProfileSetup &&
          profile?.data?.isExpressCheckInSetup
        ) {
          obj.success = true;
        } else {
          obj.success = false;
        }
        break;
      case "my-preferences":
        if (!profile.data?.email) {
          obj.content = (
            <CustomButton
              className="ml-auto cursor-not-allowed"
              label={"SETUP"}
              minWidth="78px"
            />
          );
          obj.removeBtn = true;
          obj.success = false;
        } else {
          obj.success = true;
        }
      default:
        break;
    }

    return (
      <Fragment>
        <div className="flex justify-between w-full gap-4px">
          <div className="ai-label">
            <div className="flex flex-nowrap mt-2px gap-8px">
              {obj.success ? (
                <Profile360_icon.tick />
              ) : (
                <Profile360_icon.cross />
              )}
              <div className="mt-3px">{description}</div>
            </div>
          </div>
          {obj.removeBtn ? (
            obj.content
          ) : (
            <CustomButton
              onClick={() => handleModal({ isFor, title: modalTitle })}
              className="ml-auto"
              label={text}
              minWidth="78px"
            />
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <div className="ai-platform mt-21px">
      <div className="ai-title mb-11px">shashi.ai Platform</div>
      {json.shashiAiPlatform.map((data, idx) => (
        <div className="flex mb-20px items-center" key={idx}>
          <Child {...data} />
        </div>
      ))}
    </div>
  );
}

export default AiPlatform;
