import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { Profile360_icon } from "../common/icons";
import { useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import { useFormik } from "formik";
import { profile360Schema } from "./validationSchema";
import { Profile360Apis } from "../js/api";

function MobileKeySetup({ email, reservationId }) {
  const dispatch = useDispatch();
  const queryProps = { email, reservationId };
  const handleClose = () => dispatch(profile360_actions.closeModal());
  const mobileKeySetup = useSelector(profile360Selector.mobileKeySetup);
  const profile = useSelector(profile360Selector.profile);
  const [duration, setDuration] = useState(false);
  const formik = useFormik({
    initialValues: {
      idExpiryDate: "",
      isExpressCheckInSetup: false,
      isIdVerified: true,
    },
    validationSchema: profile360Schema.keySetup,
    onSubmit: (e) => dispatch(Profile360Apis.userProfileUpdate(e)),
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDuration(false);
    }, 100);
    return () => clearTimeout(timeout);
  });

  // if profile update need to recall this get api -->>
  useEffect(() => {
    if (mobileKeySetup.apiSuccess) {
      dispatch(Profile360Apis.getProfileData(queryProps));
    }
  }, [mobileKeySetup.apiSuccess]);

  const obj = {
    emailVerified: profile.data?.emailVerified,
    cardOnFile: profile?.data?.cardOnFile,
    profileSetup: profile.data.isUserProfileSetup,
    isExpressCheck: profile?.data?.isExpressCheckInSetup,
  };

  const Rules = ({ objKey, label }) => (
    <div className="flex gap-12px mb-18px items-center">
      {obj[objKey] ? <Profile360_icon.tick /> : <Profile360_icon.cross />}
      <div className="mt-3px rule-label">{label}</div>
      {objKey == "emailVerified" && obj[objKey] ? (
        <div className="ml-auto email-verified-label mt-3px">
          {profile?.data.email}
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <section className="form-shashi-key-setup mt-26px w-394px">
      <div className="flex flex-col">
        {[
          {
            objKey: "emailVerified",
            label: "Email Verify",
          },
          {
            objKey: "cardOnFile",
            label: "Card on File",
          },
          {
            objKey: "profileSetup",
            label: "Shashi App Profile Setup",
          },
        ].map((data, index) => (
          <Fragment key={index}>
            <Rules {...data} />
          </Fragment>
        ))}
      </div>

      {obj.cardOnFile && obj.emailVerified && obj.profileSetup ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="flex gap-12px item-center mt-11px">
            {obj.isExpressCheck ? (
              <Profile360_icon.tick />
            ) : (
              <div className="cursor-pointer radius-full overflow-hidden h-20px w-20px">
                {formik.values.isExpressCheckInSetup ? (
                  <Profile360_icon.tick
                    style={{
                      transition: "0.6s ",
                      transform: !duration ? "scale(1)" : "scale(1.5)",
                    }}
                    onClick={() => {
                      formik.setFieldValue("isExpressCheckInSetup", false);
                      setDuration(true);
                    }}
                  />
                ) : (
                  <Profile360_icon.radio
                    onClick={() => {
                      formik.setFieldValue("isExpressCheckInSetup", true);
                      setDuration(true);
                    }}
                  />
                )}
              </div>
            )}
            <div className="mt-3px rule-label">Enable Express Check-In</div>
          </div>
          {!obj.isExpressCheck && (
            <div className="mt-22px">
              <div className="rule-label">Add Expiry date</div>
              <PatternFormat
                value={formik.values.idExpiryDate}
                format={"####-##-##"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="idExpiryDate"
                placeholder="YYYY-MM-DD"
                className="w-full pl-13px mt-6px"
                type={"text"}
              />

              {formik.touched.idExpiryDate && formik.errors.idExpiryDate ? (
                <div className="h-13px error-text-clr mt-2px">
                  {formik.errors.idExpiryDate}
                </div>
              ) : (
                <div className="h-13px  mt-2px" />
              )}
            </div>
          )}
        </form>
      ) : (
        <div className="desc mt-14px">
          Note - <span> Please ensure this steps complete by the user.</span>
        </div>
      )}

      <div className="mt-30px flex justify-end gap-20px">
        <button
          type="button"
          onClick={handleClose}
          className="form-btn form-btn-white"
        >
          Close
        </button>

        {obj.cardOnFile &&
          obj.emailVerified &&
          obj.profileSetup &&
          !obj.isExpressCheck && (
            <button
              disabled={formik.values.isExpressCheckInSetup ? false : true}
              onClick={() => formik.submitForm()}
              className={`flex items-center justify-center form-btn form-btn-magenta ${
                !formik.values.isExpressCheckInSetup &&
                `cursor-not-allowed brightness-low`
              }`}
            >
              {mobileKeySetup.loader ? (
                <Profile360_icon.threeDotLoader />
              ) : (
                `Update`
              )}
            </button>
          )}
      </div>
    </section>
  );
}

export default MobileKeySetup;
