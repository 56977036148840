import React, { useState, useEffect } from "react";
import { Send } from "react-feather";
import { Button, Input, InputGroup } from "reactstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { H3 } from "../../AbstractElements";
import { sendNotificationsToUser } from "../../actions/axios-actions";

const SendNotification = ({ roomData }) => {
  const [customMsg, setCustomMsg] = useState("");
  const [loading3, setLoading3] = useState(false);
  const [sendMessageSuccess, setSendMessageSuccess] = useState("");
  const [sendMessageError, setSendMessageError] = useState("");

  useEffect(() => {
    sendMessageSuccess.length &&
      Swal.fire({
        icon: "success",
        text: sendMessageSuccess,
      }).then(() => {
        setSendMessageSuccess("");
      });

    sendMessageError.length &&
      Swal.fire({
        icon: "error",
        text: sendMessageError,
      }).then(() => {
        setSendMessageError("");
      });
  }, [sendMessageError, sendMessageSuccess]);

  const _handleKeyDownForCustomMsg = (e) => {
    if (e.key === "Enter") {
      sendPushNotification(customMsg);
    }
  };

  const sendPushNotification = (message) => {
    if (message.length) {
      sendNotificationsToUser(
        roomData,
        setLoading3,
        setCustomMsg,
        message,
        setSendMessageSuccess,
        setSendMessageError
      );
    } else {
      Swal.fire({
        icon: "error",
        text: "Please enter custom message.",
      });
    }
  };

  return (
    <div className="border p-2">
      <InputGroup>
        <Input
          style={{ borderRadius: 0 }}
          type="text"
          value={customMsg}
          placeholder="Write your custom message here"
          onKeyDown={_handleKeyDownForCustomMsg}
          onChange={(e) => setCustomMsg(e.target.value)}
        />
        <Button
          style={{ borderRadius: 0, border: "5px solid red", marginLeft: "10px" }}
          {...(!roomData ? { disabled: "disabled" } : {})}
          onClick={() => sendPushNotification(customMsg)}
        >
          {loading3 ? "SENDING..." : <Send className="font-white" />}
        </Button>
      </InputGroup>
    </div>
  );
};

export default SendNotification;
