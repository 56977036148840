import { changePassword } from "../actions/axios-actions";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Btn, H2 } from "../AbstractElements";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import React from "react";
import {
  InputGroupText,
  TabContent,
  InputGroup,
  Container,
  FormGroup,
  TabPane,
  Label,
  Input,
  Form,
  Col,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
    const [apiRes, setApiRes] = useState({message:"",success:false});
  const [togglePassword, setTogglePassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputArray = [
    { label: "Current Password", name: "currentPassword", toggle: "CURRENT" },
    { label: "New Password", name: "password", toggle: "NEW" },
    { label: "Confirm Password", name: "confirmPassword", toggle: "CONFIRM" },
  ];

  useEffect(()=>{
    var swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-secondary',
      },
      buttonsStyling: false
    })
    
    apiRes.message!==""&& 
    swalWithBootstrapButtons.fire({
        icon: apiRes.success?"success":'error',
        text: apiRes.message,
      }).then(() => {
        setApiRes({message:"",success:false});
        apiRes.success && navigate(-1)
      });
  },[apiRes])

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      confirmPassword: "",
      password: "",
    },
    onSubmit:(val)=> changePassword(val,setLoading,setApiRes),
    validationSchema: yup.object({
      currentPassword: yup
        .string()
        .required("Current Password is required")
        .min(6, "At least 6 characters")
        .max(12, "Maximum 12 characters"),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "At least 6 characters")
        .max(12, "Maximum 12 characters"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
  });

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div className="login-main1">
              <TabContent className="content-login">
                <TabPane className="fade show d-flex d-flex flex-column align-items-center mb-4">
                  <H2 attrH2={{className:"fw-normal"}}>Change Password</H2>
                </TabPane>

                <TabPane className="fade show">
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="theme-form login-form"
                  >
                    {inputArray.map(({ label, name, toggle }) => (
                      <FormGroup>
                        <Label>{label}</Label>
                        <InputGroup>
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                          <Input
                            className="form-control"
                            type={
                              toggle == togglePassword ? "text" : "password"
                            }
                            name={name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values[name]}
                          />
                          <div
                            className="show-hide"
                            onClick={() => {
                              togglePassword == toggle
                                ? setTogglePassword("")
                                : setTogglePassword(toggle);
                            }}
                          >
                            <span
                              className={togglePassword == toggle ? "" : "show"}
                            ></span>
                          </div>
                        </InputGroup>
                        <div
                          className="text-danger"
                          style={{ height: "16.5px" }}
                        >
                          {formik.errors && formik.errors[name]}
                        </div>
                      </FormGroup>
                    ))}
                    <FormGroup>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "btn-block",
                          //   disabled: loading ? loading : loading,
                          // onClick:()=>changePassword('dd','dds'),
                          type: "submit",
                        }}
                      >
                        {loading ? "LOADING..." : "Save"}
                      </Btn>
                    </FormGroup>
                  </Form>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;
