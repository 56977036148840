import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import { H5, H6, H3, Image, P, Btn, H4 } from "../../../AbstractElements";
import NavTab from "./NavTab";
import { NavLinks } from "./reducer";
import { getReservastionDetails } from "../../../actions/axios-actions";
import Loader from "../../../CommonElements/Loader";

const ProfileCard = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  let params = useParams();
  
  const [currentNavItemLink, setcurrentNavItemLink] = useState("current-reservation");
  let navTabProps = { currentNavItemLink, setcurrentNavItemLink };
  console.log(state);
  useEffect(() => {
    
    getReservastionDetails(setData,setLoading,{ reservation_id: params._id })
    
  }, []);
  if(!data){
    return <Loader />
  }
  console.log('data---',data);
  if(data && data.error){
    return(
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col lg={12}>
              <H4 color={"#0b97c4"}>{data?.error}</H4>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-3 xl-30">
              <div className="md-sidebar">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark custom-scrollbar">
                      <Media>
                        <div className="media-size-email">
                          <Image
                            attrImage={{
                              width: "48px",
                              height: "48px",
                              className: "me-3 rounded-circle",
                              src: `${
                                data.avatar
                                  ? data.avatar
                                  : "/avatar.png"
                              }`,
                              alt: "www",
                            }}
                          />
                        </div>
                        <Media body>
                          <H6 attrH6={{ className: "f-w-600" }}>
                            {data.guestName}
                          </H6>
                          <P>{data.emailId}</P>
                        </Media>
                      </Media>
                      <NavTab {...navTabProps} />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col xl="9" md="12" className="box-col-9 xl-70">
              <Card className="email-body radius-left ">
                {NavLinks.map(({ link, title, Component }, index) => {
                  return (
                    currentNavItemLink == link && (
                      <>
                        <CardHeader>
                          <H3 attrH3={{ className: "text-capitalize" }}>
                            {title}
                          </H3>
                          {currentNavItemLink == "folio" && (
                            <p className="text-capitalize text-black">
                              RoomNo: {data?.roomNo}
                            </p>
                          )}
                        </CardHeader>
                        <CardBody
                          style={{ maxHeight: "600px" }}
                          className="overflow-scroll"
                          key={index}
                        >
                          {Component && <Component data = {data}/>}
                        </CardBody>
                      </>
                    )
                  );
                })}
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default ProfileCard;
