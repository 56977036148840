import React, { Fragment, useState } from 'react';
import { AlertCircle, Clock, Image, Users } from 'react-feather';
import { Card, Col, Input, InputGroup, Nav, NavItem, NavLink } from 'reactstrap';

const TimelineTab = ({  callbackActive ,handleSearch, changeSearchText, changeTabCallback, activeTab, searchText  }) => {
 
  return (
    <Fragment>
      <Col sm="12 box-col-12">
        <div className="social-tab">
            <Nav tabs >
              <NavItem><NavLink className={activeTab === 'all' ? 'active' : ''} onClick={(e) => {
               changeTabCallback(e,'all');
              }}>All</NavLink>
              </NavItem> 
              <NavItem><NavLink className={activeTab === 'emailVerified' ? 'active' : ''} onClick={(e) => {
              changeTabCallback(e,'emailVerified');
              }}>Email Verified</NavLink>
              </NavItem>
              <NavItem ><NavLink className={activeTab === 'profileCompleted' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e,'profileCompleted');
              }}>Profile Completed</NavLink>
              </NavItem>
            </Nav>
            
           
          </div>
      </Col >
    </Fragment >
  );
};
export default TimelineTab;