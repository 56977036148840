import React from 'react'

export default function PageNotFound() {
  return (
    <div>
        <h1 style={{ height: "88vh", display: "grid", placeContent: "center" }}>
            You are not allowed to access this page.
        </h1>
    </div>
  )
}
