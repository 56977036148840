import FolioPage from "../Pages/FolioPage";
import MobileAppReservationsPage from "../Pages/MobileAppReservationsPage";
import MeetingsPage from "../Pages/MeetingsPage";
import MobileUsersPage from "../Pages/MobileUsersPage";
import LoginPage from "../Auth/LoginForm";
import ServicePage from "../Pages/ServicePage";
import PaymentTransactionPage from "../Pages/PaymentTransaction";
import ReservationDetailPage from "../Pages/MobileAppReservationsPage/reservationDetailPage";
import ChangePassword from "../Auth/changePassword";
import IotDashboard from "../Pages/IotDashboard";
import CustomerProfile360 from "../Pages/CustomerProfile360";



export var routes = [
  {
    path: "/login",
    Component: <LoginPage />,
  },
  {
    path: "/folio",
    Component: <FolioPage />,
  },
  {
    path: "/services",
    Component: <ServicePage />,
  },
  {
    path: "/hardware-dashboard",
    Component: <FolioPage />,
  },
  {
    path: "/mobile-app-reservations",
    Component: <MobileAppReservationsPage />,
  },
  {
    path: "/mobile-app-reservations/:_id",
    Component: <ReservationDetailPage />,
  },
  {
    path: "/meetings",
    Component: <MeetingsPage />,
  },
  {
    path: "/mobile-users",
    Component: <MobileUsersPage />,
  },
  {
    path: "/payment-transaction",
    Component: <PaymentTransactionPage />,
  },
  {
    path: "/change-password",
    Component: <ChangePassword />,
    hideLayout: true,
    userRoutes: true,
  },
  {
    path: "/iot-dashboard",
    Component: <IotDashboard />,
  },
  {
    path: "/profile-360",
    Component: <CustomerProfile360 />,
  },
];


