import { profile360Selector } from "../js/slice";
import MobileKeySetup from "./mobileKeySetup";
import { useSelector } from "react-redux";
import ShashiEnroll from "./shashiEnroll";
import React, { Fragment, useEffect, useState } from "react";
import AddNewCard from "./addNewCard";
import "./index.scss";
import ViewCard from "./viewCard";
import PurposefulModal from "../purposefulEngagement/purposefulModals";
import MyPreferences from "./mypreferences";
import IssueOrNoteForm from "./issueOrNote";

function Forms_360({ email = "", reservationId = "" }) {
  const modal = useSelector(profile360Selector.modal);
  const queryProps = { email, reservationId };
  const [a, b] = useState("");
  let setIsfor = () => b(modal);
  // give delay while close the modal
  useEffect(() => {
    let timeout;
    if (modal.isFor == "") {
      timeout = setTimeout(setIsfor, 5000);
    } else {
      setIsfor();
    }
    return () => clearTimeout(timeout);
  }, [modal.isFor]);
  
  switch (a?.isFor) {
    case "shashi-enroll":
      return <ShashiEnroll {...queryProps} />;
    case "mobile-key-setup":
      return <MobileKeySetup {...queryProps} />;
    case "add-new-card":
      return <AddNewCard {...queryProps} />;
    case "view-card":
      return <ViewCard {...queryProps} />;
    case "my-preferences":
      return <MyPreferences {...queryProps} />;
    case "purposeful-engagement":
      return <PurposefulModal data={a?.data} {...queryProps} />;
    case "profileNotes-or-reportedIssues":
        return <IssueOrNoteForm/>
    default:
      return <Fragment />;
  }
}

export default Forms_360;
