import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const jwt_token = localStorage.getItem("token");

  // const url = window.location.pathname;
  // const parts = url.split("/");
  // const hotelCode = parts[1];
  // console.log("hotelCode:", hotelCode);

  return jwt_token ? <Outlet /> : <Navigate exact to={`/login`} />;
};

export default PrivateRoute;

