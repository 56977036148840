import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SendNotification from "../../../Folio/sendNotification";

function Notification() {
  let { state } = useLocation();
  return (
    <Fragment>
      <SendNotification roomData={{ email: state?.email }} />
    </Fragment>
  );
}

export default Notification;
