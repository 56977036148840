import React from "react";
import "./index.scss";
import Profile from "./profile";
import Weather from "./weather";
import AiPlatform from "./aiPlatform";
import EmailOrPhone from "../form/emailOrPhone";
function UserProfile({ email, reservationId }) {
  const queryProps = { email, reservationId };
  return (
    <section className="user-profile">
      <Profile {...queryProps}/>
      <Weather {...queryProps} />
      <EmailOrPhone {...queryProps}/>
      <AiPlatform />
    </section>
  );
}

export default UserProfile;
