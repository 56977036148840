import React from "react";
import { Profile360_icon } from "./icons";

export default function CustomButton({
  label = "",
  minWidth = "72px",
  background = "#ffffff00",
  className = "",
  style = {},
  disabled = false,
  loader = false,
  loaderColor = "#F41095",
  ...props
}) {
  return disabled ? (
    <div
      style={{ minWidth: minWidth, backgroundColor: background, ...style }}
      className={`custom-button cursor-not-allowed flex justify-center items-center ${className}`}
    >
      {loader ? (
        <Profile360_icon.threeDotLoader height="12" color={loaderColor} />
      ) : (
        label
      )}
    </div>
  ) : (
    <button
      style={{ minWidth: minWidth, backgroundColor: background, ...style }}
      className={`custom-button cursor-pointer ${className}`}
      {...props}
    >
      {label}
    </button>
  );
}
