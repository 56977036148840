import { Btn } from "../../../AbstractElements";
import { Views } from "../../../Constant";
import { Nav, NavItem } from "reactstrap";
import { Bookmark } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import React, { Fragment } from "react";
import { NavLinks } from "./reducer";

const NavTab = (props) => {
  const { state } = useLocation();
  let { currentNavItemLink, setcurrentNavItemLink } = props;
  return (
    <Fragment>
      <Nav className="main-menu mt-3" role="tablist">
        {/* <NavItem>
          <Btn
            attrBtn={{
              color: "primary",
              className: "badge-light d-block btn-mail w-100",
              onClick: () => {
                setcurrentNavItemLink(NavLinks[0].link);
              },
            }}
          >
            <Bookmark /> {NavLinks[0].title}
          </Btn>
        </NavItem> */}
        {/* <NavItem>
          <span className="main-title">{Views}</span>
        </NavItem> */}
        {NavLinks.map((data, index) =>
          data.link == "folio" || data.link == "key-generation" ? (
            state?.inforStatus == "In-house" &&
            state?.roomNo && (
              <NavItem key={index}>
                <Link
                  to={""}
                  onClick={(e) => {
                    e.preventDefault();
                    setcurrentNavItemLink(data.link);
                  }}
                >
                  <span className="title"> {data.title}</span>
                </Link>
              </NavItem>
            )
          ) : (
            <NavItem key={index}>
              <Link
                to={""}
                onClick={(e) => {
                  e.preventDefault();
                  setcurrentNavItemLink(data.link);
                }}
              >
                <span className="title"> {data.title}</span>
              </Link>
            </NavItem>
          )
        )}
      </Nav>
    </Fragment>
  );
};
export default NavTab;
