import "./index.scss";
import React, { Fragment, useEffect } from "react";
import json from "../tempDetails.json";
import { Profile360_icon } from "../common/icons";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../js/api";
import { profile360Selector } from "../js/slice";
import { useSelector } from "react-redux";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";
function CurrentReservation({ email, reservationId }) {
  const dispatch = useDispatch();
  const { data, loader } = useSelector(profile360Selector.reservationDetails);

  useEffect(() => {
    dispatch(Profile360Apis.getReservationDetails({ email, reservationId }));
  }, []);

  const Content = ({
    textAlign = "left",
    float = "left",
    label = "",
    value,
    dateFormat = false,
    skelton = "20",
  }) => {
    return (
      <div className="mb-26px" style={{ textAlign }}>
        <div className="key">{label}</div>
        <div style={{ float }} className="value">
          {loader ? (
            <Skeleton
              variant={"rectangular"}
              width={skelton}
              height={16.8}
              style={{ borderRadius: "4px" }}
            />
          ) : (
            <>
              {typeof value == "object" ? (
                <>
                  {value.length > 0
                    ? value?.map((dta, index) => (
                        <Fragment key={index}>
                          {dta}
                          <br />
                        </Fragment>
                      ))
                    : `-`}
                </>
              ) : dateFormat ? (
                value ? (
                  moment(value).format("ddd, MMM D, YYYY")
                ) : (
                  "-"
                )
              ) : (
                value || "-"
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const Title = () => (
    <div className="flex w-full justify-between items-center mb-30px">
      <div className="title">{data?.resState || "Current Reservation"}</div>
      <span className="express-checkin flex items-center gap-8px">
        Express Check-In
        {data?.isUserOptedForMobileCheckIn ? (
          <Profile360_icon.tick />
        ) : (
          <Profile360_icon.radio />
        )}
      </span>
    </div>
  );

  return (
    <section
      className={`current-reservations ${
        !loader && `current-reservations-loading`
      }`}
    >
      <Title />
      <div className="grid grid-col-2 justify-between">
        {json.currentReservation.details.map((e, idx) => (
          <Fragment key={idx}>
            <Content
              textAlign={idx % 2 != 0 ? "right" : "left"}
              float={idx % 2 != 0 ? "right" : "left"}
              label={e.label}
              value={data[e.key]}
              array={e.array || false}
              dateFormat={e.dateFormat}
              skelton={e.skelton}
            />
          </Fragment>
        ))}
      </div>
    </section>
  );
}

export default CurrentReservation;
