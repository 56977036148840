import React, { useState, useEffect } from "react";
import { Key, Search, X } from "react-feather";
import { Button, Input, InputGroup } from "reactstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { H3 } from "../../AbstractElements";
import { reGenerateRoomKey } from "../../actions/axios-actions";
import {
  //   ChangeRoomButton,
  //   GenarateRoomKeyButton,
  SearchRoomButton,
} from "../../Constant";

const SearchRoom = ({
  roomNo,
  setRoomNo,
  setFolio,
  setRoomData,
  roomData,
  loading,
}) => {
  const [loading2, setLoading2] = useState(false);
  const [keyGenSuccessMessage, setKeyGenSuccessMessage] = useState("");
  const [keyGenErrorMessage, setKeyGenErrorMessage] = useState("");

  useEffect(() => {
    keyGenSuccessMessage.length &&
      Swal.fire({
        icon: "success",
        text: keyGenSuccessMessage,
      }).then(() => {
        setKeyGenSuccessMessage("");
      });

    keyGenErrorMessage.length &&
      Swal.fire({
        icon: "error",
        text: keyGenErrorMessage,
      }).then(() => {
        setKeyGenErrorMessage("");
      });
  }, [keyGenErrorMessage, keyGenSuccessMessage]);

  const RegenerateRoomKey = () => {
    reGenerateRoomKey(
      setLoading2,
      roomData,
      setKeyGenSuccessMessage,
      setKeyGenErrorMessage
    );
  };
  return (
    <div className="border p-2">
      <H3>Search Room</H3>
      <InputGroup>
        <Input
          style={{ borderRadius: 0 }}
          type="text"
          value={roomNo}
          placeholder="Search Room"
          onChange={(e) => setRoomNo(e.target.value)}
        />
        {!loading && roomData && roomData.roomNo ? (
          <>
            <Button
              style={{
                borderRadius: 0,
                border: "5px solid red",
                marginLeft: "10px",
              }}
              onClick={() => {
                setRoomNo("");
                setFolio([]);
                setRoomData(null);
              }}
            >
              {/* {ChangeRoomButton} */}
              <X className="font-white" />
            </Button>
            <Button
              style={{
                borderRadius: 0,
                border: "5px solid red",
                marginLeft: "10px",
              }}
              onClick={() => RegenerateRoomKey()}
            >
              {/* {GenarateRoomKeyButton} */}
              {loading2 ? "GENARATING..." : <Key className="font-white" />}
            </Button>
          </>
        ) : (
          <Button
            style={{
              borderRadius: 0,
              border: "5px solid red",
              marginLeft: "10px",
            }}
          >
            {/* {loading ? "LOADING..." : SearchRoomButton} */}
            {loading ? "SEARCHING..." : <Search className="font-white" />}
          </Button>
        )}
      </InputGroup>
    </div>
  );
};

export default SearchRoom;
