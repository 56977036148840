import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function CustomCheckBox({
  name = "",
  options = [],
  handleCustomCheckBox = () => {},
  value = "",
  type = "",
}) {
  return (
    <div className="relative">
      <Select
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
          "&.Mui-focused .MuiOutlinedInput-input": {
            boxShadow: "none",
          },
          ".MuiSelect-icon": {
            right: "0",
            fontSize: "30px",
            color: "black",
          },
          ".MuiSelect-select": {
            marginRight: "5px",
          },
        }}
        name={name}
        multiple
        value={value}
        renderValue={(selected) => {
          return (
            <div
              style={{ maxWidth: "285px" }}
              className="flex flex-wrap justify-end"
            >
              {selected.map((e, i) => (
                <div key={i}>
                  {e.key}
                  {selected.length !== 1 && selected.length - 1 != i ? (
                    <>,&nbsp;</>
                  ) : (
                    ``
                  )}
                </div>
              ))}
            </div>
          );
        }}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map((ev, index) => (
          <MenuItem
            onClick={() =>
              handleCustomCheckBox(
                ev,
                name,
                value.some((e) => e.id == ev.id)
              )
            }
            key={index}
            value={ev}
          >
            <Checkbox checked={value.some((e) => e.id == ev.id)} />
            <ListItemText primary={ev.key} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default CustomCheckBox;
