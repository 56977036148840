import React, { Fragment, useState } from 'react';
import { AlertCircle, Clock, Image, Users } from 'react-feather';
import { Card, Col, Input, InputGroup, Nav, NavItem, NavLink } from 'reactstrap';

const TimelineTab = ({ callbackActive ,handleSearch, changeSearchText, changeTabCallback, activeTab, searchText  }) => {
  /* const [activeTab, setActiveTab] = useState('1');
  const [searchText, setsearchText] = useState('') */
  return (
    <Fragment>
      <Col sm="12 box-col-12">
          <div className="social-tab">
            <Nav tabs >
              <NavItem><NavLink className={activeTab === 'all' ? 'active' : ''} onClick={(e) => {
               changeTabCallback(e,'all');
              }}>All</NavLink>
              </NavItem> 
              <NavItem><NavLink className={activeTab === 'succeeded' ? 'active' : ''} onClick={(e) => {
              changeTabCallback(e,'succeeded');
              }}>Success</NavLink>
              </NavItem>
              <NavItem ><NavLink className={activeTab === 'refunded' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e,'refunded');
              }}>Refunded</NavLink>
              </NavItem>
              <NavItem ><NavLink className={activeTab === 'inprocess' ? 'active' : ''} onClick={(e) => {
                 changeTabCallback(e,'inprocess');
              }}>InProcess</NavLink>
              </NavItem>
              <NavItem ><NavLink className={activeTab === 'failed' ? 'active' : ''} onClick={(e) => {
              changeTabCallback(e,'failed');
              }}>Failed</NavLink>
              </NavItem>
            </Nav>
            
           
          </div>
      </Col >
    </Fragment >
  );
};
export default TimelineTab;