import GlobalSearch from "../../Component/Profile360/profileGlobalSearch";
import Profile360 from "../../Component/Profile360";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import CheckContext from "../../_helper/customizer/index";
function CustomerProfile360() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { hideLinkedInProfiles, setHIdeLinkedInProfiles } =
    useContext(CheckContext);
  let profileFlag = params.get("linkedInUrl")
    ? atob(params.get("linkedInUrl"))
    : "";
  let isLinkedInSelectionSkip = params.get("lN_slt_skip")
  ? atob(params.get("lN_slt_skip"))
  : "";
  
  useEffect(() => {
    setHIdeLinkedInProfiles("KEY_DEFAULT");
  }, []);

  if (
    profileFlag ||
    hideLinkedInProfiles === "KEY_SKIP_LINKEDIN_PROFILE" ||
    hideLinkedInProfiles === "KEY_LI_API_FAIL"
  ) {
    return <Profile360 setHIdeLinkedInProfiles={setHIdeLinkedInProfiles} />;
  } else if (
    hideLinkedInProfiles === "KEY_SIMILAR_PROFILE_BTN" ||
    (hideLinkedInProfiles === "KEY_DEFAULT" && !isLinkedInSelectionSkip)
  ) {
    return <GlobalSearch setHIdeLinkedInProfiles={setHIdeLinkedInProfiles} />;
  } else {
    return <Profile360 setHIdeLinkedInProfiles={setHIdeLinkedInProfiles} />;
  }
}

export default CustomerProfile360;
