import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { LI, UL } from '../../AbstractElements';
import { MENUITEMS } from './Menu';
import { Label } from 'reactstrap';
import { Back } from '../../Constant';
import { useSelector } from 'react-redux';
import { navbarSelector } from '../../Auth/navbarSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckContext from "../../_helper/customizer/index";
import { Profile360Apis } from '../../Component/Profile360/js/api';
import { useDispatch } from 'react-redux';
import { profile360Selector } from '../../Component/Profile360/js/slice';
import { Profile360_icon } from '../../Component/Profile360/common/icons';
import Confirm from '../../Component/Profile360/common/confirm';

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  const params = useParams()
  const location = useLocation()
  const {setHIdeLinkedInProfiles } = useContext(CheckContext);
  const search = new URLSearchParams(location.search)
  const { profileData, navbarData, isLoading, status } = useSelector(navbarSelector);
  const detachLinkedInUrl = useSelector(profile360Selector.detachLinkedInUrl);
  const reservationDetails = useSelector(profile360Selector.reservationDetails);
  const profile = useSelector(profile360Selector.profile);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen,setIsOpen] = useState(false)
  const dispatch = useDispatch()
  
  // eslint-disable-next-line
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className =
        'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className =
        'sidebar-wrapper close_icon ';
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-wrapper').className =
          'sidebar-wrapper ';
      }
    }
    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    if (item.children) {
      item.active = !item.active;
    }
    setMainMenu({ mainmenu: MENUITEMS });
  };
  const checkMenuToDisplay = (menu) =>{
    if(menu.Items && menu.Items.length){
      let menuStatement = {display:false,disableAction:false}
      menu.Items.map((item)=>{
        var pagePermissionData = profileData.allowedPages.filter(o => o.path === item.path);
        
        if (pagePermissionData && pagePermissionData[0] && pagePermissionData[0].read === true) {
          if (item.path == "/profile-360") {
            if (location.pathname == item.path) {
              menuStatement.display = true
              menuStatement.disableAction = true
            } else {
              menuStatement.display = false
            }
          } else {
            menuStatement.display = true
          }
        }
      })
      return menuStatement
    }else{
      return {display:false,disableAction:false}
    }
  }

  const cbackForLinkedIn = () => {
    searchParams.set("linkedInUrl", "")
      setHIdeLinkedInProfiles("KEY_SIMILAR_PROFILE_BTN")
     setSearchParams(searchParams, {
      replace: true,
      preventScrollReset: true,
    })
  }

  return (
    <Fragment>
      <UL attrUL={{ className: 'custom-scrollbar simple-list sidebar-links mt-1', id: 'simple-bar' }}>
        <div className='simplebar-wrapper' style={{ margin: '0px' }}>
          <div className='simplebar-height-auto-observer-wrapper'>
            <div className='simplebar-height-auto-observer'></div>
          </div>
          <div className='simplebar-mask'>
            <div className='simplebar-offset' style={{ right: '0px', bottom: '0px' }}>
              <div className='simplebar-content-wrapper' style={{ height: '100%' }}>
                <div className='simplebar-content similar-profile-74t4trh3938y'>
                  <LI attrLI={{ className: 'back-btn' }}>
                    <div className="mobile-back text-end"><span>{Back}</span><i className="fa fa-angle-right ps-2"></i></div>
                  </LI>
                  {MENUITEMS.map((Item, i) => {
                    var menu = checkMenuToDisplay(Item);
                    if(menu.display){
                      return (
                        < Fragment key={i} >
                          {Item.Items.map((menuItem, i) => (
                            <LI attrLI={{ className: 'sidebar-list' }} key={i}>
                              {menuItem.type === 'sub' &&
                                <Fragment>
                                  {menuItem.badge && <Label className="badge badge-light-primary">2  </Label>}
                                  {menuItem.badge1 && <Label className="badge badge-light-info">New  </Label>}
                                  <a href="javascript"
                                    className={`sidebar-link sidebar-title ${menuItem.active || active ? ' active' : ''}`}
                                    onClick={(event) => {
                                      event.preventDefault(); setNavActive(menuItem);
                                    }} >
                                    {menuItem.icon !== undefined && <menuItem.icon />}
                                    <span>{t(menuItem.title)}</span>
                                    <div className="according-menu">
                                      {menuItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                </Fragment>
                              }
                              {menuItem.type === 'link' && (
                                <>
                                  {menuItem.badge2 && <Label className="badge badge-light-danger">Latest</Label>}
                                  <Link
                                    style={menu.disableAction?{pointerEvents:"none"}:{}}
                                    to={`${menuItem.path}`}
                                    className={`d-flex justify-content-center align-items-center sidebar-link sidebar-title ${"/" + window.location.pathname.split('/')[1] == menuItem.path ? 'active' : ''
                                      }`}
                                    onClick={() => toggletNavActive(menuItem)}
                                  >
                                    {/* {menuItem.icon !== undefined && <menuItem.icon className={`sidebar-icon ${menuItem.active ? 'active' : ''}`} />} */}
                                    {
                                      menuItem.faicon?<FontAwesomeIcon icon={menuItem.faicon} className={`sidebar-icon ${menuItem.active ? 'active' : ''}`}/>:null
                                    }
                                    <span>{t(menuItem.title)}</span>
                                  </Link>
                                </>
                              )}
                              {menuItem.children && (
                                <UL attrUL={{
                                  className: 'simple-list sidebar-submenu',
                                  style:
                                    menuItem.active
                                      ? sidebartoogle
                                        ? {
                                          opacity: 1,
                                          transition: 'opacity 500ms ease-in',
                                        }
                                        : { display: 'block' }
                                      : { display: 'none' }
                                }}>
                                  {menuItem.children.map((childrenItem, index) => {
                                    return (
                                      <LI key={index}>
                                        {childrenItem.type === 'sub' && (
                                          <a href="javascript" className={`${childrenItem.active ? 'active' : ''}`}
                                            onClick={(event) => {
                                              event.preventDefault();
                                              toggletNavActive(childrenItem);
                                            }}>
                                            {t(childrenItem.title)}
                                            <div className="according-menu">
                                              {childrenItem.active ? (<i className="fa fa-angle-down"></i>) : (<i className="fa fa-angle-right"></i>)} </div>
                                          </a>
                                        )}
                                        {childrenItem.type === 'link' && (
                                          <Link
                                            to={childrenItem.path} className={`${childrenItem.active ? 'active' : ''}`}
                                            onClick={() => toggletNavActive(childrenItem)} >
                                            {t(childrenItem.title)}
                                          </Link>
                                        )}
                                        {childrenItem.type === 'exteral_link' && (
                                          <>
                                            <a target="_blank" className={`${childrenItem.active ? 'active' : ''}`} href={childrenItem.path} onClick={() => toggletNavActive(childrenItem)} >
                                              {t(childrenItem.title)}
                                            </a>
                                          </>
                                        )}
                                        {childrenItem.children && (
                                          <UL attrUL={{
                                            className: 'simple-list nav-sub-childmenu submenu-content',
                                            style: childrenItem.active
                                              ? { display: 'block' }
                                              : { display: 'none' }
                                          }}>
                                            {childrenItem.children.map(
                                              (childrenSubItem, key) => (
                                                <LI key={key}>
                                                  {childrenSubItem.type === 'link' && (
                                                    <Link
                                                      to={childrenSubItem.path}
                                                      className={`${childrenSubItem.active ? 'active' : ''}`}
                                                      onClick={() => toggletNavActive(
                                                        childrenSubItem)}>
                                                      {t(childrenSubItem.title)}
                                                    </Link>
                                                  )}
                                                </LI>
                                              )
                                            )}
                                          </UL>
                                        )}
                                      </LI>
                                    );
                                  })}
                                </UL>
                              )}
                            </LI>
                          ))}
                        </Fragment>
                      )
                    }
                  })
                  }
                {search.get("profilePage") && !reservationDetails.loader && !profile.loader && 
                 <li className="sidebar-list list-group-item similar-profile-base">
                   <div
                        onClick={() => {
                          setIsOpen(true)
                        }}
                     className="sime9ju702g42s d-flex justify-content-center align-items-center sidebar-link sidebar-title "
                  >
                        <div className="vb63d9jt"></div>
                      
                        <div style={{display:"flex", flexDirection:"column",alignItems:"center"}} >
                        {detachLinkedInUrl.loader && <Profile360_icon.threeDotLoader color='black'/>}
                          <div style={{overflow:"hidden",height:detachLinkedInUrl.loader?"0px":"auto"}}>
                          Find Similar Profile
                         </div>
                        </div>
                      </div>
                </li>
                  } 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='simplebar-track simplebar-horizontal' style={{ visibility: 'hidden' }}>
          <div className='simplebar-scrollbar' style={{ width: '0px', display: 'none' }}></div>
        </div>
        <div className='simplebar-track simplebar-vertical' style={{ visibility: 'visible' }}>
          <div className='simplebar-scrollbar'></div>
        </div>
      </UL>
      <Confirm
        description='Are you sure you want to change the profile?'
        toggle={() => setIsOpen(false)}
        cback={() => {
          dispatch(Profile360Apis.detachLinkedInUrl(cbackForLinkedIn))
          setIsOpen(false)
        }}
        isOpen={isOpen} />
      
    </Fragment>
  );
};
export default SidebarMenuItems;
