console.log(process.env.REACT_APP_SERVER, "REACT_APP_SERVER");
const config = {
  // RESERVATION_API: 'http://localhost:8006/api',
  // HOSPITALITY_API: 'http://localhost:8001/api',
  // RESERVATION_API_HOST: 'http://localhost:8006',
  // HOSPITALITY_API_HOST: 'http://localhost:8001',

  RESERVATION_API: process.env.REACT_APP_SERVER == 'preproduction' ? 'https://preproduction-api.reservationapp.andmv.com/api' : process.env.REACT_APP_SERVER == 'production' ? 'https://api.reservationapp.andmv.com/api' : 'https://staging-api.reservationapp.andmv.com/api',
  HOSPITALITY_API: process.env.REACT_APP_SERVER == 'preproduction' ? 'https://preproduction-api.hospitality.andmv.com/api' : process.env.REACT_APP_SERVER == 'production' ? 'https://api.hospitality.andmv.com/api' : 'https://staging-api.hospitality.andmv.com/api',
  RESERVATION_API_HOST: process.env.REACT_APP_SERVER == 'preproduction' ? 'https://preproduction-api.reservationapp.andmv.com' : process.env.REACT_APP_SERVER == 'production' ? 'https://api.reservationapp.andmv.com' : 'https://staging-api.reservationapp.andmv.com',
  HOSPITALITY_API_HOST: process.env.REACT_APP_SERVER == 'preproduction' ? 'https://preproduction-api.hospitality.andmv.com' : process.env.REACT_APP_SERVER == 'production' ? 'https://api.hospitality.andmv.com' : 'https://staging-api.hospitality.andmv.com',
}

module.exports = {
  config
};