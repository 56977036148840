import * as Yup from "yup";
const newCard = Yup.object().shape({
  card: Yup.string()
    .test(
      "len",
      "Invalid card number",
      (val) => (val && val?.length === 16) || val?.length === 15
    )
    .required("Card number is required"),
  name: Yup.string().required("Name on card is required"),
  expiry_date: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Invalid date format")
    .required("MM/YY is required"),
  cvv: Yup.string()
    .required("CVV is required")
    .min(3, "CVV must be at least 3 digits")
    .max(4, "CVV can be at most 4 digits"),
});

const enroll = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});

const profileEmailandPhone = Yup.object().shape({
  email: Yup.string().email("Invalid email format").notRequired(),
  // phone: Yup.string()
  //   .matches(/^\d+$/, "Phone number must contain only digits")
  //   .notRequired(),
});

const keySetup = Yup.object().shape({
  idExpiryDate: Yup.string()
    .matches(
      /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
      "Invalid date format"
    )
    .required("Expiry date required"),
});

export const profile360Schema = {
  newCard,
  enroll,
  profileEmailandPhone,
  keySetup,
};
