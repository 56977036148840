import React from "react";
import { useSelector } from "react-redux";
import { profile360Selector } from "../../js/slice";
import CustomButton from "../../common/button";
import { Profile360Apis } from "../../js/api";
import { useDispatch } from "react-redux";

function SuggestionsServices() {
  const modal = useSelector(profile360Selector.modal);
  const saveHistory = useSelector(profile360Selector.saveHistory);
  const dispatch = useDispatch();
  const reservationDetails = useSelector(profile360Selector.reservationDetails);
  const handleClick = () => {
    if (reservationDetails.data.roomNo) {
      dispatch(Profile360Apis.saveHistory({ ...modal.data }));
    } else {
      alert("Room number is not assigned or provided");
    }
  };

  return (
    <div className="w-500px mt-10px room-upgrade">
      <div className="desc">{modal.data?.modalDesc}</div>
      <div className="type-code flex items-end mt-22px">
        <CustomButton
          loader={saveHistory.loader}
          disabled={saveHistory.loader}
          className="ml-auto"
          onClick={handleClick}
          label="COMPLETE"
        />
      </div>
    </div>
  );
}

export default SuggestionsServices;
