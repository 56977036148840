import axios from "axios";
import { config } from "../config";



export const AxiosInterceptor = axios.create({
  baseURL: config.HOSPITALITY_API,
  headers: { hotelId: config.HOTELID },
});

AxiosInterceptor.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers["authorization"] = token;
    }

    config.headers['hotelcode'] = localStorage.getItem("hotelcode") || "";
    config.headers['hotelId'] = localStorage.getItem("hotelId") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosInterceptor.interceptors.response.use((response) => response);

export const AxiosInterceptor2 = axios.create({
  baseURL: config.RESERVATION_API,
  headers: { hotelId: config.HOTELID },
});

AxiosInterceptor2.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // console.log("hotelCode:", hotelCode);
      config.headers["authorization"] = token;
      // config.headers["hotelcode"] = hotelCode;
    }

    config.headers['hotelcode'] = localStorage.getItem("hotelcode") || "";
    config.headers['hotelId'] = localStorage.getItem("hotelId") || "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosInterceptor2.interceptors.response.use((response) => response);