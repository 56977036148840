import React from "react";
import { Table } from "reactstrap";

const FolioDetails = ({ roomData, folio }) => {
  return (
    <div className="table-responsive">
      <Table bordered striped>
        <thead>
          <tr>
            <th>Items</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {folio.map((item, index) => (
            <tr key={index}>
              <td className="text-muted">{item.name}</td>
              <td>$ {Number(item.price).toFixed(2)}</td>
              <td className="text-muted">{item.qty}</td>
              <td className="font-weight-bold">
                $
                {Number(
                  item.qty > 0 ? item.qty * item.price : 1 * item.price
                ).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={{ borderTop: "1px solid #ccc" }}>
            <td colSpan="2">Total</td>
            <td className="text-muted">{roomData && roomData.folioQty}</td>
            <td className="font-weight-bold">
              ${Number(roomData && roomData.folioTotal).toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default FolioDetails;
