import React, { Fragment, useEffect, useState } from "react";
import ServicePageContain from "../../Component/Services";
import { Button } from "reactstrap";
import { testNotification } from "../../actions/axios-actions";

const ServicePage = () => {
  return (
    <Fragment>
      {/* <Button onClick={()=>{
        testNotification()
      }}>Check Notification</Button> */}
      <ServicePageContain />
    </Fragment>
  );
};
export default ServicePage;
