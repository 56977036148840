import React from "react";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function CustomSelect({
  name = "",
  options = [],
  handleSelect = () => {},
  onBlur = () => {},
  value = "",
  type = "",
}) {
  const checkTitle = (value) => {
    if (type == "light") {
      return value >= 65
        ? "High"
        : value >= 50
        ? "Medium"
        : value >= 10
        ? "Low"
        : "Off";
    }
    return <>{value}</>;
  };

  return (
    <Select
      name={name}
      value={value}
      onBlur={onBlur}
      renderValue={(selected) =>
        checkTitle(selected)
      }
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-root": {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        "&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
        "&.Mui-focused .MuiOutlinedInput-input": {
          boxShadow: "none"
        },
        ".MuiSelect-icon": {
          right: "0",
          fontSize: "30px",
          color: "black",
        },
        ".MuiSelect-select": {
          marginRight:"5px"
        }
      }}
      IconComponent={KeyboardArrowDownIcon}
    >
      {options.map((child, ndx) => {
        
        return (
          <MenuItem
            key={ndx}
            onClick={() => handleSelect(child,name)}
            value={type == "light" ? child : child?.key}
          >
            {checkTitle(type == "light" ? child : child?.key)}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default CustomSelect;
