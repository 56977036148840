import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import MobileUsersPageContain from '../../Component/MobileUsers';

const MobileUsersPage = () => {
  return (
    <Fragment>
      
      <MobileUsersPageContain />
    </Fragment>
  );
};
export default MobileUsersPage;