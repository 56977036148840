import { getRoomFolio } from "../../../../actions/axios-actions";
import FolioDetails from "../../../Folio/folioDetails";
import { H2, H5 } from "../../../../AbstractElements";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../../CommonElements/Loader";

function Folio() {
  const [roomData, setRoomData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roomNo, setRoomNo] = useState("");
  const [message, setMessage] = useState("");
  const [folio, setFolio] = useState([]);
  const { state } = useLocation();
  useEffect(() => {
    getRoomFolio(
      state?.roomNo,
      setLoading,
      setFolio,
      setRoomNo,
      setRoomData,
      setMessage,
    );
    return () => setMessage("");
  }, []);

  return (
    <>
      {loading ? (
        <Loader/>
      ) : message ? (
        <H5 attrH5={{ className: "text-center" }}>{message}</H5>
      ) : (
        <FolioDetails roomData={roomData} folio={folio} />
      )}
    </>
  );
}

export default Folio;
