import React, { Fragment, useEffect } from "react";
import { Profile360_icon } from "../common/icons";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../js/api";
import { useSelector } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { Skeleton } from "@mui/material";

const WeatherSkelton = () => {
  const Units = () => (
    <div className="flex flex-col items-center mt-2px">
      <Skeleton variant="circular" width={20} height={20} />
      <Skeleton variant="rounded" width={16} height={14} className="mt-2" />
      <Skeleton variant="rounded" width={18} height={7} className="mt-1" />
    </div>
  );

  const Title = () => (
    <Skeleton
      variant="rounded"
      width={78}
      height={9}
      className="mt-23px mb-1"
    />
  );

  return (
    <div
      style={{ width: "300px" }}
      className="flex flex-row  justify-center w-full gap-18px ml-auto mr-auto mt-1"
    >
      <div className=" flex flex-col items-center mr-auto">
        <div className="flex flex-row gap-18px w-120px justify-between">
          <Units />
          <Units />
          <Units />
        </div>
        <Title />
      </div>
      <div className=" flex flex-col items-center ml-auto">
        <div className="flex flex-row gap-18px w-120px justify-between">
          <Units />
          <Units />
          <Units />
        </div>
        <Title />
      </div>
    </div>
  );
};

function Weather(props) {
  const dispatch = useDispatch();
  const { data, loader } = useSelector(profile360Selector.weather);
  useEffect(() => {
    dispatch(Profile360Apis.getWeatherData(props));
  }, []);

  const Child = (e) => (
    <div>
      <img className="icon" src={e.icon} alt="" />
      <div className="temperature text-center">
        {typeof e.currentTempF == "number"
          ? Math.round(e.currentTempF)
          : e.currentTempF}
      </div>
      <div className="day text-center">{e.dayName.slice(0, 3)}</div>
    </div>
  );

  const DummyChild = () => {
    return (
      <div className="flex items-center" style={{ height: `62.8px` }}>
        <div
          style={{ fontSize: "21px" }}
          className="icon flex justify-center items-center"
        >
          -
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="flex flex-row mt-14px mb-17px weather gap-16px">
        {/* <Profile360_icon.tempWeather /> */}
        {loader ? (
          <WeatherSkelton />
        ) : (
          <>
            {data.guestWeatherData?.length > 0 && (
              <div className="flex flex-col mr-auto ml-69px">
                <div className="flex gap-16px">
                  {data.guestWeatherData.map((e, index) => (
                    <Fragment key={index}>
                      {e.dummy ? <DummyChild /> : <Child {...e} />}
                    </Fragment>
                  ))}
                </div>
                <div className="subTititle text-center mb-auto  mt-18px">
                  {data.guestCityName ? (
                    data.guestCityName
                  ) : (
                    <div style={{ fontSize: "24px" }}>-</div>
                  )}
                </div>
              </div>
            )}

            {data.hotelWeatherData?.length > 0 && (
              <div className="flex flex-col ml-auto mr-69px">
                <div className="flex gap-16px">
                  {data.hotelWeatherData.map((e, index) => (
                    <Fragment key={index}>
                      {e.dummy ? <DummyChild /> : <Child {...e} />}
                    </Fragment>
                  ))}
                </div>
                <div className="subTititle text-center mb-auto mt-18px">
                  {data.hotelCityName ? (
                    data.hotelCityName
                  ) : (
                    <div style={{ fontSize: "24px" }}>-</div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default Weather;
