import React, { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import Layout from "../Layout/Layout";
import { useSelector } from "react-redux";
import { navbarSelector } from "../Auth/navbarSlice";
import PageNotFound from "../Pages/404";

const LayoutRoutes = () => {
  const url = window.location.pathname;
  const parts = url.split("/");
  // const hotelCode = parts[1];
  const currentRoute = parts[1];
  // console.log("currentRoute:", currentRoute);

  const { profileData, navbarData, isLoading, status } = useSelector(navbarSelector); // Dont't remove this line
  // console.log('navbarData:', navbarData)

  useEffect(() => {
    handleCheck();
  }, []);

  const handleCheck = () => {
    // console.log("routes:", routes);

    let isRouteAvailable = routes.some((val) => {
      return val.path.split("/")[1] === currentRoute;
    });
    // console.log("isRouteAvailable:", isRouteAvailable);
    return isRouteAvailable;
  };

  const checkRoutePermission = (path) => {
    // console.log(path);
    // console.log(profileData.allowedPages);
    var pagePermissionData = profileData.allowedPages.filter(o => o.path == path);
    if(pagePermissionData && pagePermissionData[0] && pagePermissionData[0].read === true){
      return true
    }else{
      return false
    }
  }

  const renderRoute = ({path, Component, hideLayout, i }) => {
    if(hideLayout){
      return <Route path={path} element={Component} key={i}/>
    }else{
      return <Route element={<Layout />} key={i}>
        <Route path={path} element={Component} />
      </Route>
    }
  }

  return (
    <Fragment>
      { !navbarData?.success && !isLoading ? (
        <h3 style={{ height: "88vh", display: "grid", placeContent: "center" }}>
          {`Data not found.`}
        </h3>
      ) : profileData?(
        <Routes>
          {routes.map(({ path, Component, hideLayout, userRoutes }, i) =>
          {
            if(userRoutes){
              return renderRoute({ path, Component, hideLayout, userRoutes, i })
            }else{
              
              var pagePermission = checkRoutePermission(path);
              if(pagePermission){
                return renderRoute({ path, Component, hideLayout, userRoutes, i })
              }else{
                return (
                  <Route element={<Layout />} key={i}>
                    <Route path={path} element={<PageNotFound/>}  key={i}/>
                  </Route>
                )
              }
            }
            
          }
            
          )}
        </Routes>
      ):null}
    </Fragment>
  );
};

export default LayoutRoutes;
