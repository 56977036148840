export default {
  openModal: (state, { payload }) => {
    state.modal.isOpen = true;
    state.modal.isFor = payload.isFor || "";
    state.modal.title = payload.title || "";
    state.modal.data = payload.data || {};
  },
  closeModal: (state) => {
    state.modal.isOpen = false;
    state.modal.isFor = "";
    state.modal.title = "";
    state.modal.data = {};
  },
  setLoader: (state, { payload }) => {
    state.weather.loader = payload;
  },
  setHotelData: (state, { payload }) => {
    state.hotelData = payload;
  },
  preferenceInitialFormData: (state, { payload }) => {
    const objFormat = (e, isMusic = false) => {
      if (e !== null && typeof e == "object" && !Array.isArray(e)) {
        if (Object.keys(e)?.length > 0) {
          return { id: e.id, key: isMusic ? e.name : e.key };
        } else {
          return {};
        }
      }
      return {};
    };

    const arrayFormat = (e) => {
      if (Array.isArray(e)) {
        if (e.length > 0) {
          return e.map((child) => ({
            key: child.key,
            id: child.id,
          }));
        } else {
          return [];
        }
      }
      return [];
    };

    state.preferenceDetails.formData = {
      sleepQuality: arrayFormat(payload?.sleepQuality),
      roomSelection: objFormat(payload?.roomSelection),
      typeOfWine: objFormat(payload?.typeOfWine),
      pillowType: objFormat(payload?.pillowType),
      musicFile: objFormat(payload?.music, true),
      temperature: payload?.temperature,
      temperatureUnit: payload?.temperatureUnit,
      lightPercentage: payload?.lightPercentage || 50,
      lightColor: {
        r: payload?.lightColorRed || 0,
        g: payload?.lightColorGreen || 0,
        b: payload?.lightColorBlue || 0,
      },
      calmingMusic: objFormat(payload?.calmingMusic),
      whiteNoiseForBetterSleep: objFormat(payload?.whiteNoise),
    };
  },
  resetProfileData: (state) => {
    state.profile.data = {}
  }
};
