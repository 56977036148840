import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";
import Signin from "../Auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { fetchNavbar } from "../Auth/api";
import MenuComp from "../Layout/SideBar-Layout/Menu";
import RoutesComp from "./Routes";
import { navbarSelector } from "../Auth/navbarSlice";
import { io } from "socket.io-client";
import { config } from "../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Routers = () => {

  const [socket, setSocket] = useState(null);

  const Msg = ({ text }) => (
    <div className="d-flex">
      {text}
    </div>
  );
  
  useEffect(() => {

    function notify(data){
      toast(<Msg text={data.message} />, {
        position: "top-right",
        autoClose: 150000,
        hideProgressBar: false,
        progressClassName: "bg-white",
        progressStyle: { background: "#FFFFFF" },
        style: {
          background: "#FFF",
          fontSize: '16px',
          fontFamily: 'Azo Sans'
        },
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "bg-black text-white",
      });
      console.log('Notification data -- ',data);
      new Notification(data.title, {
        body: data?.message,
        // requireInteraction : true,
        tag:data.messageId,
        // renotify:true
      });
    }

    function notify1Minute(data){
      toast(<Msg text={data.message} />, {
        position: "top-right",
        autoClose: 60000,
        hideProgressBar: false,
        progressClassName: "bg-white",
        progressStyle: { background: "#FFFFFF" },
        style: {
          background: "#FFF",
          fontSize: '16px',
          fontFamily: 'Azo Sans'
        },  
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "bg-black text-white",
      });
      console.log('Notification data -- ',data);
      new Notification(data.title, {
        body: data?.message,
        // requireInteraction : true,
        tag:data.messageId,
        // renotify:true
      });
    }
  
    if (socket === null) {
      setSocket(io(config.RESERVATION_API_HOST));
    }
    if (socket) {
      socket.on("connect", (data) => {
        // socket.emit("joined", { serverchannel: 120 });
        console.log("Connected",data);
      });

      socket.on("service_request_notify", notify);

      socket.on("service_request_new_notify", notify1Minute);

      console.log(socket.id)
    }
    return () => {
      if(socket){
        console.log('offing socket...')
        socket.off("service_request_notify", notify);
        console.log('offed socket...')
      }
    };
  
  }, [socket]);

  const jwt_token = localStorage.getItem("token");
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation()
  const { isLoading, profileData } = useSelector(navbarSelector);
  useEffect(() => {
    if (jwt_token) {
      dispatch(fetchNavbar());
    }
  }, []);
 
  if(jwt_token && isLoading){
    return <Loader />
  }

  let hotelCode = localStorage.getItem("hotelcode");
  console.log("Routes/index.js >> hotelCode:", hotelCode);

  return (
    <Fragment>
      {/* <MenuComp /> */}
      {/* <RoutesComp /> */}

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {jwt_token && (
              <>
                <Route
                  path={"/"}
                  element={<Navigate to={`/services`} />}
                />
              </>
            )}
            <Route path={"/*"} element={<LayoutRoutes />} />
          </Route>
          <Route path={"/login"} element={!hotelCode ? <Navigate to={`/`} /> : <Signin />} />
        </Routes>
      </Suspense>
    </Fragment>
  );
};
export default Routers;
