import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { getAllCheckIn } from "../../actions/axios-actions";
import { useNavigate } from "react-router-dom";
import Loader from "../../CommonElements/Loader";
import DatePicker from "react-datepicker";
import { Btn } from "../../AbstractElements";
import { AllCheckInColumns } from "./AllCheckInColumns";
import { dateFormat } from "./index";

const AllCheckIn = (props) => {
  const navigate = useNavigate();
  let {
    checkInData,
    setCheckInData,
    loading,
    setLoading,
    myTotalRows,
    setMyTotalRows,
    myPerPage,
    setMyPerPage,
    myCurrentPage,
    setMyCurrentPage,
    searchText,
    setSearchText,
    startDate,
    setStartDate,
    endDate,
    setEndDate,


    dailyCheckInFltr, setDailyCheckInFltr

  } = props;

  useEffect(() => {
    setSearchText("");

    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,

      setMyTotalRows,
      myCurrentPage,
      myPerPage
    );
  }, []);

  const handlePageChange = (page) => {
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,

      setMyTotalRows,
      (myCurrentPage = page),
      myPerPage,

      dailyCheckInFltr

    );
    setMyCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {




    console.log("handle")
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,

      setMyTotalRows,
      (myCurrentPage = page),
      (myPerPage = newPerPage),
      dailyCheckInFltr
    );
    setMyPerPage(newPerPage);
  };

  // console.log("myTotalRows:", myTotalRows);
  // console.log("myCurrentPage:", myCurrentPage);

  // const paginationOptions = {
  //   rowsPerPageText: "Rows per page:",
  //   rangeSeparatorText: "of",
  //   selectAllRowsItem: true,
  //   selectAllRowsItemText: "All",
  // };

  return (
    <Fragment>
      <CardBody>
        <div className="order-history table-responsive">
          <DataTable
            columns={AllCheckInColumns}
            data={checkInData}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={myTotalRows}
            paginationDefaultPage={myCurrentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            searching={true}
            defaultSortField={"Check-in date"}
            paginationRowsPerPageOptions={[10,15,20,25,30, 50, 100]}
            defaultSortAsc={true}
            // paginationRowsPerPageOptions={[10, 15, 20]}
            // paginationComponentOptions={paginationOptions}
          />
        </div>
      </CardBody>
      <style jsx global>{`
        
        .kIaWtg {
          padding-top: 7px;
          padding-bottom: 7px;
        }
        .epNTxY {
          padding-top: 7px;
          padding-bottom: 7px;
          padding-left: 4px;
        }
        ..dTBAzQ {
          padding-left: 5px;
        }
        .order-history .rdt_Table .rdt_TableHead h6, .order-history .rdt_Table .rdt_TableHead .h6{
          text-align: center;  
        }
      `}</style>
    </Fragment>
  );
};
export default AllCheckIn;
