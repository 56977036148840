import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import FormPassword from "./FormPassword";
import { Btn, H5 } from "../AbstractElements";
import { EmailAddress, Password, LogIn } from "../Constant";
import { LoginFrontdesk } from "../actions/axios-actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchNavbar } from "./api";
import { navbarSelector } from "./navbarSlice";

// const url = window.location.pathname;
// const parts = url.split("/");
// const hotelCode = parts[1];
// console.log("hotelCode:", hotelCode);

const LoginForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  let jwt_token = localStorage.getItem("token");
  const loginWithJwt = (e) => {
    e.preventDefault();
    LoginFrontdesk(email, password, setLoading, setLoginError, handleCB);
  };
  const { profileData } = useSelector(navbarSelector);
  useEffect(() => {
    
    if(jwt_token){
      jwt_token && dispatch(fetchNavbar());
      if(profileData){
        jwt_token && navigate(`/services`);
      }
    }
  }, [profileData]);

  const handleCB = (res) => {
    const jwt_token = res.data?.result?.token;
    jwt_token && dispatch(fetchNavbar());
    jwt_token && navigate(`/services`);
  };

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type="email"
              required=""
              onChange={(e) => setEmail(e.target.value)}
              defaultValue={email}
            />
          </InputGroup>
          <div
            className="text-danger"
            style={{ height: "16.5px" }}
          >
            {/* {formik.errors && formik.errors[name]} */}
          </div>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-eye"></i>
            </InputGroupText>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              defaultValue={password}
              required=""
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
            <div
                className="text-danger"
                style={{ height: "16.5px" }}
              >
                {/* {formik.errors && formik.errors[name]} */}
              </div>
          </InputGroup>
        </FormGroup>
        {loginError != "" && <H5 attrH5={{className:"text-danger"}} >{loginError}</H5>}
        <FormPassword />
        <FormGroup>
          <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: loading ? loading : loading,
              onClick: (e) => loginWithJwt(e),
            }}
          >
            {loading ? "LOADING..." : LogIn}
          </Btn>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default LoginForm;
