import React, { useEffect, useState } from "react";
import "./HotelPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container } from "reactstrap";
import Loader from "../../Layout/Loader";
import { AxiosInterceptor2 } from "../../utils/axios-utils";
import { ArrowForwardIosTwoTone } from "@mui/icons-material";

const HotelPage = () => {
  const [hotels, setHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAllHotels();
  }, []);

  const getAllHotels = async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosInterceptor2.get(`getAllHotels`);
      console.log("data:", data);
      console.log("data?.result?.data:", data?.result?.data);

      setHotels(data?.result?.data);
      setIsLoading(false);
    } catch (err) {
      console.log("err:", err);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  return (
    <Container fluid className="mt-5">
      <div className="hotel-list">
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <>
            <h1>Select Hotel</h1>
            {hotels?.map((hotel, index) => (
              <div
                key={index}
                className="hotel-card pointer"
                onClick={() => (
                  localStorage.setItem("hotelId", hotel.hotelId),
                  localStorage.setItem("hotelcode", hotel.hotelCode),
                  navigate(`/login`)
                )}
              >
                <img
                  src={hotel.url + hotel.hotelImage[0].image[0]}
                  alt={hotel.hotelName}
                  className="hotel-image"
                />
                <div className="hotel-info">
                  <h3>{hotel.hotelName}</h3>
                  <span className="hotel-button">  <ArrowForwardIosTwoTone /></span>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export default HotelPage;
