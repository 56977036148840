import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import DetailPage from "../../Component/MobileAppReservations/detailpage";
function ReservationDetailPage() {
     return (
        <Fragment>
          <Breadcrumbs parent="Reservation Details" title="Reservation details" />
          <DetailPage/>
        </Fragment>
      );
}

export default ReservationDetailPage

