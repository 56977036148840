import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { Close, SaveChanges } from '../../../../Constant/index';

const CommonModal = (props) => {
  return (
    <>
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
      <div>
        <div className='fw-lighter h1 m-0' style={{fontSize:"21px"}}>{props.title}</div>
        {props.title2 && props.title2}
        </div>
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      {
        props.disabledButtombuttons ? null : <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >{Close}</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: props.toggler }}>{SaveChanges}</Btn>
        </ModalFooter>
      }
    </Modal>

    <style jsx >{`
      .modal-content{
        border-radius: 0 !important;
      }
    `}
    </style>
    </>
  );
};

export default CommonModal;