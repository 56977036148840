import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useFormik } from "formik";
import { PatternFormat } from "react-number-format";
import { profile360Schema } from "./validationSchema";
import { Profile360Apis } from "../js/api";
import { useSelector } from "react-redux";
import { Profile360_icon } from "../common/icons";

function AddNewCard({ email = "", reservationId = "" }) {
  const queryProps = { email, reservationId };
  const dispatch = useDispatch();
  const saveCards = useSelector(profile360Selector.saveCards);
  const handleClose = () => dispatch(profile360_actions.closeModal());
  const formik = useFormik({
    initialValues: {
      card: "",
      name: "",
      expiry_date: "",
      cvv: "",
    },
    onSubmit: (values) =>
      dispatch(Profile360Apis.saveCards({ values, queryProps })),
    validationSchema: profile360Schema.newCard,
  });

  const Error = ({ name, height = "0px" }) => {
    return name ? (
      formik.touched[name] && formik.errors[name] ? (
        <div
          style={{
            height,
            color: "#cc4949",
            fontSize: "12px",
            paddingTop: "1px",
          }}
        >
          {formik.errors[name]}
        </div>
      ) : (
        <div style={{ height }}></div>
      )
    ) : (
      <Fragment />
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className="new-card mt-25px w-394px">
      <div className="flex flex-col">
        <input
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Name on Card"
          className="w-full pl-13px"
          name="name"
        />
        <Error name="name" height="26px" />
        <PatternFormat
          value={formik.values.card}
          placeholder={"XXXX-XXXX-XXXX-XXXX"}
          format={"####-####-####-####"}
          onValueChange={(ev) => formik.setFieldValue("card", ev.value)}
          onBlur={formik.handleBlur}
          className="w-full pl-13px"
          name="card"
          type={"text"}
        />
        <Error name="card" height="26px" />
      </div>

      <div className="flex gap-18px">
        <div className="flex flex-col  w-full">
          <PatternFormat
            value={formik.values.expiry_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full pl-13px"
            placeholder="MM/YY"
            name="expiry_date"
            format={"##/##"}
            type={"text"}
          />
          <Error name="expiry_date" height="26px" />
        </div>

        <div className="flex flex-col w-full">
          <input
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target.value))
                formik.setFieldValue("cvv", e.target.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.cvv}
            className="w-full pl-13px"
            placeholder="CVV"
            name="cvv"
          />
          <Error name="cvv" height="26px" />
        </div>
      </div>

      <div className="flex justify-end gap-20px mt-27px">
        <button
          className="form-btn form-btn-white"
          onClick={handleClose}
          type="button"
        >
          Close
        </button>
        <button
          type={saveCards.loader ? "button" : "submit"}
          className="form-btn form-btn-magenta flex justify-center items-center"
        >
          {saveCards.loader ? <Profile360_icon.threeDotLoader /> : `Update`}
        </button>
      </div>
    </form>
  );
}

export default AddNewCard;
