import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { CardBody, CardHeader } from "reactstrap";
import { OrderHistoryColumns } from "../../Data/MobileAppReservations";
import { getMobileAppReservastions } from "../../actions/axios-actions";
import TimelineTab from "./TimelineTab";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../CommonElements/Loader";

const OrderTableData = (props) => {
  const navigate = useNavigate();
  let {
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    searchText,
    setSearchText,
    inforStatus
  } = props;

  useEffect(() => {
    setSearchText("");

    getMobileAppReservastions(setData, setLoading, setTotalRows, {
      searchText: "",
      page: 1,
      size: perPage,
      inforStatus : 'all'
    });
    return () => {
      setData({}); // This worked for me
    };
  }, []);

  const handlePageChange = (page) => {
    getMobileAppReservastions(setData, setLoading, setTotalRows, {
      page,
      size: perPage,
      searchText,
      inforStatus
    });
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    getMobileAppReservastions(setData, setLoading, setTotalRows, {
      page,
      size: newPerPage,
      searchText,
      inforStatus
    });
    setPerPage(newPerPage);
  };

  return (
    <Fragment>
      {/* <CardHeader className="pb-0">
        <TimelineTab
          handleSearch={handleSearch}
          changeSearchText={(e) => setSearchText(e.target.value)}
          searchText={searchText}
        />
      </CardHeader> */}
      <CardBody>
        <div className="order-history table-responsive">
          <DataTable
            columns={OrderHistoryColumns}
            data={data}
            progressPending={loading}
            progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            searching={true}
            paginationRowsPerPageOptions={[10,15,20,25,30, 50, 100]}
            pointerOnHover
            onRowClicked={(clickedItem) => {
              const { profile } = clickedItem;
              return navigate(
                `/profile-360?e=${btoa(clickedItem.emailId || "")}&i=${btoa(
                  clickedItem.reservationId || ""
                )}&linkedInUrl=${btoa(clickedItem?.linkedInUrl || ""
                )}&lN_slt_skip=${btoa(clickedItem.isLinkedInSelectionSkip || ""   
                )}`
              );
              navigate(`/mobile-app-reservations/${clickedItem?._id}`, {
                state: {
                  inforStatus: clickedItem.inforStatus[0],
                  roomNo: clickedItem.roomNo[0],
                  email: clickedItem.emailId,
                  hotelId: clickedItem.hotelId,
                  reservationId: clickedItem.reservationId[0],
                  currentReservation: {
                    arrivalDate: clickedItem.arrivalDate,
                    departureDate: clickedItem.departureDate,
                    checkinTime: clickedItem.checkInTime[0],
                    checkoutTime: clickedItem.checkOutTime[0],
                    reservationId: clickedItem.reservationId[0],
                    keyGenerated: clickedItem.isKeyGen,
                    status: clickedItem.inforStatus[0],
                    roomNo: clickedItem.roomNo[0],
                    isAddedReservation: clickedItem.isAddReservation,
                    createdBy: clickedItem.reservationCreatedId,
                    updatedBy: clickedItem.reservationLastModifierId,
                    specialRequest: clickedItem.specialRequest,
                  },
                  avatar: profile?.avatar ? profile?.avatar : "",
                  guestName: clickedItem.guestName,
                  // email: profile?.email ? profile?.email : "",
                  isExpressCheckInSetup: profile?.isExpressCheckInSetup
                    ? profile?.isExpressCheckInSetup
                    : false,
                },
              });
            }}
          />
        </div>
      </CardBody>
      <style jsx global>{`
        
      `}</style>
    </Fragment>
  );
};
export default OrderTableData;
