import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Btn } from '../../AbstractElements'
import { Close } from '../../Constant'

const AppSetupStatusModal = ({
  isOpen,
  title,
  toggle,
  data
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <ul>
          {
            data?.map((obj, index) => {
              return(
                <li className='text-black' key={index}>
                  {obj.text}
                  <span style={{ color: obj.status == 'Done' ? 'rgb(0, 205, 52)' : 'rgb(255, 168, 0)' }}>
                    {obj.status}
                  </span>
                </li>
              );
            })
          }
        </ul>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ className: "rounded-0", color: "dark", outline: true, onClick: toggle }} >{Close}</Btn>
      </ModalFooter>
    </Modal>
  );
}

export default AppSetupStatusModal;