import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Btn } from '../../AbstractElements'
import { Close } from '../../Constant'
import { createAccountForUser, enrollUserInShashiCash, getAllCheckIn } from '../../actions/axios-actions'

const DailyCheckInModal = ({
  email,
  setEmail,
  isOpen,
  title,
  toggle,
  setOpen,
  isSignUp,
  setCheckInData,
  setLoading,
  dateFormat,
  searchText,
  setMyTotalRows,
  myCurrentPage,
  myPerPage,
  startDate,
  endDate
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const enrollInShashiCash = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Provide a valid email address");
      return;
    } else setErrorMessage("");
    setOpen(false);
    
    let data = {};
    data.email = email;
    data.isLoyaltyApply = true;
    
    async function userRegistration() {
      let status;
      let statusMessage;
      data.OSType = "FD";
      await createAccountForUser(setLoading, data, (err, res) => {
        if(err) statusMessage = err.data?.result?.message;
        else {
          status = res.data?.success;
          statusMessage = res.data?.result?.message;
        }
      })

      if(status) {
        toast.success(
          statusMessage ?? "User registration successful",
          { position: toast.POSITION.TOP_RIGHT }
        );
      } else {
        toast.error(
          statusMessage ?? "User registration failed",
          { position: toast.POSITION.TOP_RIGHT }
        );
      }
      return status;
    }

    async function shashiCashEnrollment() {
      let status;
      let statusMessage;
      await enrollUserInShashiCash(setLoading, data, (err, res) => {
        if(err) statusMessage = err.data?.result?.message;
        else {
          status = res.data?.success;
          statusMessage = res.data?.result?.message;
        }
      })

      if(status) {
        toast.success(
          statusMessage ?? "Shashi Cash enrollment successful",
          { position: toast.POSITION.TOP_RIGHT }
        );
      } else {
        toast.error(
          statusMessage ?? "Shashi Cash enrollment failed",
          { position: toast.POSITION.TOP_RIGHT }
        );
      }
    }
    
    if(!isSignUp) {
      let userRegistered = await userRegistration();
      if(userRegistered) await shashiCashEnrollment();
    } else await shashiCashEnrollment();

    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      myCurrentPage,
      myPerPage
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="col-form-label text-black" for="recipient-name">{'Enter the Email ID of the customer: '}</Label>
          <Input
            className="form-control rounded-0"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='example@gmail.com'
            type="email"
          />
        </FormGroup>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ className: "rounded-0", color: "dark", outline: true, onClick: toggle }} >{Close}</Btn>
        <Btn attrBtn={{ className: "rounded-0 enrollBtn", color: 'primary', onClick: enrollInShashiCash }}>Enroll</Btn>
      </ModalFooter>
      <style jsx>
        {`
          .enrollBtn:hover {
            background-color: #ff0094 !important;
          }
        `}
      </style>
    </Modal>
  );
}

export default DailyCheckInModal;