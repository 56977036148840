import React, { useState } from "react";
import { Profile360_icon } from "../common/icons";
import "./index.scss";
import { useSelector } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useDebouncyEffect } from "use-debouncy";
import InsertCommentRoundedIcon from "@mui/icons-material/InsertCommentRounded";
import CustomToolTip from "../common/tooltip";
import { useDispatch } from "react-redux";

const Encloser = ({
  title = "",
  content = [],
  className = "",
  btnToolTip = "",
  objKey = "",
  waitforloader = false,
  disableBtn = false,
  singleContent = "",
}) => {
  const [collapse, setCollapse] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const dispatch = useDispatch();

  useDebouncyEffect(
    () => {
      if (disableBtn) return;
      setShowBtn(collapse);
    },
    700,
    [collapse]
  );

  return (
    <div className={`${className} encloser overflow-hidden relative`}>
      <div
        onClick={() => setCollapse((s) => !s)}
        className="absolute h-64px w-full cursor-pointer top-0 left-0"
      />
      <div className="flex justify-between">
        <span className="encloser-title pt-3px">{title}</span>

        <div className="flex flex-nowrap gap-8px">
          {showBtn && !waitforloader && (
            <CustomToolTip placement="left" tooltipChild={btnToolTip}>
              <div
                onClick={(e) => {
                  if (disableBtn) return;
                  dispatch(
                    profile360_actions.openModal({
                      isFor: "profileNotes-or-reportedIssues",
                      title,
                      data: { content, objKey },
                    })
                  );
                }}
                className="w-24px h-24px relative cursor-pointer"
              >
                <InsertCommentRoundedIcon className="animation-Icon-black absolute" />
              </div>
            </CustomToolTip>
          )}
          <Profile360_icon.arrow
            onClick={() => setCollapse((s) => !s)}
            rotate={collapse ? 0 : 180}
          />
        </div>
      </div>
      <div
        style={{
          transition: "0.8s",
        }}
        className={`encloser-description  w-full overflow-hidden ${
          collapse && `while-open mt-24px opacity-1`
        }`}
      >
        <ul className="list-parent">
          {content.length > 0 ? (
            content.map((notes, index) => {
              return (
                <li key={index} className="mt-12px">
                  {notes}
                </li>
              );
            })
          ) : singleContent ? (
            <li
              className="mt-12px"
              dangerouslySetInnerHTML={{
                __html: singleContent?.replace(/\n/g, "<br/>"),
              }}
            />
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );
};

function IssuesOrNotes() {
  const hotelEngagementLogs = useSelector(
    profile360Selector.hotelEngagementLogs
  );
  const reservationDetails = useSelector(profile360Selector.reservationDetails);
  return (
    <div className="">
      <Encloser
        className="bg-white mb-20px p-20px"
        title={"Reported Issues by User"}
        content={hotelEngagementLogs.data["reportedIssues"]}
        btnToolTip="Add Reported Issue"
        objKey={"reported_issue"}
        waitforloader={hotelEngagementLogs?.loader}
      />
      <Encloser
        disableBtn={true}
        className="bg-light-gay mb-20px p-20px"
        title={"Profile Notes"}
        singleContent={reservationDetails?.data["profileNotes"]}
        btnToolTip="Add Profile Note"
        objKey={"profile_note"}
        waitforloader={reservationDetails?.loader}
      />
    </div>
  );
}

export default IssuesOrNotes;
