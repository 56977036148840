import React, { Fragment, useEffect } from "react";
import "./index.scss";
import json from "../tempDetails.json";
import { Profile360_icon } from "../common/icons";
import { Profile360Apis } from "../js/api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { profile360Selector } from "../js/slice";
import Skeleton from "@mui/material/Skeleton";
function Preferences({ email, reservationId }) {
  const dispatch = useDispatch();
  const { data, loader } = useSelector(profile360Selector.preferenceDetails);
  const profileData = useSelector(profile360Selector.profile);

  useEffect(() => {
    dispatch(Profile360Apis.getUserPreference({ email, reservationId }));
  }, []);

  const Content = ({
    keepUnderLine = false,
    bulletPoint = false,
    enabled = false,
    array = false,
    color = false,
    label = "",
    value = "",
    skelton = {},
  }) => (
    <Fragment>
      {/* ${bulletPoint?} ${bulletPoint && color?`h-20px`:`h-15px`} */}
      <div
        className={` ${
          keepUnderLine ? `mb-20px` : bulletPoint ? `mb-17px` : `mb-25px `
        } flex justify-between ${
          color ? `h-20px` : bulletPoint ? `` : `h-13px`
        }`}
      >
        <div className="flex items-center">
          {bulletPoint && (
            <Fragment>
              <div className="mr-8px h-20px">
                {loader ? (
                  <Skeleton variant={"circular"} width={20} height={20} />
                ) : (
                  <>
                    {enabled ? (
                      <Profile360_icon.tick enabled={enabled} />
                    ) : (
                      <Profile360_icon.radio />
                    )}
                  </>
                )}
              </div>
            </Fragment>
          )}
          {loader ? (
            <Skeleton variant={"text"} width={skelton.a} height={20} />
          ) : (
            <div className={bulletPoint ? `label-15 mt-3px` : `label-14`}>
              {label}
            </div>
          )}
        </div>
        {loader ? (
          <>
            {color ? (
              <Skeleton variant={"circular"} width={20} height={20} />
            ) : (
              <Skeleton variant={"text"} width={skelton.b} height={20} />
            )}
          </>
        ) : (
          <>
            {color ? (
              value ? (
                <Profile360_icon.radio fill={value} />
              ) : (
                `-`
              )
            ) : (
              <div className="value">{value || `-`}</div>
            )}
          </>
        )}
      </div>
      {keepUnderLine && <div className="underline mb-20px" />}
    </Fragment>
  );

  const formatFileds = (props) => {
    if (!profileData.data.email) return props;
    if (props.key == "lightColor") {
      props.value = `rgb(${data["lightColorRed"]} ${data["lightColorGreen"]} ${data["lightColorBlue"]})`;
    } else if (props.key == "lightPercentage") {
      if (data.lightPercentage >= 65) {
        props.value = "High";
      } else if (data.lightPercentage >= 50) {
        props.value = "Medium";
      } else if (data.lightPercentage >= 10) {
        props.value = "Low";
      } else if (!data.lightPercentage) {
        props.value = "-";
      } else {
        props.value = "Off";
      }
    } else if (props.key == "temperature") {
      props.value = data.temperature ? `${data.temperature}°` : "-";
    } else if (props.key == "music") {
      props.value = data[props.key]?.genre || data[props.key]?.name;
    } else {
      if (props.bulletPoint) {
        if (
          data?.roomservicerequest?.status == "completed" &&
          props.key != "roomSelection" &&
          props.key != "typeOfWine"
        ) {
          props.enabled = true;
        } else {
          props.enabled = false;
        }
      }
      if (props.array == true) {
        let tempString = "";
        if (typeof data[props.key] == "object") {
          data[props.key]?.map((data) => {
            if (tempString == "") {
              tempString += data.key;
            } else {
              tempString += `, ${data.key}`;
            }
          });
        }
        props.value = tempString || "-";
      } else {
        props.value = data[props.key]?.key;
      }
    }
    return props;
  };

  const Wrapper = ({ propsData }) => {
    let tempData = { ...propsData };
      if (profileData.data.email) {
        if (tempData.key == "lightColor") {
          tempData.value = `rgb(${data["lightColorRed"]} ${data["lightColorGreen"]} ${data["lightColorBlue"]})`;
        } else if (tempData.key == "lightPercentage") {
          if (data.lightPercentage >= 65) {
            tempData.value = "High";
          } else if (data.lightPercentage >= 50) {
            tempData.value = "Medium";
          } else if (data.lightPercentage >= 10) {
            tempData.value = "Low";
          } else if (!data.lightPercentage) {
            tempData.value = "-";
          } else {
            tempData.value = "Off";
          }
        } else if (tempData.key == "temperature") {
          tempData.value = data.temperature ? `${data.temperature}°` : "-";
        } else if (tempData.key == "music") {
          tempData.value = data[tempData.key]?.genre || data[tempData.key]?.name;
        } else {
          if (tempData.bulletPoint) {
            if (
              data?.roomservicerequest?.status == "completed" &&
              tempData.key != "roomSelection" &&
              tempData.key != "typeOfWine"
            ) {
              tempData.enabled = true;
            } else {
              tempData.enabled = false;
            }
          }
          if (tempData.array == true) {
            let tempString = "";
            if (typeof data[tempData.key] == "object") {
              data[tempData.key]?.map((data) => {
                if (tempString == "") {
                  tempString += data.key;
                } else {
                  tempString += `, ${data.key}`;
                }
              });
            }
            tempData.value = tempString || "-";
          } else {
            tempData.value = data[tempData.key]?.key;
          }
        }
      }

    return <Content {...tempData} />;
  };

  return (
    <section className="preferences">
      <div className="title mb-20px">Preferences</div>
      <div className="grid gird-col-2">
        {json.Preferences.map((data, index) => {
          return (
            <Fragment key={index}>
              <Wrapper propsData={data} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
}

export default Preferences;
