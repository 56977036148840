import React from "react";
import { Image } from "react-bootstrap";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import LoginForm from "./LoginForm";
import shashiLogo from "../assets/images/logo/Shashi Logo.svg";
import nestLogo from "../assets/images/logo/Nest Logo.svg";
import { Btn } from "../AbstractElements";
import { useNavigate } from "react-router-dom";

const Logins = () => {
  const navigate = useNavigate();
  let hotelCode = localStorage.getItem("hotelcode");
  console.log("Login.jsx >> hotelCode:", hotelCode);


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Btn
          attrBtn={{
            color: "primary",
            className: "btn-block p-3",
            // disabled: loading ? loading : loading,
            onClick: (e) => (
              localStorage.removeItem("hotelcode"),
              localStorage.removeItem("hotelId"),
              setTimeout(() => {
                navigate("/");
              }, 1111)
            ),
          }}
        >
          {"Change Hotel"}
        </Btn>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main1">
                <TabContent className="content-login">
                  <TabPane className="fade show d-flex d-flex flex-column align-items-center mb-3">
                    <Image
                      className="login-logo"
                      src={hotelCode == "tnpa" ? nestLogo : shashiLogo}
                      alt={hotelCode == "tnpa" ? "Nest-Logo" : "Shashi-Logo"}
                    />
                  </TabPane>
                  <TabPane className="fade show">
                    <LoginForm />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
