import React, { useContext, useState } from 'react';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/customizer/index';
import shashiLogo from '../../assets/images/logo/Shashi Logo.png';
import nestLogo from '../../assets/images/logo/Nest Logo.svg';

const SidebarIcon = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  let hotelCode = localStorage.getItem("hotelcode");
  console.log("SidebarIcon.jsx >> hotelCode:", hotelCode);

  return (
    <div className="logo-wrapper">
      <a href="">
        {/* <Image attrImage={{ className: 'img-fluid for-light', src: `${logo}`, alt: '' }} /> */}
        {/* <Image attrImage={{ className: 'img-fluid for-dark', src: `${logo1}`, alt: '' }} /> */}
        <Image attrImage={{ className: 'img-fluid for-light', src: `${hotelCode == "tnpa" ? nestLogo : shashiLogo}`, alt: hotelCode == "tnpa" ? "Nest-Logo" : "Shashi-Logo" }} />
        {/* <Image attrImage={{ className: 'img-fluid for-dark', src: `${imgg}`, alt: 'Shashi Group Logo' }} /> */}
        
      </a>
      <div className='back-btn' onClick={() => openCloseSidebar()}><i className='fa fa-angle-left'></i></div>
    </div>
  );
};
export default SidebarIcon;