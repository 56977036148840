import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import DisclosureContent from "../DisclosureContent";
import { useSelector } from "react-redux";
import { profile360Selector } from "../../js/slice";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../../js/api";

const HistorySkelton = () => {
  return (
    <div className="mt-24px">
      {[1, 1, 1, 1].map((data, index) => (
        <Skeleton
          key={index}
          className="mb-16px"
          variant="rounded"
          width={"100%"}
          height={45}
        />
      ))}
    </div>
  );
};

function PurposefulHistory() {
  const findHistory = useSelector(profile360Selector.findHistory);
  const dispatch = useDispatch();
  useEffect(() => dispatch(Profile360Apis.findHistory()), []);

  return (
    <div style={{maxHeight:"318px",overflowY:"scroll",scrollbarWidth:"3px"}} className="w-394px purposeful-history mt-16px">
      {findHistory.loader ? (
        <HistorySkelton />
      ) : Array.isArray(findHistory.data) && findHistory.data.length > 0 ? (
        findHistory.data.map((e) => (
          <DisclosureContent
            removeBtn={true}
            data={e.history}
          />
        ))
      ) : (
        <div className="text-center">History not found</div>
      )}
    </div>
  );
}

export default PurposefulHistory;
