import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const CustomToolTip = ({placement="bottom", tooltipChild = "", ...props }) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip >{tooltipChild}</Tooltip>}
  >
    {props?.children}
  </OverlayTrigger>
);

export default CustomToolTip;
