import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { NavItem } from "react-bootstrap";
import { Key } from "react-feather";
import { useLocation } from "react-router-dom";
import { Button, Nav } from "reactstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { reGenerateRoomKey } from "../../../../actions/axios-actions";
import { ThreeDots } from  'react-loader-spinner'
function KeyGen() {
  const [loading2, setLoading2] = useState(false);
  const [keyGenSuccessMessage, setKeyGenSuccessMessage] = useState("");
  const [keyGenErrorMessage, setKeyGenErrorMessage] = useState("");
  const { state } = useLocation();
  useEffect(() => {
    keyGenSuccessMessage.length &&
      Swal.fire({
        icon: "success",
        text: keyGenSuccessMessage,
      }).then(() => {
        setKeyGenSuccessMessage("");
      });

    keyGenErrorMessage.length &&
      Swal.fire({
        icon: "error",
        text: keyGenErrorMessage,
      }).then(() => {
        setKeyGenErrorMessage("");
      });
  }, [keyGenErrorMessage, keyGenSuccessMessage]);
  const generateRoomKey = () => {
    if (state.reservationId) {
      reGenerateRoomKey(
        setLoading2,
        state,
        setKeyGenSuccessMessage,
        setKeyGenErrorMessage
      );
    } else {
      console.log("reservation Id is not found ");
    }
  };
  return (
    <Fragment>
      <button
        onClick={generateRoomKey}
        className=" btn custom-btn-clr d-flex align-items-center justify-content-between"
      >
        {loading2 ? (
          <ThreeDots
            height="24"
            width="40"
            radius="9"
            color="#ffff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <>
            <span>Regenerate Key</span>&nbsp;&nbsp;&nbsp;{" "}
            <Key className="font-white font-sm" />
          </>
        )}
      </button>
    </Fragment>
  );
}

export default KeyGen;
