import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Profile360_icon } from "../common/icons";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../js/api";
import { useSelector } from "react-redux";
import { profile360_actions, profile360Selector } from "../js/slice";
import { Skeleton } from "@mui/material";
import CustomToolTip from "../common/tooltip";
import { useSearchParams } from "react-router-dom";

function Profile(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const profile = useSelector(profile360Selector.profile);
  const reservationDetails = useSelector(profile360Selector.reservationDetails);
  useEffect(() => {
    dispatch(Profile360Apis.getProfileData(props));
    return () => {
      dispatch(profile360_actions.resetProfileData())
    }
  }, []);

  useEffect(() => {
    if (
      profile.data?.linkedInProfileData?.profileURL &&
      !searchParams.get("linkedInUrl")
    ) {
      searchParams.set(
        "linkedInUrl",
        btoa(profile.data?.linkedInProfileData?.profileURL)
      );
      setSearchParams(searchParams, {
        replace: true,
        preventScrollReset: true,
      });
    }
  }, [profile.data]);

  let CustomSkelton = {};

  const lnData = useMemo(() => {
    if (Object.keys(profile.data?.linkedInProfileData || {}).length > 0) {
      let prflDta = profile.data?.linkedInProfileData || {};

      return {
        fullName: `${prflDta?.firstName || ""}${
          prflDta?.lastName ? ` ${prflDta?.lastName}` : ""
        }`,
        companyLogo: prflDta?.company?.companyLogo || "",
        companyName: prflDta?.company?.companyName || "",
        title: prflDta?.company?.title || "",
        location: prflDta?.company?.location || "",
        profilePicture: prflDta?.profilePicture || "",
      };
    }
    return {};
  }, [profile.data]);

  CustomSkelton.name = () => (
    <Skeleton
      variant="rounded"
      className="title-1 ml-auto mr-auto"
      width={125}
      height={28.2}
    />
  );
  CustomSkelton.google = () => (
    <div className="flex  gap-4px ml-auto mr-auto items-center w-fit mt-8px">
      <Skeleton variant="circular" width={26} height={26} />
      <Skeleton variant="rounded" width={73} height={21} />
    </div>
  );

  CustomSkelton.desc = () => (
    <div className="h-56px flex flex-col justify-between mt-9px ml-auto mr-auto w-fit items-center">
      <Skeleton variant="rounded" width={320} height={12} className="mt-8px" />
      <Skeleton variant="rounded" width={290} height={12} className="mb-8px" />
    </div>
  );

  const RoundIndic = ({ title = "", desc = "" }) => (
    <div className="round-indic flex items-center justify-center flex-col">
      {profile.loader ? (
        <Fragment>
          <Skeleton variant="rounded" width={23} height={12} />
          <Skeleton
            variant="rounded"
            className="mt-8px"
            width={25}
            height={6}
          />
        </Fragment>
      ) : (
        <Fragment>
          <CustomToolTip placement="top" tooltipChild={title}>
            <div style={{ textOverflow: "ellipsis" }} className="title-2">
              {title}
            </div>
          </CustomToolTip>
          <div className="dec">{desc}</div>
        </Fragment>
      )}
    </div>
  );

  return (
    <div className="profile">
      <div className="relative flex justify-center pb-5px">
        {profile.loader ? (
          <img className="img" src={`/profile_360.png`} alt="" />
        ) : (
          <img
            className="img"
            src={
              lnData?.profilePicture ||
              profile?.data?.avatar ||
              `/profile_360.png`
            }
            alt=""
          />
        )}

        <div className="absolute bottom-0 flex justify-between w-full">
          <RoundIndic
            title={`$${Math.round(
              JSON.parse(profile?.data?.currentYearLoyaltyAmount || 0)
            )}`}
            desc="Shashi Cash"
          />
          {profile?.data?.vipLevel && (
            <div className="vip flex items-center justify-center">
              {profile?.data?.vipLevel}
            </div>
          )}
          <RoundIndic
            title={profile?.data?.currentYearTotalNumberOfNights || 0}
            desc="Nights"
          />
        </div>
      </div>

      {profile?.loader ? (
        <CustomSkelton.name />
      ) : (
        <>
          <div className="title-1">
            {lnData?.fullName ? (
              lnData?.fullName
            ) : (
              <>
                {profile?.data?.fullName ||
                  (reservationDetails.data?.firstName ||
                  reservationDetails.data?.lastName
                    ? `${reservationDetails.data?.firstName || ""} ${
                        reservationDetails.data?.lastName || ""
                      }`
                    : "-")}
              </>
            )}
          </div>
        </>
      )}

      {profile?.loader ? (
        <CustomSkelton.google />
      ) : (
        <div className="google flex items-center justify-center">
          {lnData?.companyLogo && (
            <img
              className="w-30px h-30px radius-full"
              src={lnData?.companyLogo}
              alt="company logo"
            />
          )}
          {lnData?.companyName ? (
            <div className="ml-8px">{lnData?.companyName}</div>
          ) : (
            `-`
          )}
        </div>
      )}

      {profile?.loader ? (
        <CustomSkelton.desc />
      ) : (
        <div className="location text-center pl-30px pr-30px mt-10px">
          {lnData?.title || lnData?.location || lnData?.companyName ? (
            <>
              {lnData?.title && lnData?.companyName ? (
                <>
                  {lnData?.title} at {lnData?.companyName}
                  <br />
                </>
              ) : (
                lnData?.title && (
                  <>
                    {lnData?.title}
                    <br />
                  </>
                )
              )}

              {lnData?.location}
            </>
          ) : (
            profile?.data?.addressLine1 || `-`
          )}
        </div>
      )}
    </div>
  );
}

export default Profile;
