import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Profile360_icon } from "../../common/icons";
import { useDebouncyEffect } from "use-debouncy";
import { useState } from "react";

const Temperature = ({
  name = "",
  value = "",
  formik,
  visible,
  setVisible,
}) => {
  const [hidePop, setHidePop] = useState(visible);
  const min = 67;
  useDebouncyEffect(
    () => {
      setHidePop(visible);
    },
    200,
    [visible]
  );
  console.log(!(value <= min), "value <= min");
  const handleCount = (e) => {
    switch (e) {
      case "minus":
        if (!(value <= min)) {
          formik.setFieldValue(name, value - 1);
        }
        break;
      case "plus":
        formik.setFieldValue(name, value + 1);
        break;
    }
  };

  return (
    <div className="flex flex-nowrap items-center relative">
      <div style={{ fontSize: "16px" }} className="mr-5px">
        {value}°
      </div>
      {hidePop == name && (
        <div className={`absolute temperature-input ${visible == name?"anim-to-dark":"anim-to-light"}`}>
          <div className="flex justify-end w-full p-3px">
            <Profile360_icon.modalCross
              onClick={() => setVisible("")}
              fill="#868686"
            />
          </div>
          <div className="ttle mt-5px">Temperature</div>
          <div className="flex flex-nowrap justify-between items-center mt-10px">
            <Profile360_icon.minus
              className={"btn-txt ml-20px"}
              fill="white"
              onClick={() => handleCount("minus")}
              disable={value <= min}
            />
            <div className="btn-txt">{value}°F</div>
            <Profile360_icon.plus
              className={"btn-txt mr-20px"}
              fill="white"
              onClick={() => handleCount("plus")}
            />
          </div>
        </div>
      )}
      <KeyboardArrowDownIcon
        style={{
          rotate: visible == name ? "180deg" : "0deg",
          fontSize: "30px",
        }}
        className="cursor-pointer"
        onClick={() => setVisible((pref) => (pref == name ? "" : name))}
      />
    </div>
  );
};
export default Temperature;
