import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import FolioPageContain from '../../Component/Folio';

const FolioPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Folio" />
      <FolioPageContain />
    </Fragment>
  );
};
export default FolioPage;