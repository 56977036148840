import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Btn } from "../../../AbstractElements";

function Confirm({
  isOpen,
  toggle = () => {},
  description = "",
  cback = () => {},
}) {
  return (
    <Modal className="csm-confirm" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader style={{borderBottom:"none"}} toggle={toggle}>{description}</ModalHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          padding: "15px",
        }}
      >
        <Btn
          attrBtn={{
            className: "rounded-0 ddrefdd",
            color: "dark",
            outline: true,
            onClick: toggle,
          }}
        >
          <div className="mt-2px leading-17px">No</div>
        </Btn>
        <Btn
          attrBtn={{
            className: "rounded-0 enrollBtn",
            color: "primary",
            onClick: cback,
          }}
        >
          <div className="mt-2px leading-17px">Yes</div>
        </Btn>
      </div>
      <style jsx>
        {`
          .csm-confirm .enrollBtn {
            background-color: #ff0094 !important;
          }
            
            .csm-confirm .modal-title{
            font-size:16px;
            }
            .mt-2px{
            margin-top:3px;
            }
            .leading-17px{
            line-height:17px;
            }
        `}
      </style>
    </Modal>
  );
}

export default Confirm;
