import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { profile360Selector, profile360_actions } from "../js/slice";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CustomButton from "../common/button";
import { Profile360Apis } from "../js/api";
import { useDispatch } from "react-redux";
import { Profile360_icon } from "../common/icons";

function IssueOrNoteForm() {
  const dispatch = useDispatch();
  const modal = useSelector(profile360Selector.modal);
  const saveHotelEngagementLogs = useSelector(
    profile360Selector.saveHotelEngagementLogs
  );

  const [editableData, setEditableData] = useState(modal?.data?.content || []);
  const [newNote, setNewNote] = useState("");

  const handleEdit = (value, index) => {
    setEditableData((prevData) =>
      prevData.map((item, i) => (i === index ? value : item))
    );
  };

  const handleDelete = (index) => {
    setEditableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleCreate = async () => {
    let trim = newNote.replace(/\s+/g, " ").trim();
    if (trim === "") return;
    setEditableData((prevData) => prevData.concat(trim));
    setNewNote("");
  };

  const handleSubmit = () => {
    let trimNote = newNote.replace(/\s+/g, " ").trim();
    let value = {
      type: modal.data?.objKey || "",
      note: trimNote !== "" ? [...editableData, trimNote] : editableData,
    };

    dispatch(Profile360Apis.saveHotelEngagementLogs(value));
  };

  const handleClose = () => dispatch(profile360_actions.closeModal());

  return (
    <div className="w-500px mt-30px">
      <div
        className="w-full"
        style={{ maxHeight: "520px", overflowY: "scroll" }}
      >
        {Array.isArray(modal?.data?.content) &&
          editableData?.map((e, index) => {
            return (
              <div
                key={index}
                className="flex flex-nowrap items-center gap-8px mt-10px"
              >
                <TextareaAutosize
                  onChange={({ target }) => handleEdit(target.value, index)}
                  className="w-full p-2px profileNote-text"
                  minRows={2}
                  placeholder="..."
                  style={{ resize: "none", padding: "5px" }}
                  value={e}
                />
                <DeleteRoundedIcon
                  className="animation-Icon-black cursor-pointer"
                  onClick={() => handleDelete(index)}
                />
              </div>
            );
          })}
      </div>
      <div className="flex flex-nowrap items-center gap-8px mt-10px">
        <TextareaAutosize
          onChange={({ target }) => setNewNote(target.value)}
          className="w-full  p-2px"
          value={newNote}
          minRows={4}
          placeholder="..."
          style={{ resize: "none", padding: "5px" }}
        />
        <AddBoxIcon
          className="animation-Icon-black cursor-pointer profileNote-text"
          onClick={() => handleCreate()}
        />
      </div>
      <div className="flex justify-end gap-20px mt-27px">
          <button
            onClick={handleClose}
            type="button"
            className="form-btn form-btn-white"
          >
            Close
          </button>
        <button
          onClick={handleSubmit}
            type="button"
            className="form-btn form-btn-magenta flex items-center justify-center"
          >
            {saveHotelEngagementLogs?.loader ? (
              <Profile360_icon.threeDotLoader />
            ) : (
              "Update"
            )}
          </button>
        </div>
    </div>
  );
}

export default IssueOrNoteForm;
