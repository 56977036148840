import React, { Fragment } from "react";
import { Card, Col, Container, Input, InputGroup, Nav } from "reactstrap";

const TimelineTab = ({
  handleSearch,
  changeSearchText,
  searchText,
  children,
}) => {
  return (
    <div style={{ float: "right", padding: "0px" }} className="social-tab">
      {children}
      <form onSubmit={(e) => handleSearch(e)}>
        <InputGroup>
          <Input
            className="form-control"
            type="text"
            onChange={changeSearchText}
            value={searchText}
            placeholder="Search......."
          />
          <span className="input-group-text cursor_pointer">
            <i className="fa fa-search" onClick={(e) => handleSearch(e)}></i>
          </span>
        </InputGroup>
      </form>
    </div>
  );
};
export default TimelineTab;
