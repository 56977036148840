export const TickIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#00CD34"/>
            <path d="M10.7895 16.0526L7.63159 12.9171L8.73686 11.8197L10.7895 13.8578L16 8.6842L17.1053 9.78163L10.7895 16.0526Z" fill="white"/>
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#FF170A"/>
            <path d="M15.4388 9.29807L9.2981 15.4387M15.4388 15.4387L9.2981 9.29803" stroke="white" stroke-width="1.33188" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export const EmptyRoundIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.25" fill="white" stroke="black" stroke-width="0.5"/>
        </svg>
    )
}

export const MinusCircleIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#FFA800"/>
            <line x1="7" y1="12" x2="18" y2="12" stroke="white" stroke-width="2"/>
        </svg>
    )
}