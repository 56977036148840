import { faCheck, faCheckCircle, faCircleNotch, faCog, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import { Controller, useForm } from "react-hook-form";
import { getUserById, updateuserProfile } from '../../../actions/axios-actions';
import moment from 'moment';
import Loader from "../../../CommonElements/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";


export default function Form(props) {
    var { modal, toggle, modalData, onCallBack } = props;
    const [gettingData, setGettingData] = useState(false);
    const [expressCheckin, setexpressCheckin] = useState(false);
    const [idExpiryDate, setIdExpiryDate] = useState(false);
    const [openCalender, setOpenCalender] = useState(false);
    const [userProfile, setuserProfile] = useState(false);
    const [dataUpdating, setDataUpdating] = useState(false);
    
    const [error, seterror] = useState(false);
    const [message, setMessage] = useState(false);
    

    useEffect(() => {
        if(userProfile?.idExpiryDate){
            setIdExpiryDate(moment(userProfile.idExpiryDate).toDate())
        }
        
    }, [userProfile]);

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
        // mode: "onBlur",
        mode:"onChange",
    });

    // const { state } = useLocation();

    useEffect(() => {
        setGettingData(true)
        if(modalData && modalData._id){
            getUserById(modalData._id,setuserProfile,function(err,res){
                setGettingData(false)
                setValue("isExpressCheckInSetup",res.isExpressCheckInSetup);
                setValue("idExpiryDate",res.idExpiryDate || "");
            })
        }
        return {}
    }, [modalData, setValue]);

    const onSubmit = (data) => {
        console.log(errors)
        data.email = userProfile.email
        setMessage(false)
        seterror(false);
        data.isIdVerified = true;

        if(!data.isExpressCheckInSetup){
            seterror('Please enable Express Check-In.')
        }else if(!data.idExpiryDate){
            seterror('Please add Expiry date.')
        }else{
            seterror(false);
            setDataUpdating(true);
            updateuserProfile(data,function(err,res){
                getUserById(modalData._id,setuserProfile,function(err,res){
                    if(onCallBack){
                        onCallBack()
                    }
                    // toggle();
                    setDataUpdating(false)
                })
                console.log(err,res)
                if(res && res.error){
                    seterror('Something went wrong. Please try again later.')
                }else if(res && res.success){
                    setMessage(res.result.message)
                }
            })
        }
    }
    console.log('idExpiryDateidExpiryDate', errors)
    return (
        <Modal centered={true} isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Update Details</ModalHeader>
            
                {
                    userProfile && (!userProfile.emailVerified || !userProfile.primaryEmailVerified || !userProfile.isCardAdded || !userProfile.isUserProfileSetup || !userProfile.isLoyaltyApply)?
                    <ModalBody>
                    <div className='text-danger h6'>
                        {!userProfile.emailVerified || !userProfile.primaryEmailVerified?<p className='mb-2 fs-15'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>Please verify the email.</p>:null}
                        {!userProfile.isCardAdded?<p className='mb-2 fs-15'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>Please add card.</p>:null}
                        {!userProfile.isUserProfileSetup?<p className='mb-2 fs-15'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>Please complete profile setup.</p>:null}
                        {!userProfile.isLoyaltyApply?<p className='mb-2 fs-15'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>Please apply for Shashi Cash.</p>:null}
                    </div>
                    </ModalBody>
                    :<ModalBody>
                        <div>
                            {
                            gettingData?<Loader/>:
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg={12}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className="text-label fw-bold">Email</label>
                                        <label className="text-title">
                                            {userProfile.email}
                                        </label>
                                    </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className="text-label fw-bold mb-0">Enable Express Check-In</label>
                                            
                                                {
                                                    userProfile && userProfile.isExpressCheckInSetup?
                                                        <div className='current-reservation'>
                                                            <label className="status color-green">Enabled</label>
                                                        </div>
                                                        :
                                                        
                                                    <div className={expressCheckin?"checkbox checkbox-solid-primary":"checkbox checkbox-primary"}>
                                                        <input 
                                                            id="solid4"
                                                            type="checkbox" 
                                                            className="form-check-input"
                                                            name="isExpressCheckInSetup"    
                                                            {...register("isExpressCheckInSetup")}
                                                            onChange={(e) => {
                                                                setValue("isExpressCheckInSetup", e.target.checked ? true : false);
                                                                if(e.target.checked == true){
                                                                    seterror(false)
                                                                }else{
                                                                    seterror('Please enable Express Check-In.')
                                                                }
                                                                setexpressCheckin(!expressCheckin);
                                                            }}
                                                            value={expressCheckin?expressCheckin:""}
                                                            checked={expressCheckin}
                                                        />
                                                        <label htmlFor="solid4" className="">
                                                            &nbsp;
                                                        </label>
                                                        {/* <Input 
                                                            name="isExpressCheckInSetup" 
                                                            {...register("isExpressCheckInSetup")} 
                                                            id="checkbox1" 
                                                            type="checkbox" 
                                                            onChange={(e) => {
                                                                setValue("isExpressCheckInSetup", e.target.checked ? true : false);
                                                                setexpressCheckin(!expressCheckin);
                                                            }}
                                                            checked={expressCheckin}
                                                        /> */}
                                                        {/* <Label for="checkbox1" ></Label> */}
                                                    </div>
                                                }   
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-2'>
                                            <label className="text-label fw-bold">Expiry date</label>
                                            <label>{userProfile.idExpiryDate || idExpiryDate?moment(idExpiryDate).format("YYYY-MM-DD"):''} 
                                            
                                                <span className='ms-2 text-underline cursor_pointer txt-primary' onClick={()=>setOpenCalender(!openCalender)}>{openCalender?'Close':'Add date'}</span>
                                            </label>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                        
                                        {
                                            openCalender?
                                            <Fragment>
                                                <Controller
                                                    control={control}
                                                    name="idExpiryDate"
                                                    // rules={{ required: "Please add Expiry date." }}
                                                    // defaultValue={moment(idExpiryDate).format("YYYY-MM-DD")}
                                                    render={
                                                    ({ field }) => 
                                                        <div className="datepicker-here" data-language="en">
                                                            <DatePicker className="form-control digits"
                                                                selected={idExpiryDate?idExpiryDate:new Date()} 
                                                                minDate={new Date()}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                onChange={(date) => {
                                                                    seterror(false)
                                                                    field.onChange(date)
                                                                    setValue('idExpiryDate',moment(date).format("YYYY-MM-DD"))
                                                                    setIdExpiryDate(date)
                                                                    setOpenCalender(!openCalender)
                                                                }}
                                                                inline
                                                            />
                                                        </div>
                                                        // <Calendar minDate={new Date()} {...field} onChange={(date)=>{
                                                        //     field.onChange(date)
                                                        //     setValue('idExpiryDate',moment(date).format("YYYY-MM-DD"))
                                                        //     setIdExpiryDate(date)
                                                        //     setOpenCalender(!openCalender)
                                                        // }} value={idExpiryDate?idExpiryDate:new Date()} />
                                                    }
                                                    
                                                />
                                            </Fragment>:
                                            null
                                        }
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col lg={12} className='mt-2'>
                                        {
                                            error?<Alert color='light' className='border border-danger text-danger p-2 text-center'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>{error}</Alert>:null
                                        }
                                        {
                                            message?<Alert color='light' className='border border-success text-success p-2 text-center'><FontAwesomeIcon icon={faCheckCircle} className='me-2'/>{message}</Alert>:null
                                        }
                                        {/* {
                                            Object.keys(formErrors).length !== 0 && Object.keys(formErrors).map((err)=>{
                                                return (
                                                    <Alert color='light' className='border border-danger text-danger p-2 text-center'><FontAwesomeIcon icon={faExclamationCircle} className='me-2'/>{errors[err].message}</Alert>
                                                )
                                            })
                                        } */}
                                    </Col>
                                    <Col lg={12}>
                                        <Button 
                                            // type='submit'
                                            color="secondary" 
                                            disabled={dataUpdating} 
                                            block
                                        >
                                            {dataUpdating?<FontAwesomeIcon icon={faCircleNotch} spin/>:'Update Details'}
                                        </Button>
                                    </Col>
                                    
                                </Row>
                            </form>
                            }
                        </div>
                    </ModalBody>
                }
                
            
        </Modal>
    )
}
