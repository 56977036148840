import React from "react";
import CustomButton from "../common/button";
import { Profile360_icon } from "../common/icons";
import CustomToolTip from "../common/tooltip";
import { useDispatch } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useSelector } from "react-redux";
function DisclosureContent({
  tooltip = "",
  data = {},
  removeBtn = false,
  rightChild = <></>,
  ...props
}) {
  const dispatch = useDispatch();
  const reservationDetails = useSelector(profile360Selector.reservationDetails);

  // disable button by reservation status -->>
  let disable = data.isDone;
  if (data.type == "roomUpgrade") {
    if (reservationDetails.data.inforStatus != "Reserved") {
      disable = true;
    }
  } else if (data.serviceType == "chamomileTeaService") { 
    if (reservationDetails.data.inforStatus != "In-house") {
      disable = true;
    }
  } else if (
    reservationDetails.data.inforStatus != "Reserved" &&
    reservationDetails.data.inforStatus != "In-house"
  ) {
    disable = true;
  }
  // disable button by reservation status <<--

  return (
    <div className="drop-content flex items-center gap-4px justify-between mb-16px">
      <div className="child flex gap-4px items-center">
        {/* <div className="flex items-center">
         
        </div> */}
        <div className="drop-label flex items-start gap-4px">
          <Profile360_icon.radio2 success={data?.isDone} />
          <div>{data.title}</div>
        </div>
        {data.tooltip && (
          <CustomToolTip tooltipChild={data.tooltip}>
            <div className="flex items-center cursor-pointer">
              <Profile360_icon.tooltip />
            </div>
          </CustomToolTip>
        )}
      </div>

      {removeBtn ? (
        rightChild
      ) : (
        <CustomButton
          disabled={disable}
          onClick={() =>
            dispatch(
              profile360_actions.openModal({
                isFor: "purposeful-engagement",
                title: data.modalTitle || data.title,
                data: data,
              })
            )
          }
          style={{ textTransform: "uppercase" }}
          label={data?.buttonLabel}
        />
      )}
    </div>
  );
}

export default DisclosureContent;
