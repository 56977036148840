import React, { Fragment } from 'react';
import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import PaymentTransactionPageContain from '../../Component/PaymentTransaction';

const PaymentTransactionPage = () => {
  return (
    <Fragment>
      <PaymentTransactionPageContain />
    </Fragment>
  );
};
export default PaymentTransactionPage;