import React, { Fragment, useState } from "react";
import "./index.scss";
import json from "../tempDetails.json";
import CustomFlip from "../common/filp";
import DisclosureContent from "./DisclosureContent";
import { useSelector } from "react-redux";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";

function PurposefulEngagement() {
  const dispatch = useDispatch();
  const purposefulEngagement = useSelector(
    profile360Selector.purposefulEngagement
  );
  const handleHisory = () => {
    dispatch(
      profile360_actions.openModal({
        title: "History",
        isFor: "purposeful-engagement",
        data: { type: "history" },
      })
    );
  };

  const Disclouser = ({ label = "", options = [], parentKey = "" }) => {
    const [viewMore, setViewMore] = useState(false);

    let flag = true;
    options = options.filter((e) => {
      if (!e.isDone) {
        flag = false;
        return e;
      }
    });

    if (flag) {
      return <></>;
    }

    return (
      <div className="Engagements">
        <div className=" flex justify-between items-center  mb-17px">
          <div className="heading">{label}</div>
          {options.length > 2 && (
            <div
              className="cursor-pointer"
              style={{ width: "58.31px", height: "14.9px" }}
            >
              <CustomFlip
                front={
                  <div
                    onClick={() => setViewMore((more) => !more)}
                    className="view-more text-300 cursor-pointer"
                  >
                    View More
                  </div>
                }
                isFlip={viewMore}
                height="100%"
                width="100%"
                back={
                  <div
                    onClick={() => setViewMore((more) => !more)}
                    className="view-more text-300 cursor-pointer"
                  >
                    View Less
                  </div>
                }
              />
            </div>
          )}
        </div>
        <div
          style={{
            overflow: "hidden",
            height: viewMore
              ? 61 * (options?.length || 0)
              : options.length >= 2
              ? `${61 * 2}px`
              : options.length >= 1
              ? `61px`
              : `0px`,
            transition: "0.6s height",
          }}
        >
          {options.length > 0 &&
            options.map((data, idx) => (
              <Fragment key={idx}>
                <DisclosureContent data={{ parentKey, ...data }} />
              </Fragment>
            ))}
        </div>
      </div>
    );
  };

  const Title = () => (
    <div className="flex justify-between items-center title-base-div">
      <div className="title">Purposeful Engagement</div>
      <div onClick={handleHisory} className="history text-300">
        HISTORY
      </div>
    </div>
  );

  const PurposeFullSkelton = () => {
    return (
      <div>
        {[133, 143].map((h, index) => (
          <div key={index} className="pt-24px">
            <div className="mb-17px flex justify-between items-center">
              <Skeleton variant="rounded" width={h} height={19} />
              <Skeleton variant="rounded" width={58} height={14} />
            </div>
            <div>
              <Skeleton
                className="mb-16px"
                variant="rounded"
                width={"100%"}
                height={45}
              />
              <Skeleton
                className="mb-16px"
                variant="rounded"
                width={"100%"}
                height={45}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="PurposefulEngagement">
      <Title />
      {purposefulEngagement.loader ? (
        <PurposeFullSkelton />
      ) : (
        json.PurposefulEngagement.map((data, idx) => (
          <Fragment key={idx}>
            {Array.isArray(purposefulEngagement?.data[data.key]) &&
              purposefulEngagement.data[data.key].length > 0 && (
                <Disclouser
                  parentKey={data.key}
                  label={data.label}
                  options={purposefulEngagement.data[data.key]}
                />
              )}
          </Fragment>
        ))
      )}
    </section>
  );
}

export default PurposefulEngagement;
