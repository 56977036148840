import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../../js/api";
import { useSelector } from "react-redux";
import { profile360Selector, profile360_actions } from "../../js/slice";
import { useFormik } from "formik";
import { profile360Schema } from "../validationSchema";
import tempJson from "../../tempDetails.json";
import CustomSelect from "./select";
import ColorPicker from "./colorPicker";
import Temperature from "./temperature";
import CustomCheckBox from "./checkbox";
import CustomButton from "../../common/button";
import { Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Loader from "./loader";
import { Profile360_icon } from "../../common/icons";
function MyPreferences({ email, reservationId }) {
  const queryProps = { email, reservationId };
  const dispatch = useDispatch();
  const getAllRoomService = useSelector(profile360Selector.getAllRoomService);
  const { formData } = useSelector(profile360Selector.preferenceDetails);
  const saveUserPreference = useSelector(profile360Selector.saveUserPreference);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (
      typeof getAllRoomService.data == "object" &&
      !Object.keys(getAllRoomService.data).length > 0
    ) {
      dispatch(Profile360Apis.getAllRoomService());
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      sleepQuality: formData.sleepQuality || [],
      roomSelection: formData.roomSelection || {},
      typeOfWine: formData.typeOfWine || {},
      pillowType: formData.pillowType || {},
      musicFile: formData.musicFile || {},
      temperature: formData.temperature || 68,
      temperatureUnit: formData.temperatureUnit || "Fahrenheit",
      lightPercentage: formData.lightPercentage || 50,
      lightColor: formData.lightColor || { r: 0, g: 0, b: 0 },
      calmingMusic: formData.calmingMusic || {},
      whiteNoiseForBetterSleep: formData.whiteNoiseForBetterSleep || {},
    },
    enableReinitialize: true,
    validationSchema: profile360Schema.myPreferences,
    onSubmit: (values) => {
      let newObj = {};
      Object.keys(values).map((e) => {
        if (Array.isArray(values[e])) {
          // for array -->>
          if (values[e].length > 0) {
            newObj[e] = values[e].map((n) => n.id);
          } else {
            newObj[e] = [];
          }
        } else if (typeof values[e] == "object" && !Array.isArray(values[e])) {
          // for object -->>
          if (e == "lightColor") {
            newObj.lightColorRed = values[e].r;
            newObj.lightColorGreen = values[e].g;
            newObj.lightColorBlue = values[e].b;
          } else {
            newObj[e] = values[e].id;
          }
        } else if (typeof values[e] == "number") {
          newObj[e] = values[e];
        } else if (typeof values[e] == "string") {
          newObj[e] = values[e];
        }
      });
      //   if (!newObj.sleepQuality) {
      //     newObj.sleepQuality = [];
      //   }
      dispatch(Profile360Apis.saveUserPreference({ data: newObj, queryProps }));
    },
  });

  const colorPickerProps = {
    formik,
    visible,
    setVisible,
  };
  const temperatureProps = {
    formik,
    visible,
    setVisible,
  };

  const handleCustomCheckBox = (value, name, checked) => {
    let state = formik.values[name];
    let tempArray = [];
    if (Array.isArray(state)) {
      if (checked) {
        tempArray = state.filter((item) => item.id !== value.id);
      } else {
        tempArray = [...state];
        tempArray.push(value);
      }
    }
    formik.setFieldValue(name, tempArray);
  };

  const handleSelect = (value, name) => {
    setVisible(name);
    formik.setFieldValue(name, value);
  };

  if (getAllRoomService.loader) {
    return <Loader />;
  }
  const handleClose = () => dispatch(profile360_actions.closeModal());
  return (
    <div
      onClick={() => setColorPickerVisible(false)}
      className="my-preferences mt-30px w-500px"
    >
      <form className="flex flex-col" onSubmit={formik.handleSubmit}>
        {tempJson.Preferences.map(({ form, keepUnderLine, label }, index) => (
          <Fragment key={index}>
            <div className="flex flex-nowrap justify-between items-center w-full mb-15px gap-30px h-40px">
              <div className="my-pref-ttle">{label}</div>
              {form.inputType == "select" ? (
                <div className="relative">
                  <CustomSelect
                    name={form.name}
                    onBlur={formik.handleBlur}
                    handleSelect={handleSelect}
                    value={formik.values[form.name].key}
                    options={getAllRoomService.data[form.name] || []}
                  />
                </div>
              ) : form.inputType == "color" ? (
                <ColorPicker
                  {...colorPickerProps}
                  name={form.name}
                  value={formik.values[form.name]}
                />
              ) : form.inputType == "temperature" ? (
                <Temperature
                  {...temperatureProps}
                  name={form.name}
                  value={formik.values[form.name]}
                />
              ) : form.inputType == "checkbox" ? (
                <CustomCheckBox
                  name={form.name}
                  onBlur={formik.handleBlur}
                  handleCustomCheckBox={handleCustomCheckBox}
                  value={formik.values[form.name]}
                  options={getAllRoomService.data[form.name] || []}
                  setVisible={setVisible}
                />
              ) : form.inputType == "light" ? (
                <CustomSelect
                  name={form.name}
                  onBlur={formik.handleBlur}
                  handleSelect={handleSelect}
                  value={formik.values[form.name]}
                  type={form.inputType}
                  options={[100, 50, 10, 0]}
                />
              ) : (
                <></>
              )}
            </div>
            {keepUnderLine && <div className="underline  mb-15px" />}
          </Fragment>
        ))}

        <div className="flex justify-end gap-20px mt-27px">
          <button
            onClick={handleClose}
            type="button"
            className="form-btn form-btn-white"
          >
            Close
          </button>
          <button
            type="submit"
            className="form-btn form-btn-magenta flex items-center justify-center"
          >
            {saveUserPreference.loader ? (
              <Profile360_icon.threeDotLoader />
            ) : (
              "Update"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default MyPreferences;
