import { createSlice } from "@reduxjs/toolkit";
import { fetchNavbar } from "./api";

const initialState = {
  navbarData: [],
  isLoading: true,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const navbarSlice = createSlice({
  initialState,
  name: "fetchNavbar",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchNavbar.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNavbar.fulfilled, (state, { payload }) => {
      // console.log("fetchNavbar.fulfilled:", payload);

      state.isLoading = false;
      state.navbarData = payload;
      state.profileData = payload?.result?.data;
    });
    builder.addCase(fetchNavbar.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = payload?.status;
    });
  },
});

export default navbarSlice.reducer;
export const { setStatus } = navbarSlice.actions;
export const navbarSelector = (state) => state.fetchNavbar;
