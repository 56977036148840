import React, { Fragment } from "react";
import { Row } from "reactstrap";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import MobileAppReservationContain from "../../Component/MobileAppReservations";
import TimelineTab from "../../Component/MobileAppReservations/TimelineTab";

const MobileAppReservationsPage = () => {
  return (
    <Fragment>
      <MobileAppReservationContain />
    </Fragment>
  );
};
export default MobileAppReservationsPage;
