import { useState } from "react";
import { RgbColorPicker } from "react-colorful";
import { useDebouncyEffect } from "use-debouncy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Profile360_icon } from "../../common/icons";

const ColorPicker = ({ value, name, formik, setVisible, visible }) => {
  const [selectedColor, setColor] = useState(value);
  const [hidePop, setHidePop] = useState(visible);

  useDebouncyEffect(
    () => {
      formik.setFieldValue(name, selectedColor);
    },
    200,
    [selectedColor]
  );

  useDebouncyEffect(
    () => {
      setHidePop(visible);
    },
    180,
    [visible]
  );

  return (
    <div
      className="flex flex-nowrap items-center relative"
      onClick={(e) => e.stopPropagation()}
    >
      {hidePop == name && (
        <div
          style={{ zIndex: "1" }}
          className={`absolute top-0 right-0 mr-30px color-picker-1 ${
            visible == name ? "anim-to-dark" : "anim-to-light"
          }`}
        >
          <div className="flex justify-end w-full p-3px">
            <Profile360_icon.modalCross
              onClick={() => setVisible("")}
              fill="#868686"
            />
          </div>
          <RgbColorPicker
            className="m-10px"
            style={{ width: "180px", height: "110px" }}
            name={name}
            onChange={setColor}
            color={value}
          />
        </div>
      )}
      <div
        className="mr-5px"
        style={{
          background: `rgb(${value.r},${value.g},${value.b})`,
          width: "23px",
          height: "23px",
          borderRadius: " 23px",
          filter: `drop-shadow(0px 0px 1px rgb(${value.r},${value.g},${value.b})`,
        }}
      ></div>
      <KeyboardArrowDownIcon
        style={{
          rotate: visible == name ? "180deg" : "0deg",
          fontSize: "30px",
        }}
        className="cursor-pointer"
        onClick={() => setVisible((pref) => (pref == name ? "" : name))}
      />
    </div>
  );
};

export default ColorPicker;
