export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			layout: 'Dubai',
			sidebar: {
				type: 'horizontal-wrapper',
			},
			sidebar_setting: 'default-sidebar',
		},
		color: {
			primary_color: '#FF0094',
			secondary_color: '#000000',
			mix_background_layout: 'light-only',
		},
		router_animation: 'fadeIn'
	};
}
export default ConfigDB;
