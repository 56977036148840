import { H1, H3 } from "../../AbstractElements";
import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Stack } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { getRoomFolio } from "../../actions/axios-actions";
import SearchRoom from "./searchRoom";
import SendNotification from "./sendNotification";
import FolioDetails from "./folioDetails";

const FolioPageContain = () => {
  const [roomNo, setRoomNo] = useState("");
  const [roomNoDebounce] = useDebounce(roomNo, 800);
  const [loading, setLoading] = useState(false);
  const [folio, setFolio] = useState([]);
  const [roomData, setRoomData] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (roomNoDebounce && roomNoDebounce.length >= 2) {
      getRoomFolio(
        roomNoDebounce,
        setLoading,
        setFolio,
        setRoomNo,
        setRoomData,
        setMessage
      );
    } else {
      setRoomNo("");
      setFolio([]);
      setRoomData(null);
      setMessage("");
    }
  }, [roomNoDebounce]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0"></CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <SearchRoom
                      roomNo={roomNo}
                      setRoomNo={setRoomNo}
                      setFolio={setFolio}
                      setRoomData={setRoomData}
                      roomData={roomData}
                      loading={loading}
                    />
                  </Col>
                  <Col>
                    {!loading && folio.length > 0 && (
                      <SendNotification roomData={roomData} />
                    )}
                  </Col>
                </Row>
                {!loading && folio.length > 0 ? (
                  <>
                    <H1>Room No: {roomNo}</H1>
                    <Stack
                      direction="vertical"
                      gap={5}
                      className="border p-2 mt-4"
                    >
                      <Col>
                        <Row>
                          <div className="m-2">
                            <H3>Folio Details</H3>
                          </div>
                          <FolioDetails roomData={roomData} folio={folio} />
                        </Row>
                      </Col>
                    </Stack>
                  </>
                ) : (
                  <Col className="pt-3">
                    <H1>{message}</H1>
                  </Col>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default FolioPageContain;
