import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import DraggableContain from '../../Component/Meetings';

const Draggable = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Meetings" />
      <DraggableContain />
    </Fragment>
  );
};
export default Draggable;