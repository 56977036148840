import React, { Fragment, useEffect, useState } from "react";
// import { AlertCircle, Clock, Image, Users } from 'react-feather';
import {
  Button,
  Card,
  Col,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import SelectElement from "../../CommonElements/formelements/select";
import { useDispatch } from "react-redux";
import { fetchFrontdeskPackageList } from "../../actions/axios-actions";

const TimelineTab = ({ handleSearch, changeSearchText, changeTabCallback, activeTab, searchText, handlefieldChange,handleStatusChange  }) => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    
    fetchFrontdeskPackageList({setFilterData,setLoading})
    
  }, []);
  
  return (
    <Fragment>
      <Col sm="12 box-col-12">
        {/* <Card> */}
          <div className="social-tab">
            {/* <Nav></Nav> */}
            <Nav tabs>
              <NavItem><NavLink className={activeTab === 'Front Desk' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e, 'Front Desk');
              }}>Front Desk</NavLink>
              </NavItem>
              <NavItem ><NavLink className={activeTab === 'Housekeeping' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e, 'Housekeeping');
              }}>Housekeeping</NavLink>
              </NavItem>

              {activeTab === 'Housekeeping' ||  activeTab === 'History' ?  <NavItem ><NavLink className={activeTab === 'History' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e, 'History') 
              }}>History</NavLink> 
              </NavItem> : null }
             {/* <NavItem ><NavLink className={activeTab === 'f&b' ? 'active' : ''} onClick={(e) => {
                changeTabCallback(e, 'f&b');
              }}>F&B</NavLink>
              </NavItem> */}
            </Nav>
            {/* <form onSubmit={(e) => handleSearch(e, { searchText })}>
              <InputGroup>
                <Input
                  className="form-control"
                  type="text"
                  onChange={changeSearchText}
                  value={searchText}
                  placeholder="Search......."
                />
                <span className="input-group-text">
                  <i
                    className="fa fa-search"
                    onClick={(e) => handleSearch(e, { searchText })}
                  ></i>
                </span>
              </InputGroup>
            </form> */}
            {activeTab == "Front Desk" ?<div className="d-flex ">
                <div className="me-2">
                  <SelectElement
                    placeholder="Request Type"
                    label="Filter by Request"
                    options={filterData && filterData.map(o => ({ label: o.packageName, value: o.packageType }))} 
                    onChange = {handlefieldChange}
                  />
                </div>
                <div className="me-3">
                  <SelectElement
                    placeholder="Request Status"
                    label="Filter by Status"
                    options={[
                      {
                        label:"All",value:0
                      },{
                        label:"Approved",value:1
                      },{
                        label :"Rejected",value:-1
                      }
                    ]} 
                    onChange = {handleStatusChange}
                  />
                </div>
                {/* <div>
                  <label>&nbsp;</label>
                  <div>
                    <Button className="rounded-0" onClick={handleSearch}>Filter</Button>
                  </div>
                </div> */}
            </div>:null}


            {activeTab == "History" ?<div className="d-flex ">
            
            <div className="me-3">
              <SelectElement
                placeholder="Request Status"
                label="Filter by Status"
                options={[
                  {
                    label:"All",value:0
                  },{
                    label:"Completed",value:1
                  },{
                    label :"Cancelled",value:2
                  }
                ]} 
                onChange = {handleStatusChange}
              />
            </div>
            {/* <div>
              <label>&nbsp;</label>
              <div>
                <Button className="rounded-0" onClick={handleSearch}>Filter</Button>
              </div>
            </div> */}
        </div>:null}



          </div>
        {/* </Card> */}
      </Col>




      <style jsx global>{`
        
      .cygFkb{
        padding-right: 0px;
      }
     
    `}</style>
    </Fragment>
  );
};
export default TimelineTab;
