import reducers from "./reducers";
import initialState from "./initialState";
import extraReducers from "./extraReducers";

const { createSlice, createSelector } = require("@reduxjs/toolkit");

const profile360Slice = createSlice({
  name: "profile360",
  initialState,
  reducers,
  extraReducers,
});

export default profile360Slice.reducer;
export const profile360_actions = profile360Slice.actions;

export const profile360 = (state) => state.ManageProfile360;

const modal = createSelector([profile360], (state) => state.modal);
const enroll = createSelector([profile360], (state) => state.enroll);
const profile = createSelector([profile360], (state) => state.profile);
const weather = createSelector([profile360], (state) => state.weather);
const saveCards = createSelector([profile360], (state) => state.saveCards);
const hotelData = createSelector([profile360], (state) => state.hotelData);
const upgradeRoom = createSelector([profile360], (state) => state.upgradeRoom);
const saveHistory = createSelector([profile360], (state) => state.saveHistory);
const findHistory = createSelector([profile360], (state) => state.findHistory);

const reservationDetails = createSelector(
  [profile360],
  (state) => state.reservationDetails
);
const preferenceDetails = createSelector(
  [profile360],
  (state) => state.preferenceDetails
);
const userRegistration = createSelector(
  [profile360],
  (state) => state.userRegistration
);
const mobileKeySetup = createSelector(
  [profile360],
  (state) => state.mobileKeySetup
);
const purposefulEngagement = createSelector(
  [profile360],
  (state) => state.purposefulEngagement
);
const addPackagesToReservation = createSelector(
  [profile360],
  (state) => state.addPackagesToReservation
);
const newservicerequest = createSelector(
  [profile360],
  (state) => state.newservicerequest
);
const getAllRoomService = createSelector(
  [profile360],
  (state) => state.getAllRoomService
);
const queryDetails = createSelector(
  [profile360],
  (state) => state.queryDetails
);
const saveUserPreference = createSelector(
  [profile360],
  (state) => state.saveUserPreference
);
const hotelEngagementLogs = createSelector(
  [profile360],
  (state) => state.hotelEngagementLogs
);
const saveHotelEngagementLogs = createSelector(
  [profile360],
  (state) => state.saveHotelEngagementLogs
);
const profilesFromLinkedIn = createSelector(
  [profile360],
  (state) => state.profilesFromLinkedIn
);

const attachLinkedInUrl = createSelector(
  [profile360],
  (state) => state.attachLinkedInUrl
);

const detachLinkedInUrl = createSelector(
  [profile360],
  (state) => state.detachLinkedInUrl
);

const skipLinkedInSelection  = createSelector(
  [profile360],
  (state) => state.skipLinkedInSelection
);

export const profile360Selector = {
  modal,
  reservationDetails,
  queryDetails,
  preferenceDetails,
  weather,
  profile,
  enroll,
  userRegistration,
  hotelData,
  saveCards,
  mobileKeySetup,
  purposefulEngagement,
  upgradeRoom,
  saveHistory,
  addPackagesToReservation,
  newservicerequest,
  findHistory,
  getAllRoomService,
  saveUserPreference,
  hotelEngagementLogs,
  saveHotelEngagementLogs,
  profilesFromLinkedIn,
  attachLinkedInUrl,
  detachLinkedInUrl,
  skipLinkedInSelection
};
