import React from "react";
import RoomUpgrade from "./roomUpgrade";
import AddPackage from "./addPackage";
import AddService from "./addService";
import SuggestionsServices from "./suggestionsServices";
import PurposefulHistory from "./history";

function PurposefulModal({ data, ...props }) {
    console.log(data);
  switch (data?.type) {
    case "roomUpgrade":
      return <RoomUpgrade />;
    case "addPackage":
      return <AddPackage />;
    case "addService":
      return <AddService />;
    case "suggestion":
      return <SuggestionsServices />;
    case "history":
      return <PurposefulHistory />;

    default:
      return <></>;
  }
}

export default PurposefulModal;
