import React, { Fragment, useState } from "react";
import { profile360Selector } from "../../js/slice";
import { useSelector } from "react-redux";
import CustomButton from "../../common/button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { Profile360_icon } from "../../common/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../../js/api";

function AddService() {
  const modal = useSelector(profile360Selector.modal);
  const reservationDetails = useSelector(profile360Selector.reservationDetails);
  const newservicerequest = useSelector(profile360Selector.newservicerequest);
  const dispatch = useDispatch();
  // count handler -->>>>
  const Count = ({ quantity, setQuantity = () => {} }) => {
    let min = 0;
    let max = 5;
    const handleCount = (e) => {
      switch (e) {
        case "minus":
          !quantity <= min && setQuantity(quantity - 1);
          break;
        case "plus":
          quantity < max && setQuantity(quantity + 1);
          break;
      }
    };

    return (
      <div
        style={{
          color: "red",
          fontSize: "18px",
          width: "55px",
        }}
        className="flex  flex-nowrap items-center justify-between mt-auto mr-15px"
      >
        <Profile360_icon.minus
          onClick={() => handleCount("minus")}
          disable={quantity <= min}
        />
        <div className="h-23px" style={{ color: "#F41095" }}>
          {quantity}
        </div>
        <Profile360_icon.plus
          onClick={() => handleCount("plus")}
          disable={quantity >= max}
        />
      </div>
    );
  };

  // Child component  -->>>>
  const Child = (data) => {
    dayjs.extend(customParseFormat);
    const [quantity, setQuantity] = useState(0);
    const [time, setTime] = useState(
      data.value ? dayjs(data.value, "hh:mm A") : dayjs().hour(9).minute(30)
    );

    const handleClick = (values) => {
      let selectedTime = time.format("hh:mm A");
      if (!reservationDetails.data?.roomNo) {
        alert("Room number is not assigned or provided");
        return;
      } else if (!quantity) {
        alert("Please add at least one quantity");
        return;
      }
      let params = {
        ...modal.data,
        body: {
          department: data.department,
          hotelId: modal.data?.service?.hotelId,
          requests: [
            {
              price: 0,
              quantity,
              selectedTime,
              time: selectedTime,
              title: modal.data?.title,
            },
          ],
          reservationId: modal.data?.reservationId,
          roomNo: reservationDetails.data?.roomNo,
          totalAmount: 0,
          viewtype: modal.data?.service?.requestKey,
        },
      };
      dispatch(Profile360Apis.newservicerequest(params));
    };

    switch (modal.data?.title) {
      case "Chamomile Tea":
        return (
          <div
            style={{ height: "33px" }}
            className="flex justify-between mt-22px "
          >
            <div className="flex items-center flex-nowrap  gap-8px">
              {modal.data?.title == "Chamomile Tea" &&
                !newservicerequest.loader && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      value={time}
                      onAccept={(e) => setTime(dayjs(e))}
                      label="Select Time"
                    />
                  </LocalizationProvider>
                )}
            </div>
            <div className="flex gap-8px">
              {!newservicerequest.loader && (
                <Count setQuantity={setQuantity} quantity={quantity} />
              )}

              <CustomButton
                onClick={handleClick}
                loader={newservicerequest.loader}
                disabled={newservicerequest.loader}
                className={"h-23px mt-auto"}
                label="ADD"
              />
            </div>
          </div>
        );

      default:
        return (
          <div className="p-10px">{modal.data?.title}&nbsp;Not configured</div>
        );
    }
  };

  return (
    <div className="w-450px mt-10px services">
      <div className="desc">{modal.data?.modalDesc}</div>
      {Array.isArray(modal.data?.service?.options) &&
        modal.data?.service?.options.map((data, index) => {
          return (
            <Fragment key={index}>
              <Child {...data} />
            </Fragment>
          );
        })}
    </div>
  );
}

export default AddService;
