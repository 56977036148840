import React, { Fragment } from "react";
import ProfileCard from "./profileCard";

function DetailPage() {
  return (
    <Fragment>
      <ProfileCard />
    </Fragment>
  );
}

export default DetailPage;
