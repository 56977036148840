import moment from "moment";
import React from "react";
import { Image } from "react-bootstrap";
import { H6, Badges } from "../../AbstractElements";
import { Badge, UncontrolledTooltip } from "reactstrap";

export const OrderHistoryColumns = [
  {
    name: <H6>Guest Name</H6>,
    selector: (row) =>{
      if (!row.guestName?.length) return ""; // Handle empty string
      console.log(row.guestName);
      if(row.guestName){
        let guestName = row.guestName;
        let returnText = guestName.split(" ").map((e) => {
          return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
        });
        console.log(returnText.join(" "));
        return returnText.join(" ");
      }else{
        console.log(row.guestName);
        return "-"
      }
    },
    sortable: true,
    // center: true,
    minWidth: "150px",
    wrap: true,
  },


  {
    name: <H6>Email ID</H6>,
    selector: (row) => row.emailId && (!row.emailId.includes("+deleted")) ? row.emailId : "-",
    sortable: true,
    // center: true,
    minWidth: "250px",
    wrap: true,
  },
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId,
    sortable: true,
    center: true,
    minWidth: "165px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Arrival Date</H6>,
    selector: (row) => row.arrivalDate,
    sortable: true,
    // center: true,
    minWidth: "160px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Departure Date</H6>,
    selector: (row) => row.departureDate,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Check-In Time</H6>,
    selector: (row) => row.checkInTime,
    sortable: true,
    center: true,
    minWidth: "160px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Check-Out Time</H6>,
    selector: (row) => row.checkOutTime,
    sortable: true,
    center: true,
    minWidth: "170px",
  },
  {
    name: <H6>Status</H6>,
    selector: (row) =>
      row.inforStatus == "In-house" ? (
        <div className="text-center" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{ color: "success", style: { borderRadius: "0px" } }}
          >
            In-house
          </Badges>
        </div>
      ) : row.inforStatus == "Reserved" ? (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{ color: "warning", style: { borderRadius: "0px" } }}
          >
            Reserved
          </Badges>
        </div>
      ) : row.inforStatus == "No-Show" ? (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges attrBadge={{ color: "info", style: { borderRadius: "0px" } }}>
            No-Show
          </Badges>
        </div>
      ) : row.inforStatus == "Cancelled" ? (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{ color: "danger", style: { borderRadius: "0px" } }}
          >
            Cancelled
          </Badges>
        </div>
      ) : row.inforStatus == "Checked-out" ? (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{ color: "danger", style: { borderRadius: "0px" } }}
          >
            Checked-out
          </Badges>
        </div>
      ) : (
        ""
      ),
    sortable: true,
    center: true,
    minWidth: "110px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Room No</H6>,
    selector: (row) => row.roomNo,
    sortable: true,
    center: true,
    minWidth: "120px",
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>User Shashi Cash Eligibility</H6>,
    selector: (row) => {
      var message = "";
      var title = "",subMessage = "";
      // console.log(row.shasiCashBackEarned,row.shashicash_reason,row.isMarketCode,row.isSourceOfBusiness,row.wrongCode)
      var earned = 0;
      if(row.shasiCashBackEarned){
        earned = row.shasiCashBackEarned;
      }else if(row.isMarketCode || row.isSourceOfBusiness){
        subMessage = row.isMarketCode?"Market Code":row.isSourceOfBusiness?"Source of Business":""
        message = row.wrongCode;
      }else if(row.shashicash_reason){
        message = row.shashicash_reason;
      }
      
      return (
        <div>
          {
            !earned?<UncontrolledTooltip
            popperClassName="mb-2"
            innerClassName="bg-white"
            placement="top"
            target={"tooltip_"+row.reservationId}
            style={{
              // backgroundColor: 'white',
              textAlign:'left',
              width:'240px',
              maxWidth:'240px',
              padding: 0,
              borderRadius: '30px',
            }}
          >
            <div className="p-4 w-100 bg-white rounded">
              {row.marketCode?<p className="mb-0 text-black">Market Code : <span className={row.isMarketCode?"txt-danger":"txt-success"}>{row.marketCode}</span></p>:null}
              <p className="mb-0 text-black">Email : {row?.profile?.emailVerified?<span className="txt-success">Verified</span>:<span className="txt-danger">Not Verified</span>}</p>
              <p className="mb-0 text-black">Shashi Cash : {row?.profile?.isLoyaltyApply?<span className="txt-success">Enrolled</span>:<span className="txt-danger">Not Enrolled</span>}</p>
              {row.SourceOfBusiness?<p className="mb-0 text-black">SOB : <span className={row.isSourceOfBusiness?"txt-danger":"txt-success"}>{row.SourceOfBusiness}</span></p>:null}
            </div>
          </UncontrolledTooltip>:null}
          {
            earned?<span className="badge badge-light-success form-label rounded-0 mb-0">Eligible</span>:
            <span id={"tooltip_"+row.reservationId}
             className="badge badge-light-danger form-label rounded-0 mb-0">Not Eligible</span>
          }
          
        </div>
      )
    },
    sortable: true,
    center: true,
    minWidth: "170px",
    wrap: true,
  },
  {
    name: <H6 attrH6={{className:"text-center"}}>Shashi Cash <br/>Amount</H6>,
    selector: (row) => {
      var message = "";
      var title = "",subMessage = "";
      // console.log(row.shasiCashBackEarned,row.shashicash_reason,row.isMarketCode,row.isSourceOfBusiness,row.wrongCode)
      var earned = 0;
      if(row.shasiCashBackEarned){
        earned = row.shasiCashBackEarned;
      }else if(row.isMarketCode || row.isSourceOfBusiness){
        subMessage = row.isMarketCode?"Market Code":row.isSourceOfBusiness?"Source of Business":""
        message = row.wrongCode;
      }else if(row.shashicash_reason){
        message = row.shashicash_reason;
      }
      return (
        <div>
          <div className="text-center">${earned?earned:'00.00'}</div>
          {/* {subMessage?<div className="fs-11 text-danger">{subMessage}</div>:null}
          {message?<div className="text-center text-danger fs-15">{message}</div>:null} */}
        </div>
      )
    },
    sortable: true,
    center: true,
    minWidth: "150px",
    wrap: true,
  },
  // {
  //   name: <H6>Profile</H6>,
  //   selector: (row) =>  <Image
  //   style={{ borderRadius: "50%", aspectRatio: "1/1" }}
  //   className="img-fluid img-30"
  //   src={row?.profile?.avatar || "/avatar.png"}
  //   alt={row?.profile?.avatar|| "profile Icon"}
  // />,
  //   sortable: true,
  //   center: true,
  //   minWidth: "100px",
  // },
  
  // {
  //   name: <H6>Key Generated</H6>,
  //   selector: (row) => row.isKeyGen,
  //   sortable: true,
  //   center: true,
  // },
  // {
  //   name: <H6>Express CheckIn Setup</H6>,
  //   selector: (row) => row.profile.isExpressCheckInSetup,
  //   sortable: true,
  //   center: true,
  //   wrap: true,
  // },
  // {
  //   name: <H6>Special request</H6>,
  //   selector: (row) => row.specialRequest,
  //   sortable: true,
  //   center: true,
  // },
  // {
  //   name: <H6>Is Added Resrervation</H6>,
  //   selector: (row) => row.isAddReservation,
  //   sortable: true,
  //   center: true,
  // },
  // {
  //   name: <H6>Created By</H6>,
  //   selector: (row) => row.reservationCreatedId,
  //   sortable: true,
  //   center: true,
  // },
  // {
  //   name: <H6>Updated By</H6>,
  //   selector: (row) => row.reservationLastModifierId,
  //   sortable: true,
  //   center: true,
  // },
];
