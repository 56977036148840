import { faCreditCard, faDollar, faList, faListCheck, faUsers, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { HomeSvg, TaskSvg, CalanderSvg, UsersComponentSvg, TablesSvg, KanbanSvg, CloudMoonIcon } from '../../Data/svgIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export var MENUITEMS = [
    {
        menutitle: 'Folio',
        Items: [
            {
                title: 'Folio', icon: HomeSvg, type: 'link', active: false, path: '/folio'
            },
        ]
    },
    {
        menutitle: 'Services',
        Items: [
            {
                title: 'Services', faicon: faListCheck, type: 'link', active: false, path: '/services'
            },
        ]
    },
    {
        menutitle: 'Hardware Dashboard',
        Items: [
            {
                title: 'Hardware Dashboard', faicon: TablesSvg, type: 'link', active: false, path: '/hardware-dashboard'
            },
        ]
    },
    {
        menutitle: 'Reservations',
        Items: [
            {
                title: 'Reservations', faicon: faList, type: 'link', active: false, path: '/mobile-app-reservations'
            },
        ]
    },
    {
        menutitle: 'Meetings',
        Items: [
            {
                title: 'Meetings', icon: CalanderSvg, type: 'link', active: false, path: '/meetings'
            },
        ]
    },
    {
        menutitle: 'Mobile Users',
        Items: [
            {
                title: 'Mobile Users', faicon:faUsers, type: 'link', active: false, path: '/mobile-users'
            },
        ]
    },
    {
        menutitle: 'Payment Transaction',
        Items: [
            {
                title: 'Payment Transaction', faicon: faCreditCard, type: 'link', active: false, path: '/payment-transaction'
            },
        ]
    },
    {
        menutitle: '360 Customer Profile',
        Items: [
            {
                title: '360 Customer Profile', faicon: faPeopleGroup, type: 'link', active: false, path: '/profile-360'
            },
        ]
    },
    {
        menutitle: 'IoT Dashboard',
        Items: [
            {
                title: 'IoT Dashboard', faicon: faList, type: 'link', active: false, path: '/iot-dashboard'
            },
        ]
    },
];
