import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { profile360Schema } from "./validationSchema";
import { Profile360_icon } from "../common/icons";
import { useSelector } from "react-redux";
import { profile360Selector } from "../js/slice";
import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Profile360Apis } from "../js/api";


const EmailSkelton = () => (
  <div className="flex gap-8px items-center">
    <Skeleton variant="rounded" width={20} height={20} />
    <Skeleton variant="rounded" width={150} height={14} />
  </div>
);

const ErrorWrapper = ({ children, formik, name }) => {
  return (
    <div className="relative input-base flex">
      {children}
      {name == "phone" ? (
        <>
          {formik.touched[name] ? (
            <>
              {!formik.values.isValidPhoneNo ? (
                <div
                  style={{
                    color: "#ff2520",
                    fontSize: "10px",
                    marginTop: "-10px",
                    height: "14px",
                    backgroundColor: "#fff5f4",
                  }}
                  className="absolute top-0 pl-3px pr-3px ml-3px"
                >
                  Please enter valid mobile number
                </div>
              ) : (
                <Fragment />
              )}
            </>
          ) : (
            <Fragment />
          )}
        </>
      ) : formik.touched[name] && formik.errors[name] ? (
        <div
          style={{
            color: "#ff2520",
            fontSize: "10px",
            marginTop: "-10px",
            height: "14px",
            backgroundColor: "#fff5f4",
          }}
          className="absolute top-0 pl-3px pr-3px ml-3px"
        >
          {formik.errors[name]}
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

const EmailInput = ({ formik, name, placeHolder, loader, email, ...props }) => {
  return loader ? (
    <EmailSkelton />
  ) : email ? (
    <div
      style={{width:"230px"}}
      className="flex flex-nowrap gap-8px values-2 h-fit mt-auto mb-auto"
    >
      <Profile360_icon.email />
      <div style={{ overflowWrap: "anywhere" }} className="mt-1px">
        {email}
      </div>
    </div>
  ) : (
    <ErrorWrapper formik={formik} name={name}>
      <input
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        placeholder={placeHolder}
        className="input"
        name={name}
        {...props}
      />
    </ErrorWrapper>
  );
};

const MobileInput = ({
  formik,
  name,
  loader,
  phone,
  countryCode,
  ...props
}) => {
  return loader ? (
    <EmailSkelton />
  ) : phone ? (
    <div
      className="flex flex-nowrap gap-8px values-2 items-center"
    >
      <Profile360_icon.phone />
      <div className="mt-3px"> {phone}</div>
    </div>
  ) : (
    <ErrorWrapper formik={formik} name={"phone"}>
      <div className="w-fit ml-3px mt-auto mb-auto">
        <PhoneInput
          country={"us"}
          onChange={(value, data, event, formattedValue) => {
            const regex = new RegExp(`^${data.dialCode}`);
            const phoneNumber = value.replace(regex, "");
            console.log(value, data.dialCode, "value");
            formik.setFieldValue("countryCode", `+${data.dialCode}`);

            formik.setFieldValue("phone", phoneNumber);
            formik.setFieldValue(
              "isValidPhoneNo",
              data.format?.length == formattedValue?.length
            );
          }}
          value={`${formik.values.countryCode}${formik.values.phone}`}
          inputProps={{
            name,
            style: {
              border: "none",
              width: "fit-content",
              height: "-webkit-fill-available",
              fontSize: "14px",
              backgroundColor: "#ffffff00",
            },
          }}
          countryCodeEditable={false}
          disableSearchIcon={true}
          enableSearch={true}
          {...props}
          onFocus={(event, data) => {
            formik.setFieldValue(
              "isValidPhoneNo",
              data.format.length == event.target.value.length
            );
          }}
        />
      </div>
    </ErrorWrapper>
  );
};

function EmailOrPhone({ email = "", reservationId = "" }) {
  const dispatch = useDispatch();
  const queryProps = { email, reservationId };
  const profile = useSelector(profile360Selector.profile);
  const formik = useFormik({
    initialValues: {
      countryCode:
        profile?.data?.countryCodeForMobileNumber ||
        (profile?.data?.inforPhoneNo
          ? profile?.data.inforPhoneNo?.split(" ")[0]
          : "+1"),
      email: profile?.data?.pmsEmailId || "",
      phone:
        profile?.data?.mobileNoWithoutCountryCode ||
        (profile?.data?.inforPhoneNo
          ? profile?.data.inforPhoneNo?.split(" ")[1]
          : ""),
      for: "",
      isValidPhoneNo: false,
    },
    enableReinitialize: true,
    validationSchema: profile360Schema.profileEmailandPhone,
    onSubmit: (value) => {
      if (value.for == "update" && !value.isValidPhoneNo) return;
      dispatch(
        Profile360Apis.userRegistrationv5({
          profileSetup: true,
          value,
          queryProps,
        })
      );
    },
  });

  const userRegistration = useSelector(profile360Selector.userRegistration);

  const handleRegister = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      if (!formik.values.email) return;
      formik.setFieldValue("for", "create");
      e.target.form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  const handleProfileUpdate = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      formik.setFieldValue("for", "update");
      e.target.form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="email-or-phone flex flex-col justify-between gap-8px"
    >
      <div className="underline mb-3px" />
      <div className="flex flex-row justify-between flex-nowrap gap-4px">
        <EmailInput
          formik={formik}
          name="email"
          placeHolder="Enter Email ID here.."
          onKeyDown={handleRegister}
          loader={profile.loader || userRegistration.loader}
          email={
            profile?.data?.email ? profile?.data?.email.split("+delete")[0] : ""
          }
        />
        {(profile?.data?.email || profile?.data?.inforPhoneNo) && (
          <MobileInput
            formik={formik}
            name={"phone"}
            onKeyDown={handleProfileUpdate}
            loader={profile.loader || userRegistration.loader}
            // phone={profile?.data?.phoneNo}
          />
        )}
      </div>

      <div className="underline mt-3px" />
    </form>
  );
}

export default EmailOrPhone;
