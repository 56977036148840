import React from "react";

function CustomFlip({
  width = "auto",
  height = "auto",
  front = "",
  back = "",
  isFlip = false
}) {
  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: width,
        height: height,
        // perspective: "1000px",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          textAlign: "center",
          transition: "transform 0.8s",
          transformStyle: " preserve-3d",
          transform: isFlip ? " rotateX(180deg)" : "rotateX(0deg)",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            WebkitBackfaceVisibility: "hidden",
            backfaceVisibility: "hidden",
          }}
        >
          {front}
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            WebkitBackfaceVisibility: "hidden",
            backfaceVisibility: "hidden",
            transform: "rotateX(180deg)",
          }}
        >
          {back}
        </div>
      </div>
    </div>
  );
}

export default CustomFlip;
