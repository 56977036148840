import React from "react";
import { Modal } from "reactstrap";
import { profile360Selector, profile360_actions } from "../js/slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Profile360_icon } from "./icons";
import Forms_360 from "../form";
function Custom360modal({ email, reservationId }) {
  const modal = useSelector(profile360Selector.modal);
  const queryProps = { email, reservationId };
  const dispatch = useDispatch();

  const handleToggler = () => {
    dispatch(profile360_actions.closeModal());
  };
  return (
    <Modal className="profile-360-modal-dialog" isOpen={modal?.isOpen} toggle={handleToggler} centered={true}>
      <section className="profile-360">
        <div className="profile360-modal">
          <div className="flex justify-between">
            <div className="title mr-10px">{modal.title || ""}</div>
            <Profile360_icon.modalCross onClick={handleToggler} />
          </div>
          <Forms_360 {...queryProps} />
        </div>
      </section>
    </Modal>
  );
}

export default Custom360modal;
