import React, { Fragment } from 'react'
import Select from 'react-select'

export default function SelectElement({label,options,onChange,placeholder,defaultValue}) {
    const colourStyles = {
        menuList: (base) => ({
            ...base,
        
            "::-webkit-scrollbar": {
              width: "4px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#555"
            }
        }),
        control: (base,state) => ({ 
            ...base, 
            scrollX:2,
            scrollY:2,
            width : "200px", 
            minWidth : "200px", 
            borderRadius : '0px',
            // borderColor:'#000',
            ':hover': {
                ...base[':hover'],
                borderColor:'#000',
                borderWidth:'1px',
                boxShadow:'none'
            },
            ':focus': {
                ...base[':focus'],
                borderColor:'#000',
                borderWidth:'1px',
                boxShadow:'none'
            },
            // borderColor: 'grey',

        }) ,
        // menu: (base) => ({...base, borderRadius : '0px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
                ? "#CCC"
                : isSelected
                ? '#C2C2C2'
                : isFocused
                ? '#000000'
                : '#FFFFFF',
            color: isDisabled
                ? '#000'
                : isSelected
                ? '#000000'
                : isFocused
                ? '#FFFFFF'
                : '#000000',
        
            ':hover': {
                ...styles[':hover'],
                backgroundColor:"#000000",
                color:"#FFFFFF"
            },
            ':active': {
                ...styles[':active'],
                backgroundColor:"#000000",
                color:"#FFFFFF"
            },
            ':focus': {
                ...styles[':focus'],
                backgroundColor:"#000000",
                color:"#FFFFFF"
            },
          };
        },
        // input: (styles) => ({ ...styles }),
        // placeholder: (styles) => ({ ...styles }),
        // singleValue: (styles, { data }) => ({ ...styles }),
      };
      
  return (
    <Fragment>
        <label>{label}</label>
        <Select 
            defaultValue = {defaultValue || false}
            placeholder={placeholder || 'Select...'}
            options = {options} 
            className="w-100"
            onChange = {(option)=>{
                if(onChange){
                    onChange(option)
                }
            }}
            styles={colourStyles}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                border:'none',
                colors: {
                  ...theme.colors,
                  primary25: 'black',
                  primary: 'black',
                },
            })}
          
        />
    </Fragment>
  )
}
