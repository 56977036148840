import React from "react";
import { profile360Selector } from "../../js/slice";
import { useSelector } from "react-redux";
import CustomButton from "../../common/button";
import { useDispatch } from "react-redux";
import { Profile360Apis } from "../../js/api";

function RoomUpgrade() {
  const modal = useSelector(profile360Selector.modal);
  const upgradeRoom = useSelector(profile360Selector.upgradeRoom);

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(Profile360Apis.upgradeRoom({ ...modal.data }));
  };
  return (
    <div className="w-576px mt-10px room-upgrade">
      <div className="flex justify-between type-code mt-22px pr-12px">
        <div>{modal.data?.roomTypeName}</div>
        <CustomButton
          disabled={upgradeRoom.loader}
          loader={upgradeRoom.loader}
          onClick={handleClick}
          label="UPGRADE"
        />
      </div>
      {Array.isArray(modal.data?.modalDesc) && (
        <>
          {modal.data?.modalDesc?.length > 0 && (
            <ul className="list-parent mt-15px">
              {modal.data?.modalDesc.map((e) => (
                <li>{e}</li>
              ))}
            </ul>
          )}
        </>
      )}
      <div className="underline mt-39px" />
      {Array.isArray(modal.data?.roomTypeComparison) &&
        modal.data?.roomTypeComparison.length > 0 && (
          <table className="w-full mt-41px table-parent">
            <thead>
              <tr className="h-42px">
                <td>Room Type</td>
                <td className="text-center">Beds</td>
                <td className="text-center">Area</td>
                <td className="text-end pr-22px">Price</td>
              </tr>
            </thead>
            <tbody>
              {modal.data?.roomTypeComparison.map((e) => (
                <tr className="h-42px">
                  <td>{e.roomType}</td>
                  <td className="text-center">{e.beds}</td>
                  <td className="text-center">{e.area}</td>
                  <td className="text-end">{e.price && `+${e.price}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default RoomUpgrade;
