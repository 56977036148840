import React from 'react';
import { H6 } from '../../AbstractElements';
import moment from 'moment';

export const OrderHistoryColumns = [
  /* {
    name: <H6>Profile</H6>,
    selector: (row) => row.avatar,
    sortable: true,
    center: true,
  }, */
  {
    name: <H6>Email ID</H6>,
    selector: (row) => row.email,
    sortable: true,
    // center: true,
    wrap: true
  },
  {
    name: <H6>First Name</H6>,
    selector: (row) => {
      console.log(row.firstName);
      if (row.firstName.length === 0) return "-"; // Handle empty string

      row.firstName = row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1).toLowerCase();
      console.log(row.firstName);
      return row.firstName;
    },
    sortable: true,
    // center: true,
    wrap: true
  },
  {
    name: <H6>Last Name</H6>,
    selector: (row) => {
      console.log(row.lastName);
      if (row.lastName.length === 0) return "-"; // Handle empty string

      row.lastName = row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1).toLowerCase();
      console.log(row.lastName);
      return row.lastName;
    },
    sortable: true,
    // center: true,
    wrap: true
  },
  {
    name: <H6>Email Verified</H6>,
    selector: (row) => row.emailVerified ? 'Yes' : 'No',
    sortable: true,
    center: true
  },
  {
    name: <H6>Card on file</H6>,
    selector: (row) => row.isCardAdded ? 'Yes' : 'No',
    sortable: true,
    center: true
  },
  {
    name: <H6>Express Checkin</H6>,
    selector: (row) => row.isExpressCheckInSetup ? 'Yes' : 'No',
    sortable: true,
    center: true,
    button:false
  },
  {
    name: <H6>Loyalty Program</H6>,
    selector: (row) => row.isLoyaltyApply ? 'Yes' : 'No',
    sortable: true,
    center: true
  },
  {
    name: <H6>Profile Completed</H6>,
    selector: (row) => row.isUserProfileSetup ? 'Yes' : 'No',
    sortable: true,
    center: true
  },
  {
    name: <H6>Phone #</H6>,
    selector: (row) => row.phoneNo || '-',
    sortable: true,
    center: true
  },
  {
    name: <H6>Last update Dt-Time</H6>,
    selector: (row) => row.updatedAt ? moment(row.updatedAt).format('YYYY-MM-DD HH:mm') : '',
    sortable: true,
    center: true,
    wrap: true
  },
];